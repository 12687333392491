import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types/general';

export interface ILostConnectionOverlayStyleProps extends IDeviceInfoContext {
}

export interface ILostConnectionOverlayStyles {
  title: IStyle;
}

export const getStyles = ({ calculateRemValue }: ILostConnectionOverlayStyleProps): ILostConnectionOverlayStyles => ({
  title: {
    textAlign: 'center',
    fontSize: calculateRemValue(11.4),
  },
});
