import { IStyleFunctionOrObject } from '@fluentui/utilities';
import { useStyles } from './useStyles';
import { useDeviceInfo } from './useDeviceInfo';
import { IDeviceInfoContext } from '../types/general';

// TODO: Review and optimize this file after first priority tasks (Alex Kolesnikov)
export const useStylesWithDeviceInfo = <TStyles, TStyleProps>(
  styles: IStyleFunctionOrObject<TStyleProps, TStyles> = {},
  props?: Omit<TStyleProps, keyof IDeviceInfoContext>,
) => {
  const deviceInfo = useDeviceInfo();

  const classNames = useStyles<TStyles, TStyleProps & IDeviceInfoContext>(styles, {
    ...deviceInfo,
    ...props,
  } as TStyleProps & IDeviceInfoContext);

  return classNames;
};
