import { createReducer } from '@reduxjs/toolkit';
import { IBrandsReducer } from '../types';
import {
  getBrands,
  getBrandsSuccess,
  getBrandsError,
  setBrandDialogForm,
  setImportBrandDialogForm,
  mergeBrandDialogForm,
  mergeImportBrandDialogForm,
  putBrandSuccess,
  setBrandsDialogAction,
  mergeBrandsDialogAction,
  executeBrandsActionSuccess,
} from './actions';
import { BrandAction } from '../../../types/brand';

export const initialState: IBrandsReducer = {
  isLoading: false,
  total: 0,
  brands: [],
  dialogAction: {
    id: null,
    action: BrandAction.enable,
    isOpen: false,
    isLoading: false,
  },
  importDialogAction: {
    isOpen: false,
    isLoading: false,
  },
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
  importDialogForm: {
    isOpen: false,
    isLoading: false,
  },
};

export const brandsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getBrands, (state) => ({
    ...state,
    total: 0,
    brands: [],
    isLoading: true,
  }));
  builder.addCase(getBrandsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getBrandsError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(setBrandDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));

  builder.addCase(setImportBrandDialogForm, (state, { payload }) => ({
    ...state,
    importDialogForm: {
      ...initialState.importDialogForm,
      ...payload,
    },
  }));

  builder.addCase(mergeBrandDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));

  builder.addCase(mergeImportBrandDialogForm, (state, { payload }) => ({
    ...state,
    importDialogForm: {
      ...state.importDialogForm,
      ...payload,
    },
  }));

  builder.addCase(putBrandSuccess, (state, { payload }) => ({
    ...state,
    brands: state.brands.map((brand) => {
      if (brand.id !== payload.id) {
        return brand;
      }

      return {
        ...brand,
        ...payload,
      };
    }),
  }));

  builder.addCase(setBrandsDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...initialState.dialogAction,
      ...payload,
    },
  }));
  builder.addCase(mergeBrandsDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...state.dialogAction,
      ...payload,
    },
  }));

  builder.addCase(executeBrandsActionSuccess, (state, { payload }) => ({
    ...state,
    brands: state.brands.map((brand) => {
      if (brand.id !== payload.id) {
        return brand;
      }

      return {
        ...brand,
        ...payload,
      };
    }),
  }));
});
