import React from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStylesWithDeviceInfo } from '../../hooks/useStylesWithDeviceInfo';
import { getStyles, IProgressBarStyleProps, IProgressBarStyles } from './styles/ProgressBar';

export interface IProgressBarProps extends Partial<IProgressBarStyleProps> {
  styles?: IStyleFunctionOrObject<IProgressBarStyleProps, IProgressBarStyles>;
  total: number;
  current: number;
}

const ProgressBarBase: React.FC<IProgressBarProps> = ({
  styles, total, className, classNameBar, current, children,
}) => {
  const classNames = useStylesWithDeviceInfo<IProgressBarStyles, IProgressBarStyleProps>(styles, {
    total,
    current,
    className,
    classNameBar,
  });

  return (
    <div className={classNames.root}>
      <div className={classNames.bar}>
        {children}
      </div>
    </div>
  );
};

export const ProgressBar = styled<IProgressBarProps, IProgressBarStyleProps, IProgressBarStyles>(
  ProgressBarBase,
  getStyles,
);
