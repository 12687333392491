import { IStorage } from './types';

export class ApplicationStorage {
  constructor(private storage: IStorage) {
  }

  public getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  public setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  public remove(key: string): void {
    this.storage.remove(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}
