import { createAction } from '@reduxjs/toolkit';
import {
  IPostCmsRequestBody,
  IGetCmsRequestFilterParams,
  IGetCmsResponseBody,
  IPutCmsRequestBody,
  ICmsDialogFormState,
  CmsAction,
  IAssetTypes,
} from '../types';
import { CmsId } from '../../../types/cms';

interface IExecuteActionCms {
  id: CmsId;
  action: CmsAction;
}

export const clearCms = createAction('CMS/CLEAR_CMS');

export const postCms = createAction<IPostCmsRequestBody>('CMS/POST_CMS');

export const getCms = createAction<IGetCmsRequestFilterParams>('CMS/GET_CMS');
export const getCmsSuccess = createAction<IGetCmsResponseBody>('CMS/GET_CMS/SUCCESS');
export const getCmsError = createAction('CMS/GET_CMS/ERROR');

export const putCms = createAction<IPutCmsRequestBody>('CMS/PUT_CMS');

export const setCmsDialogForm = createAction<Partial<ICmsDialogFormState> | undefined>('CMS/SET_DIALOG_FORM');
export const mergeCmsDialogForm = createAction<Partial<ICmsDialogFormState> | undefined>('CMS/MERGE_DIALOG_FORM');

export const executeCmsAction = createAction<IExecuteActionCms>('CMS/EXECUTE_ACTION');

export const getAssetTypes = createAction('CMS/GET_ASSET_TYPES');
export const getAssetTypesSuccess = createAction<IAssetTypes>('CMS/GET_ASSET_TYPES_SUCCESS');
export const getAssetTypesError = createAction('CMS/GET_ASSET_TYPES_ERROR');
