import { getUrl, makeRequest } from '../../services/api';
import {
  IAssetTypes,
  IGetCmsRequestFilterParams,
  IGetCmsResponseBody,
  IPostCmsRequestBody,
  IPutCmsRequestBody,
  IRemoveCmsRequestBody,
} from './types';

export const postCmsRequest = async (data: IPostCmsRequestBody) => {
  const { file, ...restData } = data;
  const url = getUrl('/cms', restData);

  return makeRequest(url, file, 'POST');
};

export const getCmsRequest = (filterParams: IGetCmsRequestFilterParams) => {
  const url = getUrl('/cms', filterParams);

  return makeRequest<IGetCmsResponseBody>(url);
};

export const putCmsRequest = (data: IPutCmsRequestBody) => {
  const { file, ...restData } = data;
  const url = getUrl(`/cms/${data.id}`, restData);

  return makeRequest(url, file, 'PUT');
};

export const removeCmsRequest = (data: IRemoveCmsRequestBody) => {
  const url = getUrl(`/cms/${data.id}`);

  return makeRequest(url, data, 'DELETE');
};

export const getAssetTypesRequest = () => {
  const url = getUrl('/cms/filtering/info');

  return makeRequest<IAssetTypes>(url);
};
