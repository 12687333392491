import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetRetentionReportResponseBody,
  IGetRetentionRequestFiltersParams,
  IGetRetentionProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getRetentionProcessedReportRequest = ({ filterParams }: IGetRetentionProcessedReportsFilterParams) => {
  const url = getUrl('/report/retention/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getRetentionReportRequest = (filterParams: IGetRetentionRequestFiltersParams) => {
  const url = getUrl('/report/retention', filterParams);

  return makeRequest<IGetRetentionReportResponseBody>(url);
};
