import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import { history } from 'arcadia-common-fe';
import { handleError } from '../../services/sagasErrorHandler';
import { DEFAULT_ROUTE, IRoutesMap, ROUTES_MAP } from '../../routing/constants';
import {
  signIn,
  signInError,
  signInSuccess,
  getPermissions,
  getPermissionsSuccess,
  getPermissionsError,
  logOut,
} from './actions';
import {
  signInRequest, getUserPermissionsRequest, getPermissionsRequest,
} from './api';
import { LocalStorageKeys } from '../../constants';
import {
  IGetPermissionsResponseBody,
  IGetUserPermissionsResponseBody,
  IPermission,
  IPermissionsEntities,
  UserPermissionName,
} from './types';
import { authReducerSelector } from './selectors';

const permissionSchema = new schema.Entity('permissions', {}, { idAttribute: 'name' });
const permissionsListSchema = new schema.Array(permissionSchema);

export function* handlePushUserToDefaultRoute() {
  try {
    const { userPermissions } = yield select(authReducerSelector);
    const defaultRoute = Object.values(ROUTES_MAP).find(
      (route: IRoutesMap) => route.permissionName && route.permissionName === userPermissions[0],
    );

    history.push((defaultRoute && defaultRoute.path) || DEFAULT_ROUTE.path);
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* handleGetPermissions(): any {
  try {
    const [data, userPermissions]: [IGetPermissionsResponseBody, IGetUserPermissionsResponseBody] = yield all([
      yield call(getPermissionsRequest),
      yield call(getUserPermissionsRequest),
    ]);

    const permissionNames = Object.values(UserPermissionName);
    const sortedUserPermissions = userPermissions.sort((permissionA, permissionB) => (
      permissionNames.indexOf(permissionA) - permissionNames.indexOf(permissionB)
    ));

    const { result: permissionsNames, entities } = normalize<IPermission>(data, permissionsListSchema);

    yield put(getPermissionsSuccess({
      permissionsNames,
      permissionsEntities: entities.permissions as IPermissionsEntities,
      userPermissions: sortedUserPermissions,
    }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPermissionsError());
  }
}

export function* handleSignIn({ payload }: ReturnType<typeof signIn>) {
  try {
    const { data } = yield call(signInRequest, payload);

    yield call([localStorage, 'setItem'], LocalStorageKeys.accessToken, data.token);
    yield call([localStorage, 'setItem'], LocalStorageKeys.user, JSON.stringify(data.user));
    yield put(signInSuccess(data));
    yield handleGetPermissions();
    yield handlePushUserToDefaultRoute();
  } catch (error: any) {
    yield handleError(error);
    yield put(signInError());
  }
}

export function* handleLogOut() {
  try {
    yield call([localStorage, 'removeItem'], LocalStorageKeys.accessToken);
    yield call([localStorage, 'removeItem'], LocalStorageKeys.user);
    history.push(ROUTES_MAP.signIn.path);
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* authSagas(): any {
  yield all([
    yield takeLatest(
      signIn,
      handleSignIn,
    ),
    yield takeLatest(
      logOut,
      handleLogOut,
    ),
    yield takeLatest(
      getPermissions,
      handleGetPermissions,
    ),
  ]);
}
