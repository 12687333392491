import { createReducer } from '@reduxjs/toolkit';
import { IMachineBallTableReducer } from '../types';
import {
  getMachineBallTableOpen, getMachineBallTableOpenError,
  getMachineBallTableOpenSuccess,
  mergeMachineBallsDialog,
  setMachineBallsDialog,
} from './actions';

export const initialState: IMachineBallTableReducer = {
  isLoading: false,
  table: [],
  dialogBalls: {
    isOpen: false,
    isLoading: false,
  },
};

export const machineBallTableReducer = createReducer(initialState, (builder) => {
  builder.addCase(setMachineBallsDialog, (state, { payload }) => ({
    ...state,
    dialogBalls: {
      ...initialState.dialogBalls,
      ...payload,
    },
  }));
  builder.addCase(mergeMachineBallsDialog, (state, { payload }) => ({
    ...state,
    dialogBalls: {
      ...state.dialogBalls,
      ...payload,
    },
  }));

  builder.addCase(getMachineBallTableOpen, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getMachineBallTableOpenSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getMachineBallTableOpenError, (state) => ({
    ...state,
    isLoading: false,
  }));
});
