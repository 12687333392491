import { IGetVouchersResponseBody, IGetVouchersRequestFiltersParams } from 'arcadia-common-fe';
import { getUrl, makeRequest } from '../../services/api';
import {
  IVoucherRevokeRequest,
  IGetVoucherRelatedSessionRequest,
  IGetVoucherRelatedSessionResponseBody,
} from './types';

export const getVouchersRequest = (filterParams: IGetVouchersRequestFiltersParams) => {
  const url = getUrl('/vouchers', filterParams);

  return makeRequest<IGetVouchersResponseBody>(url);
};

export const voucherRevokeRequest = (data: IVoucherRevokeRequest) => {
  const url = getUrl(`/vouchers/revoke`);

  return makeRequest(url, data, 'POST');
};

export const getVoucherRelatedSessionRequest = ({ id }: IGetVoucherRelatedSessionRequest) => {
  const url = getUrl(`/vouchers/${id}/related-session`);

  return makeRequest<IGetVoucherRelatedSessionResponseBody>(url);
};
