import React from 'react';
import { Textfit as ReactTextFit } from 'react-textfit';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStyles } from '../../hooks/useStyles';
import { useDeviceInfo } from '../../hooks/useDeviceInfo';
import { getStyles, ITextFitStyleProps, ITextFitStyles } from './styles/TextFit';

export interface ITextFitProps extends Partial<ITextFitStyleProps> {
  styles?: IStyleFunctionOrObject<ITextFitStyleProps, ITextFitStyles>;
  className?: string;
  mode?: 'single' | 'multi';
  forceSingleModeWidth?: boolean;
  min?: number;
  max?: number;
  throttle?: number;
  children?: React.ReactNode;
  onReady?: () => void;
}

const TextFitBase: React.FC<ITextFitProps> = ({ styles, className, ...restProps }) => {
  const { isResizing } = useDeviceInfo();

  const classNames = useStyles<ITextFitStyles, ITextFitStyleProps>(styles, {
    className,
  });

  if (isResizing) {
    return null;
  }

  return (
    <ReactTextFit
      className={classNames.root}
      {...restProps}
    />
  );
};

export const TextFit = React.memo(styled<ITextFitProps, ITextFitStyleProps, ITextFitStyles>(
  TextFitBase,
  getStyles,
));
