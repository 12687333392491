import { getUrl, makeRequest } from '../../services/api';
import {
  IGetCurrenciesRequestFilterParams,
  IGetCurrenciesResponseBody,
  IGetUniqueCurrenciesResponseBody,
  IPostCurrencyRequestBody,
  IPutCurrencyRequestBody,
} from './types';

export const postCurrencyRequest = (data: IPostCurrencyRequestBody) => {
  const url = getUrl('/currencyConversion');

  return makeRequest(url, data, 'POST');
};

export const getCurrenciesRequest = (filterParams: IGetCurrenciesRequestFilterParams) => {
  const url = getUrl('/currencyConversion', filterParams);

  return makeRequest<IGetCurrenciesResponseBody>(url);
};

export const getAllCurrenciesRequest = () => {
  const url = getUrl('/currencyConversion/currencies');

  return makeRequest<IGetUniqueCurrenciesResponseBody>(url);
};

export const putCurrencyRequest = (data: IPutCurrencyRequestBody) => {
  const url = getUrl(`/currencyConversion/${data.id}`);

  return makeRequest(url, data, 'PUT');
};
