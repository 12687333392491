export enum BackgroundSoundChannelName {
  background = 'background',
  christmasBackground = 'christmasBackground'

}

export enum BackgroundSound {
  background,
  christmasBackground
}
