import React from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStyles } from '../../hooks/useStyles';
import liveBadgeImg from './assets/images/live.png';
import { getStyles, ILiveBadgeStyles, ILiveBadgeStylesProps } from './styles/LiveBadge';

export interface ILiveBadgeProps extends Partial<ILiveBadgeStylesProps> {
  styles?: IStyleFunctionOrObject<ILiveBadgeStylesProps, ILiveBadgeStyles>;
}

const LiveBadgeBase: React.FC<ILiveBadgeProps> = ({ styles }) => {
  const classNames = useStyles(styles);

  return (
    <div className={classNames.root}>
      <img className={classNames.image} src={liveBadgeImg} alt="alt" />
    </div>
  );
};

export const LiveBadge = React.memo(
  styled<
    ILiveBadgeProps,
    ILiveBadgeStylesProps,
    ILiveBadgeStyles
    >(
      LiveBadgeBase,
      getStyles,
    ),
);
