import io from 'socket.io-client';
import { ConnectionOptions, ISocket } from './types';

export class SocketIO<SendEvents extends string, ReceiveEvents extends string> implements ISocket<SendEvents, ReceiveEvents> {
  private socket: SocketIOClient.Socket | null = null;

  connect(options: ConnectionOptions): SocketIO<SendEvents, ReceiveEvents> {
    this.socket = io(options.url, { reconnection: false });

    return this;
  }

  isConnected(): boolean {
    return !!this.socket;
  }

  disconnect(): void {
    this.socket?.disconnect();
  }

  emit(event: SendEvents, ...args: any[]): SocketIO<SendEvents, ReceiveEvents> {
    this.socket?.emit(event, ...args);

    return this;
  }

  on(event: ReceiveEvents, listener: (...args: any[]) => void): SocketIO<SendEvents, ReceiveEvents> {
    this.socket?.on(event, listener);

    return this;
  }
}
