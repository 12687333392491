import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getMachineStatusReport,
  getMachineStatusReportError,
  exportMachineStatusReport,
  exportMachineStatusReportError,
  exportMachineStatusReportSuccess,
  getProcessedMachineStatusReport,
  setProcessedMachineStatusReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getMachineStatusProcessedReportRequest, getMachineStatusReportRequest } from '../api';
import { IMachineStatusReportItem, IGetMachineStatusReportResponseBody, IGetMachineRequestFiltersParams } from '../types';
import { machineStatusReportReducerSelector } from './selectors';

function* handleGetMachineStatusReportRequest(requestParams: IGetMachineRequestFiltersParams) {
  const { data } = yield call(getMachineStatusReportRequest, requestParams);

  return data;
}

function* handleGetMachineStatusProcessedReport({ payload }: ReturnType<typeof getProcessedMachineStatusReport>) {
  try {
    const { data } = yield call(getMachineStatusProcessedReportRequest, payload);

    yield put(setProcessedMachineStatusReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachineStatusReportError());
  }
}

function* handleGetMachineStatusReport({ payload }: ReturnType<typeof getMachineStatusReport>) {
  try {
    const machineStatusReportData: IGetMachineStatusReportResponseBody = yield handleGetMachineStatusReportRequest(payload);
    const { info } = machineStatusReportData;
    const { filterParams } = yield select(machineStatusReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedMachineStatusReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachineStatusReportError());
  }
}

function* handleExportMachineStatusReport({ payload }: ReturnType<typeof exportMachineStatusReport>): any {
  try {
    const { data }: IGetMachineStatusReportResponseBody = yield handleGetMachineStatusReportRequest(payload);

    const preparedData = data.map((item: IMachineStatusReportItem) => ({
      'Grouping Value': item.groupingValue,
      '# Machines': item.totalMachines,
      'Available Time': item.totalAvailableTime,
      'Available Time %': item.percentAvailableTime,
      'In Play Time': item.totalInPlayTime,
      'In Play Time %': item.percentInPlayTime,
      'Offline Time': item.totalOfflineTime,
      'Offline Time %': item.percentOfflineTime,
      'Stopped Time': item.totalStoppedTime,
      'Stopped Time %': item.percentStoppedTime,
      'Shutting Down Time': item.totalShuttingDownTime,
      'Shutting Down Time %': item.percentShuttingDownTime,
      'Preparing Time': item.totalPreparingTime,
      'Preparing Time %': item.percentPreparingTime,
      'Ready Time': item.totalReadyTime,
      'Ready Time %': item.percentReadyTime,
      'Seeding Time': item.totalSeedingTime,
      'Seeding Time %': item.percentSeedingTime,
      'Hold Time': item.totalOnHoldTime,
      'Hold Time %': item.percentOnHoldTime,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Machine Status Report.csv');
    yield put(exportMachineStatusReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportMachineStatusReportError());
  }
}

export function* machineStatusReportSagas(): any {
  yield all([
    yield takeLatest(
      getMachineStatusReport,
      handleGetMachineStatusReport,
    ),
    yield takeLatest(
      getProcessedMachineStatusReport,
      handleGetMachineStatusProcessedReport,
    ),
    yield takeLatest(
      exportMachineStatusReport,
      handleExportMachineStatusReport,
    ),
  ]);
}
