import React, { useCallback } from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { motion } from 'framer-motion';
import { useStylesWithDeviceInfo } from '../../hooks';
import IconArrow from './assets/arrowLeft.svg';
import { getStyles, IAccordionStyleProps, IAccordionStyles } from './styles/Accordion';

export interface IAccordionProps extends Partial<IAccordionStyleProps> {
  styles?: IStyleFunctionOrObject<IAccordionStyleProps, IAccordionStyles>;
  label: React.ReactNode;
  isExpanded: boolean;
  onChange: (value: boolean) => void;
  renderContentIfCollapsed?: boolean;
  customArrowComponent?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  children?: React.ReactNode;
}

const AccordionBase: React.FC<IAccordionProps> = ({
  styles,
  className,
  label,
  isExpanded,
  children,
  onChange,
  renderContentIfCollapsed,
  customArrowComponent: CustomArrow,
}) => {
  const handleOnChange = useCallback(() => (
    onChange(!isExpanded)
  ), [onChange, isExpanded]);

  const classNames = useStylesWithDeviceInfo<IAccordionStyles, IAccordionStyleProps>(styles, {
    className,
    isExpanded,
  });

  return (
    <div className={classNames.accordion}>
      <div
        className={classNames.header}
        role="button"
        tabIndex={0}
        onClick={handleOnChange}
      >
        {label}
        { CustomArrow ? (
          <CustomArrow className={classNames.icon} />
        ) : (
          <IconArrow className={classNames.icon} />
        )}
      </div>
      <motion.section
        initial="collapsed"
        animate={isExpanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div className={classNames.children}>
          { (renderContentIfCollapsed || isExpanded) && children }
        </div>
      </motion.section>
    </div>
  );
};

export const Accordion = React.memo(
  styled<
    IAccordionProps,
    IAccordionStyleProps,
    IAccordionStyles
    >(
      AccordionBase,
      getStyles,
    ),
);
