import { AdministrationAction, AdministrationUserStatus } from './types';
import { UserPermissionName } from '../auth/types';

export const administrationStatusLabelMap: { [key in AdministrationUserStatus]: string } = {
  [AdministrationUserStatus.enabled]: 'Enabled',
  [AdministrationUserStatus.disabled]: 'Disabled',
};

export const administrationActionLabelMap: { [key in AdministrationAction]: string } = {
  [AdministrationAction.enable]: 'Enable',
  [AdministrationAction.disable]: 'Disable',
  [AdministrationAction.remove]: 'Remove',
};

export const permissionLabelMap: { [key in UserPermissionName]: string } = {
  [UserPermissionName.dashboard]: 'Dashboard',
  [UserPermissionName.sessions]: 'Sessions',
  [UserPermissionName.players]: 'Players',
  [UserPermissionName.groups]: 'Groups',
  [UserPermissionName.machines]: 'Machines',
  [UserPermissionName.vouchers]: 'Vouchers',
  [UserPermissionName.operators]: 'Operators',
  [UserPermissionName.reports]: 'Reports',
  [UserPermissionName.alerts]: 'Alerts',
  [UserPermissionName.disputes]: 'Disputes',
  [UserPermissionName.monitoring]: 'Monitoring',
  [UserPermissionName.administration]: 'Administration',
  [UserPermissionName.cameras]: 'Cameras',
  [UserPermissionName.maintenance]: 'Maintenance',
  [UserPermissionName.transactions]: 'Transactions',
  [UserPermissionName.sites]: 'Sites',
  [UserPermissionName.cms]: 'CMS',
  [UserPermissionName.currencyManager]: 'Сurrency',
  [UserPermissionName.brandSecrets]: 'Credentials',
  [UserPermissionName.brands]: 'Brands',
};
