import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from 'arcadia-common-client';
import { Color, Time } from '../../../styles/constants';

export interface IProgressBarStyleProps extends IDeviceInfoContext {
  total: number;
  current: number;
  className?: string;
  classNameBar?: string;
}

export interface IProgressBarStyles {
  root: IStyle;
  bar: IStyle;
}

export const getStyles = ({
  total, current, className, classNameBar, calculateRemValue,
}: IProgressBarStyleProps): IProgressBarStyles => ({
  root: [
    {
      position: 'relative',
      display: 'block',
      width: '100%',
      height: calculateRemValue(7),
      overflow: 'hidden',
      borderRadius: calculateRemValue(3.5),
      border: `${calculateRemValue(0.2)} solid ${Color.progressBar.mainColor}`,
    },
    className,
  ],
  bar: [
    {
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: `${(current * 100) / total}%`,
      transition: `${Time.defaultAnimationTime}s width linear`,
      background: Color.progressBar.mainColor,
    },
    classNameBar,
  ],
});
