import { createAction } from '@reduxjs/toolkit';
import {
  IGetPlayerBlocksRequestFiltersParams,
  IGetPlayerBlocksProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getPlayerBlocksReport = createAction<IGetPlayerBlocksRequestFiltersParams>('REPORT/GET_PLAYER_BLOCKS');
export const getPlayerBlocksReportError = createAction('REPORT/GET_PLAYER_BLOCKS/ERROR');

export const getProcessedPlayerBlocksReport = createAction<
  IGetPlayerBlocksProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_PLAYER_BLOCKS_REPORT/SUCCESS');
export const setProcessedPlayerBlocksReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_PLAYER_BLOCKS_REPORTS/SUCCESS');

export const exportPlayerBlocksReport = createAction<IGetPlayerBlocksRequestFiltersParams>('REPORT/EXPORT_PLAYER_BLOCKS');
export const exportPlayerBlocksReportSuccess = createAction('REPORT/EXPORT_PLAYER_BLOCKS/SUCCESS');
export const exportPlayerBlocksReportError = createAction('REPORT/EXPORT_PLAYER_BLOCKS/ERROR');
