import React from 'react';
import { useTranslation } from 'react-i18next';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStylesWithDeviceInfo, GameId } from 'arcadia-common-client';
import { Overlay } from '../Overlay/Overlay';
import { Button } from '../Button/Button';
import { getStyles, IMoreGamesStyleProps, IMoreGamesStyles } from './styles/MoreGames.styles';
import imgBtnClose from './assets/images/closeMoreGamesBtn.png';
import roulettePoster from './assets/images/roulettePoster.png';
import clawPoster from './assets/images/clawPoster.png';
import pusherPoster from './assets/images/pusherPoster.png';

export interface IMoreGamesProps extends Partial<IMoreGamesStyleProps> {
  styles?: IStyleFunctionOrObject<IMoreGamesStyleProps, IMoreGamesStyles>;
  isOpen?: boolean;
  onClose?: () => void;
  onSelect?: (gameId: GameId) => void;
}

export const MoreGamesBase: React.FC<IMoreGamesProps> = ({
  styles,
  isOpen,
  onClose,
  onSelect,
   }) => {
    const { t } = useTranslation();

    const classNames = useStylesWithDeviceInfo(styles);

    return (
        <Overlay
          className={classNames.overlay}
          classNameContent={classNames.overlayContent}
          isVisible={isOpen}
          onClick={onClose}
        >
          <div className={classNames.card}>
            <div className={classNames.closeBtnContainer}>
              <Button
                normalImg={imgBtnClose}
                className={classNames.closeBtn}
                onClick={onClose}
              />
              <span className={classNames.closeBtnText}>{t('Menu.MoreGamesPanel.Close')}</span>
            </div>
            <div className={classNames.contentContainer}>
              <ul>
                <li><Button onClick={() => onSelect?.(GameId.CLAW)} normalImg={clawPoster} className={classNames.moreGamesLink}/></li>
                <li><Button onClick={() => onSelect?.(GameId.PUSHER)} normalImg={pusherPoster} className={classNames.moreGamesLink}/></li>
                <li><Button onClick={() => onSelect?.(GameId.ROULETTE)} normalImg={roulettePoster} className={classNames.moreGamesLink}/></li>
              </ul>
            </div>
          </div>
        </Overlay>
    );
  };
  
  export const MoreGames = styled<IMoreGamesProps, IMoreGamesStyleProps, IMoreGamesStyles>(
    MoreGamesBase,
    getStyles,
  );