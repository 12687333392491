import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getPlayerBlocksReport,
  getPlayerBlocksReportError,
  exportPlayerBlocksReport,
  exportPlayerBlocksReportError,
  exportPlayerBlocksReportSuccess,
  getProcessedPlayerBlocksReport,
  setProcessedPlayerBlocksReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getPlayerBlocksProcessedReportRequest, getPlayerBlocksReportRequest } from '../api';
import {
  IPlayerBlocksReportItem,
  IGetPlayerBlocksReportResponseBody,
  IGetPlayerBlocksRequestFiltersParams,
} from '../types';
import { playerBlocksReportReducerSelector } from './selectors';

function* handleGetPlayerBlocksReportRequest(requestParams: IGetPlayerBlocksRequestFiltersParams) {
  const { data } = yield call(getPlayerBlocksReportRequest, requestParams);

  return data;
}

function* handleGetPlayerBlocksProcessedReport({ payload }: ReturnType<typeof getProcessedPlayerBlocksReport>) {
  try {
    const { data } = yield call(getPlayerBlocksProcessedReportRequest, payload);

    yield put(setProcessedPlayerBlocksReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPlayerBlocksReportError());
  }
}

function* handleGetPlayerBlocksReport({ payload }: ReturnType<typeof getPlayerBlocksReport>) {
  try {
    const playerBlocksReportData: IGetPlayerBlocksReportResponseBody = yield handleGetPlayerBlocksReportRequest(payload);
    const { info } = playerBlocksReportData;
    const { filterParams } = yield select(playerBlocksReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedPlayerBlocksReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPlayerBlocksReportError());
  }
}

function* handleExportPlayerBlocksReport({ payload }: ReturnType<typeof exportPlayerBlocksReport>): any {
  try {
    const { data }: IGetPlayerBlocksReportResponseBody = yield handleGetPlayerBlocksReportRequest(payload);

    const preparedData = data.map((item: IPlayerBlocksReportItem) => ({
      'Grouping Value': item.groupingValue,
      Blocked: item.totalBlocked,
      Unblocked: item.totalUnblocked,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Player Blocks Report.csv');
    yield put(exportPlayerBlocksReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportPlayerBlocksReportError());
  }
}

export function* playerBlocksReportSagas(): any {
  yield all([
    yield takeLatest(
      getPlayerBlocksReport,
      handleGetPlayerBlocksReport,
    ),
    yield takeLatest(
      getProcessedPlayerBlocksReport,
      handleGetPlayerBlocksProcessedReport,
    ),
    yield takeLatest(
      exportPlayerBlocksReport,
      handleExportPlayerBlocksReport,
    ),
  ]);
}
