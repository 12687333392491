import React from 'react';
import { useTranslation } from 'react-i18next';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { Overlay } from '../Overlay/Overlay';
import { OverlayBackdropColor } from '../Overlay/styles/Overlay.styles';
import { useStylesWithDeviceInfo } from '../../hooks/useStylesWithDeviceInfo';
import { getStyles, IExitStyleProps, IExitStyles } from './styles/Exit';

interface IExitProps extends Partial<IExitStyleProps> {
  styles?: IStyleFunctionOrObject<IExitStyleProps, IExitStyles>;
}

const ExitBase: React.FC<IExitProps> = ({ styles }) => {
  const { t } = useTranslation();

  const classNames = useStylesWithDeviceInfo<IExitStyles, IExitStyleProps>(styles);

  return (
    <Overlay
      classNameContent={classNames.content}
      overlayBackdropColor={OverlayBackdropColor.primarySolid}
      isVisible
    >
      {t('Exit.Title')}
    </Overlay>
  );
};

export const Exit = styled<IExitProps, IExitStyleProps, IExitStyles>(
  ExitBase,
  getStyles,
);
