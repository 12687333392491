import { SessionAction, SessionLogSource, SessionStatus } from './types';
import { SessionMode } from '../../types/sessions';

export const sessionActionLabelMap: Record<SessionAction, string> = {
  [SessionAction.terminate]: 'Terminate',
};

export const sessionsLabelMap: { [key in SessionStatus]: string } = {
  [SessionStatus.viewer]: 'Viewer',
  [SessionStatus.playing]: 'Playing',
  [SessionStatus.autoplay]: 'Autoplay',
  [SessionStatus.queue]: 'In Queue',
  [SessionStatus.terminating]: 'Terminating',
  [SessionStatus.completed]: 'Completed',
  [SessionStatus.terminated]: 'Terminated',
  [SessionStatus.forcedAutoplay]: 'Forced Autoplay',
  [SessionStatus.viewerBetBehind]: 'Viewer Bet Behind',
  [SessionStatus.queueBetBehind]: 'Queue Bet Behind',
  [SessionStatus.reBuy]: 'reBuy',
};

export const sessionLogSourceLabelMap: { [key in SessionLogSource]: string } = {
  [SessionLogSource.player]: 'Player',
  [SessionLogSource.robot]: 'Robot',
  [SessionLogSource.game]: 'Game',
};

export const sessionsModeLabelMap: { [key in SessionMode]: string } = {
  [SessionMode.active]: 'Active',
  [SessionMode.archive]: 'Archive',
};

export const searchParseOptions = {
  parseNumbers: true,
  customParseOptionsByKey: {
    playerCid: {
      parseNumbers: false,
    },
  },
};
