import { useMemo } from 'react';
import queryString, { ParseOptions } from 'query-string';
import { useLocation } from 'react-router-dom';

export interface IUseSearchParamsOptions extends ParseOptions {
  customParseOptionsByKey?: { [key: string]: ParseOptions }
}

export const useSearchParams = (options: IUseSearchParamsOptions = { parseNumbers: true }) => {
  const { search } = useLocation();

  return useMemo(() => {
    const { customParseOptionsByKey } = options;
    let result = queryString.parse(search, options);

    if (customParseOptionsByKey) {
      Object.keys(customParseOptionsByKey).forEach((key) => {
        const parseOptions = { ...options, ...customParseOptionsByKey[key] };
        const value = queryString.parse(search, parseOptions)[key];

        result = {
          ...result,
          [key]: value,
        };
      });
    }

    return result;
    // TODO: options must be in deps BEGIN
  }, [search]);
  // TODO: options must be in deps END
};
