import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { useSearchParams } from './searchParams';

interface IDefaultFilterParams {
  [key: string]: any
  offset: number
  take: number
}

export const useExport = <TFilterParams = IDefaultFilterParams>(total: number, actionCreator: (filterParams: TFilterParams) => void) => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams() as unknown as TFilterParams;

  const handleExport = useCallback(() => {
    dispatch(actionCreator({
      ...searchParams,
      offset: 0,
      take: total,
    }));
  }, [total, searchParams]);

  return {
    handleExport,
  };
};
