import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  voucherStatusLabelMap,
  getDateTimeFormatted,
  saveStringAsFile,
  IGetVouchersRequestFiltersParams,
  IVouchers,
  IVouchersNormalizedData,
  history,
  dateSetEndOfTheDate,
} from 'arcadia-common-fe';
import { normalize, schema } from 'normalizr';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getVouchers,
  getVouchersError,
  getVouchersSuccess,
  exportVouchers,
  exportVouchersError,
  exportVouchersSuccess,
  vouchersRevoke,
  mergeVouchersRevokeDialog,
  setVouchersRevokeDialog,
  openVoucherSession,
  setIsVoucherSessionLoading,
} from './actions';
import { getVoucherRelatedSessionRequest, getVouchersRequest, voucherRevokeRequest } from '../api';
import { ROUTES_MAP } from '../../../routing/constants';
import { modulesInitialFilters } from '../../../constants';
import { SessionMode } from '../../../types/sessions';

const vouchersSchema = new schema.Entity('vouchers');
const vouchersListSchema = new schema.Array(vouchersSchema);

function* handleGetVouchersDataRequest(requestParams: IGetVouchersRequestFiltersParams) {
  const { data } = yield call(getVouchersRequest, requestParams);
  const { result: vouchersIds, entities } = normalize<IVouchers>(data.vouchers, vouchersListSchema);

  return {
    ids: vouchersIds,
    entities: entities.vouchers,
    total: data.total,
  };
}

function* handleRefreshVouchers() {
  yield put(getVouchers(queryString.parse(window.location.search)));
}

function* handleGetVouchers({ payload }: ReturnType<typeof getVouchers>) {
  try {
    const vouchersData: IVouchersNormalizedData = yield handleGetVouchersDataRequest(payload);

    yield put(getVouchersSuccess(vouchersData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getVouchersError());
  }
}

function* handleVouchersRevoke({ payload }: ReturnType<typeof vouchersRevoke>): any {
  try {
    yield put(mergeVouchersRevokeDialog({ isLoading: true }));

    const { ids, reason, password } = payload;

    yield call(voucherRevokeRequest, { ids, reason, password });
    yield call(
      toast.success,
      `The Voucher${ids.length > 1 ? 's' : ''} with id -
      ${ids.join(', ')} ${ids.length > 1 ? 'have' : 'has'} been successfully revoke`,
    );
    yield put(setVouchersRevokeDialog());
    yield handleRefreshVouchers();
  } catch (error: any) {
    yield put(mergeVouchersRevokeDialog({ isLoading: false }));
    yield handleError(error);
  }
}

function* handleExportVouchers({ payload }: ReturnType<typeof exportVouchers>): any {
  try {
    const { entities, ids }: IVouchersNormalizedData = yield handleGetVouchersDataRequest(payload);

    const preparedData = ids.map((id) => {
      const voucher = entities[id];

      return ({
        Status: voucherStatusLabelMap[voucher.status],
        'Voucher ID': voucher.id,
        'Operator Name': voucher.operatorName,
        'Player CID': voucher.playerCid,
        'Group Name': voucher.groupName,
        'Granted Date': getDateTimeFormatted(voucher.grantedDate),
        'Expiration Date': getDateTimeFormatted(voucher.expirationDate),
        Reason: voucher.revocationReason,
      });
    });

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Vouchers.csv');
    yield put(exportVouchersSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportVouchersError());
  }
}

function* handleOpenVoucherSession({ payload }: ReturnType<typeof openVoucherSession>) {
  try {
    yield put(setIsVoucherSessionLoading({ isLoading: true }));

    const { data } = yield call(getVoucherRelatedSessionRequest, payload);
    const { sessionId: id, endDate } = data;

    const endOfDayDate = dateSetEndOfTheDate(endDate).toISOString();

    yield put(setIsVoucherSessionLoading({ isLoading: false }));

    if (!id && !endDate) {
      yield call(toast.error, `The voucher with id ${payload.id} is not used on sessions yet.`);
    } else if (id && !endDate) {
      const url = ROUTES_MAP.sessions.createURL({
        ...modulesInitialFilters.sessions[SessionMode.active],
        id,
      });

      history.push(url);
    } else if (id && endDate) {
      const url = ROUTES_MAP.sessions.createURL({
        ...modulesInitialFilters.sessions[SessionMode.archive],
        id,
        endDate: endOfDayDate,
      });

      history.push(url);
    }
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* vouchersSagas(): any {
  yield all([
    yield takeLatest(
      getVouchers,
      handleGetVouchers,
    ),
    yield takeLatest(
      vouchersRevoke,
      handleVouchersRevoke,
    ),
    yield takeLatest(
      exportVouchers,
      handleExportVouchers,
    ),
    yield takeLatest(
      openVoucherSession,
      handleOpenVoucherSession,
    ),
  ]);
}
