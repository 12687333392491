import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetPlayerStatsReportResponseBody,
  IGetPlayerStatsRequestFiltersParams,
  IGetPlayerStatsProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getPlayerStatsProcessedReportRequest = ({ filterParams }: IGetPlayerStatsProcessedReportsFilterParams) => {
  const url = getUrl('/report/player-stats/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getPlayerStatsReportRequest = (filterParams: IGetPlayerStatsRequestFiltersParams) => {
  const url = getUrl('/report/player-stats', filterParams);

  return makeRequest<IGetPlayerStatsReportResponseBody>(url);
};
