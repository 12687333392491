import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getActivityReport,
  getActivityReportError,
  exportActivityReportError,
  exportActivityReportSuccess,
  exportActivityReport,
  getProcessedActivityReport,
  setProcessedActivityReportsSuccess,
} from './actions';
import { IActivityReportReducer } from '../types';

export const initialState: IActivityReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const activityReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getActivityReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getActivityReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportActivityReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportActivityReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportActivityReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedActivityReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedActivityReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
