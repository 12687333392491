import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetFunnelReportResponseBody,
  IGetFunnelRequestFiltersParams,
  IGetFunnelProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getFunnelProcessedReportRequest = ({ filterParams }: IGetFunnelProcessedReportsFilterParams) => {
  const url = getUrl('/report/funnel/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getFunnelReportRequest = (filterParams: IGetFunnelRequestFiltersParams) => {
  const url = getUrl('/report/funnel', filterParams);

  return makeRequest<IGetFunnelReportResponseBody>(url);
};
