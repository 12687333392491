import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from 'arcadia-common-client';
import { Color, FontFamily, FontSize } from '../../../styles/constants';
import { BORDER_COLOR } from '../constants'
import moreGamesBG from '../assets/images/moreGamesBG.png';

export interface IMoreGamesStyleProps extends IDeviceInfoContext {
}

export interface IMoreGamesStyles {
  card: IStyle;
  overlay: IStyle;
  overlayContent: IStyle;
  closeBtnContainer: IStyle;
  closeBtn: IStyle;
  closeBtnText: IStyle;
  contentContainer: IStyle;
  moreGamesLink: IStyle;
}

export const getStyles = ({ calculateRemValue }: IMoreGamesStyleProps): IMoreGamesStyles => ({
  overlay: {
    position: 'absolute',
  },
  overlayContent: {
    height: '100%',
    padding: 0,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '3rem',
    border: 0,
    boxShadow: 'none',
    width: '100%',
    minWidth: calculateRemValue(115),
    height: '100%',
    backgroundColor: Color.black,
    backgroundImage: `url(${moreGamesBG})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    borderRadius: 0,
  },
  closeBtnContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: calculateRemValue(4),
    right: calculateRemValue(5),
    zIndex: 1,
  },
  closeBtn:{
    width: calculateRemValue(8),
  },
  closeBtnText: {
    display: 'block',
    textTransform: 'uppercase',
    marginTop: '2rem',
    fontSize: FontSize.Size9,
    fontStyle: FontFamily.tertiary,
  },
  contentContainer: {
    position: 'relative',
    top: calculateRemValue(20),
    width: '100%',
    fontSize: 10,
    borderTop: `1px solid ${BORDER_COLOR}`,
  },
  moreGamesLink: {
    display: 'block',
    maxWidth: calculateRemValue(115),
    minHeight: calculateRemValue(52),
    border: `1px solid ${BORDER_COLOR}`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4rem',
    marginTop: '4rem',
    overflow: 'hidden',
    cursor: 'pointer',
    "&:hover": {
      filter: 'brightness(1.25)',
    },
  },
});
