import React, { useCallback } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import IconArrow from '../../assets/svg/arrowLeft.svg';
import './Accordion.scss';

interface IAccordionProps {
  className?: string
  label: React.ReactNode
  isExpanded: boolean
  onChange: (value: boolean) => void
  renderContentIfCollapsed?: boolean
  customArrowComponent?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
}

export const Accordion: React.FC<IAccordionProps> = ({
  className,
  label,
  isExpanded,
  children,
  onChange,
  renderContentIfCollapsed,
  customArrowComponent: CustomArrow,
}) => {
  const handleOnChange = useCallback(() => (
    onChange(!isExpanded)
  ), [onChange, isExpanded]);

  return (
    <div className={classNames('accordion', { 'accordion--expanded': isExpanded }, className)}>
      <div
        className={classNames('accordion__header', { 'accordion__header--expanded': isExpanded })}
        role="button"
        tabIndex={0}
        onClick={handleOnChange}
      >
        {label}
        { CustomArrow ? (
          <CustomArrow className={classNames('accordion__icon', { 'accordion__icon--expanded': isExpanded })} />
        ) : (
          <IconArrow className={classNames('accordion__icon', { 'accordion__icon--expanded': isExpanded })} />
        )}
      </div>
      <motion.section
        initial="collapsed"
        animate={isExpanded ? 'open' : 'collapsed'}
        exit="collapsed"
        variants={{
          open: { opacity: 1, height: 'auto' },
          collapsed: { opacity: 0, height: 0 },
        }}
        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
      >
        <div className="accordion__children">
          { (renderContentIfCollapsed || isExpanded) && children }
        </div>
      </motion.section>
    </div>
  );
};
