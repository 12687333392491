import { ICommonRequestFiltersParams } from 'arcadia-common-fe';
import { OperatorId } from '../../types/operators';
import { BrandId } from '../../types/brand';
import { PlayerId } from '../../types/player';

export enum PlayerStatus {
  active = 'active',
  inPlay = 'in-play',
  blocked = 'blocked'
}

export enum PlayerAction {
  block,
  unblock,
  toggleTester
}

export type PlayerCID = string;

export interface IPlayer {
  cid: PlayerCID;
  name: string;
  operatorName: string;
  operatorId: OperatorId;
  brandId: BrandId;
  brandName: string;
  id: PlayerId;
  status: PlayerStatus;
  isTester: boolean;
  bets: number;
  wins: number;
  netCash: number;
  createdDate: string;
  lastSessionDate: string;
  blockReason: string;
  settings: {};
}

export type IPlayers = IPlayer[];

export interface IPlayersFiltersPanelValues {
  cid?: PlayerCID;
  status?: PlayerStatus[];
  operatorName?: string;
  brandId?: BrandId;
  betsFrom?: number;
  betsTo?: number;
  winsFrom?: number;
  winsTo?: number;
  netCashFrom?: number;
  netCashTo?: number;
  lastSessionDateFrom?: string;
  lastSessionDateTo?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
}

export interface IGetPlayersRequestFilterParams extends ICommonRequestFiltersParams, IPlayersFiltersPanelValues {
}

export interface IGetPlayersResponseBody extends ICommonRequestFiltersParams {
  total: number;
  players: IPlayers;
}

export interface IPlayerActionRequestBody {
  id: PlayerId;
  reason?: string;
  password?: string;
}

export interface IDialogActionState {
  id: PlayerId;
  action: PlayerAction;
  isOpen: boolean;
  isLoading?: boolean;
}

export interface IPlayersReducer extends IGetPlayersResponseBody {
  readonly isLoading: boolean;
  readonly isExporting: boolean;
  readonly dialogAction: IDialogActionState;
}

export interface IPlayersSlice {
  playersReducer: IPlayersReducer;
}
