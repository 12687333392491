import { Environment } from '../../environment';

export enum VideoPlayerType {
  CSRTCPlayer = 'CSRTCPlayer',
  HLSPlayer = 'HLSPlayer'
}

export enum HLSVideoPlayerStatus { // https://docs.videojs.com/player
  waiting = 'waiting',
  abort = 'abort',
  canplay = 'canplay',
  canplaythrough = 'canplaythrough',
  emptied = 'emptied',
  ended = 'ended',
  error = 'error',
  firstplay = 'firstplay',
  loadstart = 'loadstart',
  pause = 'pause',
  play = 'play',
  playing = 'playing',
  progress = 'progress',
  stalled = 'stalled',
  suspend = 'suspend',
}

export enum MediaProvider {
  WebRTC = 'WebRTC',
  MSE = 'MSE'
}

export enum CSRTCVideoPlayerSessionStatus {
  ESTABLISHED = 'ESTABLISHED',
  PENDING = 'PENDING',
  DISCONNECTED = 'DISCONNECTED',
  FAILED = 'FAILED',
  UNREGISTERED = 'UNREGISTERED',
}

export enum CSRTCVideoPlayerStatus {
  FAILED = 'FAILED',
  NEW = 'NEW',
  NOT_ENOUGH_BANDWIDTH = 'NOT_ENOUGH_BANDWIDTH',
  PAUSED = 'PAUSED',
  PENDING = 'PENDING',
  PLAYBACK_PROBLEM = 'PLAYBACK_PROBLEM',
  PLAYING = 'PLAYING',
  PUBLISHING = 'PUBLISHING',
  RESIZE = 'RESIZE',
  SNAPSHOT_COMPLETE = 'SNAPSHOT_COMPLETE',
  STOPPED = 'STOPPED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum VideoStreamStatus {
  loading = 'loading',
  playing = 'playing',
  paused = 'paused',
}

interface IIceServer {
  url: string;
  username: string;
  credential: string;
}

type IIceServers = IIceServer[];
export type IIceServersByEnvironment = Record<Environment, IIceServers>;

export enum VideoStatusChangeType {
  CSRTCPlayerSessionStatus = 'CSRTCPlayerSessionStatus',
  CSRTCPlayerStreamStatus = 'CSRTCPlayerStreamStatus',
  HLSPlayerStreamStatus = 'HLSPlayerStreamStatus'
}

export enum CSRTCVideoFailedEventReason {
  poorConnection = 'poorConnection',
  disabledTunneling = 'disabledTunneling',
  videoNotAvailableWithTunneling = 'videoNotAvailableWithTunneling'
}

export enum VideoStreamQuality {
  high = 'high',
  low = 'low',
}

export enum PubSubUserEventNotification {
  orientationChanged = 'orientationChanged',
  menuClicked = 'menuClicked',
  menuClosed = 'menuClosed',
  settingsUpdate = 'settingsUpdate',
  lostFocus = 'lostFocus',
  regainedFocus = 'regainedFocus',
  video = 'video'
}

export interface IWebrtcSrc {
  src: string;
  rtsp: string;
  preloaderUrl: string;
  initOption?: object;
  sessionOptions?: object;
  iceServersByEnvironment: IIceServersByEnvironment;
}

export interface IStreamStatusData {
  quality: VideoStreamQuality;
  videoStreamStatus: VideoStreamStatus;
}

export interface IUserEventNotification {
  type: PubSubUserEventNotification;
  data: object;
}

export interface IVideoFailedEvent {
  connectionSpeedKbps?: number[];
  reason: CSRTCVideoFailedEventReason;
}

export interface IVideoZoomData {
  isVideoZoomed: boolean;
  offsetX: number;
  offsetY: number;
}
