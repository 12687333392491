import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { getDateTimeFormatted, saveStringAsFile } from 'arcadia-common-fe';
import { AxiosResponse } from 'axios';
import { handleError } from '../../../services/sagasErrorHandler';
import { IGetMaintenanceRequestFiltersParams, IGetMaintenanceResponseBody } from '../types';
import { AlertAction, AlertId } from '../../../types/alerts';
import {
  getMaintenance,
  getMaintenanceError,
  getMaintenanceSuccess,
  exportMaintenance,
  exportMaintenanceError,
  exportMaintenanceSuccess,
  executeMaintenanceAction,
  mergeMaintenanceDialogAction,
  setMaintenanceDialogAction,
  setMaintenanceDialogQRScan,
} from './actions';
import {
  getMaintenanceRequest,
  maintenanceDismissRequest,
  maintenanceEmptyDispenserWaistRequest,
  maintenanceFillDispenserRequest,
} from '../api';

function* handleRefreshList() {
  yield put(getMaintenance(queryString.parse(window.location.search)));
}

function* handleGetMaintenanceDataRequest(requestParams: IGetMaintenanceRequestFiltersParams) {
  const { data } = yield call(getMaintenanceRequest, requestParams);

  return data;
}

function* handleGetMaintenance({ payload }: ReturnType<typeof getMaintenance>) {
  try {
    const data: IGetMaintenanceResponseBody = yield handleGetMaintenanceDataRequest(payload);

    yield put(getMaintenanceSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMaintenanceError());
  }
}

function* handleExportMaintenance({ payload }: ReturnType<typeof exportMaintenance>): any {
  try {
    const { alerts }: IGetMaintenanceResponseBody = yield handleGetMaintenanceDataRequest(payload);

    const preparedData = alerts.map((alert) => ({
      'Alert ID': alert.id,
      Severity: alert.severity,
      'Machine Serial': alert.machineSerial,
      'Machine Name': alert.machineName,
      Source: alert.source,
      'Date & Time': getDateTimeFormatted(alert.date),
      Description: alert.description,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Maintenance.csv');
    yield put(exportMaintenanceSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportMaintenanceError());
  }
}

function* handleExecuteMaintenanceAction({ payload }: ReturnType<typeof executeMaintenanceAction>) {
  try {
    const { id, action } = payload;

    yield put(mergeMaintenanceDialogAction({ isLoading: true }));

    const actionValues: Record<AlertAction, { fetchCallback: (id: AlertId) => Promise<AxiosResponse>; successMessage: string }> = {
      [AlertAction.fillDispenser]: {
        fetchCallback: maintenanceFillDispenserRequest,
        successMessage: 'The dispenser has been successfully filled up.',
      },
      [AlertAction.emptyDispenserWaist]: {
        fetchCallback: maintenanceEmptyDispenserWaistRequest,
        successMessage: 'The dispenser waist has been successfully emptied.',
      },
      [AlertAction.dismiss]: {
        fetchCallback: maintenanceDismissRequest,
        successMessage: `The Alert with id ${id} has been successfully dismissed.`,
      },
    };

    yield call(actionValues[action].fetchCallback, id);
    yield call(toast.success, actionValues[action].successMessage);

    yield put(setMaintenanceDialogAction());
    yield handleRefreshList();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeMaintenanceDialogAction({ isLoading: false }));
  }
}

function* handleSetMaintenanceDialogQRScan({ payload }: ReturnType<typeof setMaintenanceDialogQRScan>) {
  if (!payload?.isOpen) {
    return;
  }

  try {
    yield navigator.mediaDevices.getUserMedia({ video: true });
  } catch (error: any) {
    yield call(toast.error, 'Browser doesn’t have access to your camera');
  }
}

export function* maintenanceSagas(): any {
  yield all([
    yield takeLatest(
      getMaintenance,
      handleGetMaintenance,
    ),
    yield takeLatest(
      exportMaintenance,
      handleExportMaintenance,
    ),
    yield takeLatest(
      executeMaintenanceAction,
      handleExecuteMaintenanceAction,
    ),
    yield takeLatest(
      setMaintenanceDialogQRScan,
      handleSetMaintenanceDialogQRScan,
    ),
  ]);
}
