import { useCallback, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { IUseSearchParamsOptions, useSearchParams } from './searchParams';
import { ITEMS_PER_PAGE } from '../constants';
import { history } from '../routing';

interface IUsePaginationOptions {
  useSearchParamsOptions?: IUseSearchParamsOptions;
  itemsPerPage?: number;
}

export const usePagination = (options?: IUsePaginationOptions) => {
  const { pathname } = useLocation();
  const searchParams = useSearchParams(options?.useSearchParamsOptions);
  const offset = Number(searchParams.offset) || 0;
  const perPage = options?.itemsPerPage ?? ITEMS_PER_PAGE;
  const [page, setPage] = useState(0);

  useEffect(() => {
    setPage(offset / perPage || 0);
  }, [offset, page]);

  const updateSearchParams = useCallback((selected) => {
    const newSearchParams = {
      ...searchParams,
      offset: selected * perPage,
    };

    history.push(`${pathname}?${queryString.stringify(newSearchParams)}`);
  }, [perPage, pathname, searchParams]);


  const handlePageChange = useCallback(({ selected }) => {
    if (Number.isNaN(selected)) {
      return;
    }

    updateSearchParams(selected);
  }, [updateSearchParams]);

  const resetPage = useCallback(() => {
    setPage(0)
    updateSearchParams(0);
  }, []);

  return {
    handlePageChange,
    forcePage: page,
    offset,
    resetPage
  };
};
