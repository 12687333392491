import { useMemo } from 'react';
import { classNamesFunction, IStyleFunctionOrObject } from '@fluentui/utilities';

export const useStyles = <TStyles, TStyleProps = undefined>(
  styles: IStyleFunctionOrObject<TStyleProps, TStyles> = {},
  props?: TStyleProps,
) => {
  const getClassNames = useMemo(() => (
    classNamesFunction<TStyleProps, TStyles>()
  ), []);

  return getClassNames(styles, props);
};
