interface IVisibilityVendorData {
  hidden: string;
  event: string;
}

const visibilityVendorsData: IVisibilityVendorData[] = [
  {
    hidden: 'hidden',
    event: 'visibilitychange',
  },
  {
    hidden: 'webkitHidden',
    event: 'webkitvisibilitychange',
  },
  {
    hidden: 'mozHidden',
    event: 'mozvisibilitychange',
  },
  {
    hidden: 'msHidden',
    event: 'msvisibilitychange',
  },
  {
    hidden: 'oHidden',
    event: 'ovisibilitychange',
  },
];

const {
  hidden: documentHiddenKey,
  event: visibilityChangeEventName,
} = visibilityVendorsData.find((item) => item.hidden in document) || visibilityVendorsData[0];

export class DocumentVisibility {
  static get isVisible(): boolean {
    return !(document as any)[documentHiddenKey];
  }

  static subscribe(callback: () => void) {
    window.addEventListener(visibilityChangeEventName, callback);
  }

  static unsubscribe(callback: () => void) {
    window.removeEventListener(visibilityChangeEventName, callback);
  }
}
