import { createAction } from '@reduxjs/toolkit';
import {
  IGetDisputesRequestFiltersParams,
  IGetDisputesProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getDisputesReport = createAction<IGetDisputesRequestFiltersParams>('REPORT/GET_DISPUTES');
export const getDisputesReportError = createAction('REPORT/GET_DISPUTES/ERROR');

export const getProcessedDisputesReport = createAction<
  IGetDisputesProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_DISPUTES_REPORT/SUCCESS');
export const setProcessedDisputesReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_DISPUTES_REPORTS/SUCCESS');

export const exportDisputesReport = createAction<IGetDisputesRequestFiltersParams>('REPORT/EXPORT_DISPUTES');
export const exportDisputesReportSuccess = createAction('REPORT/EXPORT_DISPUTES/SUCCESS');
export const exportDisputesReportError = createAction('REPORT/EXPORT_DISPUTES/ERROR');
