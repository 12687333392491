import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getRevenueReport,
  getRevenueReportError,
  exportRevenueReport,
  exportRevenueReportError,
  exportRevenueReportSuccess,
  getProcessedRevenueReport,
  setProcessedRevenueReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getRevenueProcessedReportRequest, getRevenueReportRequest } from '../api';
import { IRevenueReportItem, IGetRevenueReportResponseBody, IGetRevenueRequestFiltersParams } from '../types';
import { revenueReportReducerSelector } from './selectors';

function* handleGetRevenueReportRequest(requestParams: IGetRevenueRequestFiltersParams) {
  const { data } = yield call(getRevenueReportRequest, requestParams);

  return data;
}

function* handleGetRevenueProcessedReport({ payload }: ReturnType<typeof getProcessedRevenueReport>) {
  try {
    const { data } = yield call(getRevenueProcessedReportRequest, payload);

    yield put(setProcessedRevenueReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getRevenueReportError());
  }
}

function* handleGetRevenueReport({ payload }: ReturnType<typeof getRevenueReport>) {
  try {
    const revenueReportData: IGetRevenueReportResponseBody = yield handleGetRevenueReportRequest(payload);
    const { info } = revenueReportData;
    const { filterParams } = yield select(revenueReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedRevenueReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getRevenueReportError());
  }
}

function* handleExportRevenueReport({ payload }: ReturnType<typeof exportRevenueReport>): any {
  try {
    const { data }: IGetRevenueReportResponseBody = yield handleGetRevenueReportRequest(payload);

    const preparedData = data.map((item: IRevenueReportItem) => ({
      'Grouping Value': item.groupingValue,
      Players: item.totalUniquePlayers,
      'New Players': item.totalNewPlayers,
      Bets: item.totalBets,
      Wins: item.totalWins,
      'Voucher Bets': item.totalVoucherBets,
      'Voucher Wins': item.totalVoucherWins,
      Refunds: item.totalRefunds,
      'Gross Gaming': item.totalGrossGaming,
      'Net Gaming': item.totalNetGaming,
      ARPU: item.arpu,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Revenue Report.csv');
    yield put(exportRevenueReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportRevenueReportError());
  }
}

export function* revenueReportSagas(): any {
  yield all([
    yield takeLatest(
      getRevenueReport,
      handleGetRevenueReport,
    ),
    yield takeLatest(
      getProcessedRevenueReport,
      handleGetRevenueProcessedReport,
    ),
    yield takeLatest(
      exportRevenueReport,
      handleExportRevenueReport,
    ),
  ]);
}
