import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getVouchersReport,
  getVouchersReportError,
  exportVouchersReportError,
  exportVouchersReportSuccess,
  exportVouchersReport,
  getProcessedVouchersReport,
  setProcessedVouchersReportsSuccess,
} from './actions';
import { IVouchersReportReducer } from '../types';

export const initialState: IVouchersReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const vouchersReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getVouchersReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getVouchersReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportVouchersReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportVouchersReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportVouchersReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedVouchersReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedVouchersReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
