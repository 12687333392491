import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetActivityReportResponseBody,
  IGetActivityRequestFiltersParams,
  IGetActivityProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getActivityProcessedReportRequest = ({ filterParams }: IGetActivityProcessedReportsFilterParams) => {
  const url = getUrl('/report/activity/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getActivityReportRequest = (filterParams: IGetActivityRequestFiltersParams) => {
  const url = getUrl('/report/activity', filterParams);

  return makeRequest<IGetActivityReportResponseBody>(url);
};
