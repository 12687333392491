import { createAction } from '@reduxjs/toolkit';
import {
  IGetMachineBallTableCloseRequestBody,
  IGetMachineBallTableCloseResponseBody,
  IGetMachineBallTableOpenRequestBody,
  IGetMachineBallTableOpenResponseBody,
  IMachineBallsDialogState,
  IPatchMachineBallTableClearRequestBody,
  IPatchMachineBallTableClearResponseBody,
  IPatchMachineBallTableSetActionRequestBody, IPatchMachineBallTableSetActionResponseBody,
} from '../types';

export const setMachineBallsDialog = createAction<Partial<IMachineBallsDialogState> | undefined>('MACHINE/SET_MACHINE_BALLS_DIALOG');
export const mergeMachineBallsDialog = createAction<Partial<IMachineBallsDialogState> | undefined>('MACHINE/MERGE_MACHINE_BALLS_DIALOG');

export const getMachineBallTableOpen = createAction<IGetMachineBallTableOpenRequestBody>('MACHINE/GET_MACHINE_BALL_TABLE_OPEN');
export const getMachineBallTableOpenSuccess = createAction<IGetMachineBallTableOpenResponseBody>('MACHINE/GET_MACHINE_BALL_TABLE_OPEN/SUCCESS');
export const getMachineBallTableOpenError = createAction('MACHINE/GET_MACHINE_BALL_TABLE_OPEN/ERROR');

export const getMachineBallTableClose = createAction<IGetMachineBallTableCloseRequestBody>('MACHINE/GET_MACHINE_BALL_TABLE_CLOSE');
export const getMachineBallTableCloseSuccess = createAction<IGetMachineBallTableCloseResponseBody>('MACHINE/GET_MACHINE_BALL_TABLE_CLOSE/SUCCESS');
export const getMachineBallTableCloseError = createAction('MACHINE/GET_MACHINE_BALL_TABLE_CLOSE/ERROR');

export const patchMachineBallTableClear = createAction<IPatchMachineBallTableClearRequestBody>('MACHINE/PATCH_MACHINE_BALL_TABLE_CLEAR');
export const patchMachineBallTableClearSuccess = createAction<IPatchMachineBallTableClearResponseBody>('MACHINE/PATCH_MACHINE_BALL_TABLE_CLEAR/SUCCESS');
export const patchMachineBallTableClearError = createAction('MACHINE/PATCH_MACHINE_BALL_TABLE_CLEAR/ERROR');

export const patchMachineBallTableSetAction = createAction<IPatchMachineBallTableSetActionRequestBody>('MACHINE/PATCH_MACHINE_BALL_TABLE_SET_ACTION');
export const patchMachineBallTableSetActionSuccess = createAction<IPatchMachineBallTableSetActionResponseBody>('MACHINE/PATCH_MACHINE_BALL_TABLE_SET_ACTION/SUCCESS');
export const patchMachineBallTableSetActionError = createAction('MACHINE/PATCH_MACHINE_BALL_TABLE_SET_ACTION/ERROR');
