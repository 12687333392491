import React, { useCallback } from 'react';
import { ActionType, DialogAction } from '../dialogs/DialogAction/DialogAction';
import { useDialogActionConfirm } from './useDialogActionConfirm';

interface IDialogActionConfirmProps {
}

export const DialogActionConfirm: React.FC<IDialogActionConfirmProps> = () => {
  const {
    isOpen, action, subject, onSubmit, onClose,
  } = useDialogActionConfirm();

  const handleSubmit = useCallback(() => {
    onSubmit();
    onClose();
  }, [onSubmit, onClose]);

  return (
    <DialogAction
      isOpen={isOpen}
      isLoading={false}
      actionType={ActionType.negative}
      action={action}
      subject={subject}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
};
