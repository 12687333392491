import React, { createContext, useCallback, useState } from 'react';

interface IDialogActionConfirmData {
  isOpen: boolean
  action: string
  subject: string
  onSubmit: () => void
}

interface IContextDataDialogConfirmation extends IDialogActionConfirmData {
  onClose: () => void
  setDialogActionConfirm: (data: IDialogActionConfirmData) => void
}

const initialDialogData = {
  isOpen: false,
  action: '',
  subject: '',
  onSubmit: () => {},
};

const contextDataDialogConfirmation: IContextDataDialogConfirmation = {
  ...initialDialogData,
  onClose: () => {},
  setDialogActionConfirm: () => {},
};

export const DialogActionConfirmContext = createContext(contextDataDialogConfirmation);

export const DialogActionConfirmContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [dataDialogActionConfirm, setDialogActionConfirm] = useState(initialDialogData);

  const onClose = useCallback(() => {
    setDialogActionConfirm(initialDialogData);
  }, [setDialogActionConfirm]);

  return (
    <DialogActionConfirmContext.Provider value={{ ...dataDialogActionConfirm, onClose, setDialogActionConfirm }}>
      {children}
    </DialogActionConfirmContext.Provider>
  );
};
