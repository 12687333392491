import { createAction } from '@reduxjs/toolkit';
import {
  IGetRevenueRequestFiltersParams,
  IGetRevenueProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getRevenueReport = createAction<IGetRevenueRequestFiltersParams>('REPORT/GET_REVENUE');
export const getRevenueReportError = createAction('REPORT/GET_REVENUE/ERROR');

export const getProcessedRevenueReport = createAction<
  IGetRevenueProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_REVENUE_REPORT/SUCCESS');
export const setProcessedRevenueReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_REVENUE_REPORTS/SUCCESS');

export const exportRevenueReport = createAction<IGetRevenueRequestFiltersParams>('REPORT/EXPORT_REVENUE');
export const exportRevenueReportSuccess = createAction('REPORT/EXPORT_REVENUE/SUCCESS');
export const exportRevenueReportError = createAction('REPORT/EXPORT_REVENUE/ERROR');
