import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getRevenueReport,
  getRevenueReportError,
  exportRevenueReportError,
  exportRevenueReportSuccess,
  exportRevenueReport,
  getProcessedRevenueReport,
  setProcessedRevenueReportsSuccess,
} from './actions';
import { IRevenueReportReducer } from '../types';

export const initialState: IRevenueReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const revenueReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getRevenueReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getRevenueReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportRevenueReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportRevenueReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportRevenueReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedRevenueReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedRevenueReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
