/* eslint-disable no-console */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Browser } from '../../services/browser';
import { usePrevious } from '../../hooks';
import {
  CSRTCVideoPlayerSessionStatus,
  CSRTCVideoPlayerStatus,
  MediaProvider,
  VideoStreamStatus,
  IIceServersByEnvironment,
  CSRTCVideoFailedEventReason,
  IVideoFailedEvent,
  VideoStreamQuality,
} from './types';
import { Environment } from '../../environment';

const CSRTC_STATS_TRACKING_INTERVAL_MS = 1000;
const CSRTC_STATS_DELAY_FREQUENCY_LIMIT = 8;

const defaultInitOptions = {
  receiverLocation: './js/WSReceiver2.js',
  decoderLocation: './js/video-worker2.js',
  preferredMediaProviders: [MediaProvider.WebRTC],
};

interface IVideoStream {
  setVolume: (volume: number) => void;
  play: () => void;
  getStats: (callback: (stats: any) => any) => void;
  on: (status: CSRTCVideoPlayerStatus, callback: () => void) => void;
}

interface ICSRTCVideoPlayerProps {
  className?: string;
  volume: number;
  src: string;
  rtsp: string;
  preloaderUrl: string;
  initOptions?: object;
  sessionOptions?: object;
  webrtcVideoTunneling?: boolean;
  videoStreamStatus: VideoStreamStatus;
  iceServersByEnvironment: IIceServersByEnvironment;
  quality?: VideoStreamQuality;
  videoMonitoringEventSending?: (data: any) => void;
  onSessionStatusChange: (status: CSRTCVideoPlayerSessionStatus) => void;
  onStreamStatusChange: (status: CSRTCVideoPlayerStatus) => void;
  sendVideoFailedEvent?: (data: IVideoFailedEvent) => void;
}

export const CSRTCVideoPlayer: React.FC<ICSRTCVideoPlayerProps> = React.memo(({
  className,
  src,
  rtsp,
  volume,
  initOptions,
  sessionOptions,
  webrtcVideoTunneling,
  preloaderUrl,
  videoStreamStatus = VideoStreamStatus.loading,
  iceServersByEnvironment,
  quality,
  videoMonitoringEventSending,
  onSessionStatusChange,
  onStreamStatusChange,
  sendVideoFailedEvent = () => {},
}) => {
  const videoContainerRef = useRef<HTMLDivElement | null>(null);
  const sessionIdRef = useRef<string>();
  const streamRef = useRef<IVideoStream>();
  const videoStatsCheckIntervalRef = useRef(0);
  const videoStatsDelayTickRef = useRef(0);
  const startTimeRef = useRef(Date.now());
  const bufferingStartRef = useRef(null as number | null);

  const [shouldTryTunnel, setShouldTryTunnel] = useState(false);

  const previousURL = usePrevious(src);
  const previousStreamID = usePrevious(rtsp);

  const defaultSessionOptions = useMemo(() => ({
    urlServer: src,
    mediaOptions: {
      // TODO: Make NODE_ENV: Environment to avoid "NODE_ENV as Environment",
      iceServers: iceServersByEnvironment[process.env.NODE_ENV as Environment],
      ...(shouldTryTunnel ? { iceTransportPolicy: 'relay' } : {}),
    },
    ...sessionOptions,
  }), [
    src,
    sessionOptions,
    shouldTryTunnel,
  ]);

  console.log('webrtcVideoTunneling', webrtcVideoTunneling);

  const getActiveSession = useCallback(() => {
    const sessions = window?.CSRTCPlayer.getSessions();
    const session = sessions.find((item: any) => item.id() === sessionIdRef.current);

    return session || null;
  }, []);

  useEffect(() => {
    if (videoMonitoringEventSending && webrtcVideoTunneling && !shouldTryTunnel) {
      videoMonitoringEventSending({
        reason: 'protocol switch',
        protocol: 'UDP',
        url: src,
      });
    } else if (videoMonitoringEventSending && webrtcVideoTunneling && shouldTryTunnel) {
      videoMonitoringEventSending({
        reason: 'protocol switch',
        protocol: 'TCP',
        url: src,
      });
    }
  }, [webrtcVideoTunneling, shouldTryTunnel]);

  const handlePlayStream = useCallback((session: any) => {
    try {
      const options = {
        name: rtsp,
        display: videoContainerRef.current,
        transport: webrtcVideoTunneling && shouldTryTunnel ? 'TCP' : 'UDP',
        unmutePlayOnStart: false,
      };

      streamRef.current = session.createStream(options).on(CSRTCVideoPlayerStatus.PLAYING, () => {
        streamRef.current?.setVolume(volume);
        onStreamStatusChange(CSRTCVideoPlayerStatus.PLAYING);
      });

      streamRef.current?.play();
      streamRef.current?.setVolume(volume);

      videoStatsCheckIntervalRef.current = window.setInterval(() => {
        streamRef.current?.getStats((stats: any) => {
          const videoInboundHeight: number = stats.inboundStream.video?.height;

          if (!videoInboundHeight) {
            videoStatsDelayTickRef.current += 1;
          } else {
            videoStatsDelayTickRef.current = 0;
          }

          console.log('videoStatsDelayTick', videoStatsDelayTickRef.current);

          if (videoStatsDelayTickRef.current >= CSRTC_STATS_DELAY_FREQUENCY_LIMIT) {
            if (webrtcVideoTunneling && !shouldTryTunnel) {
              setShouldTryTunnel(true);
              videoStatsDelayTickRef.current = 0;
              console.log('webrtcVideoTunneling DETECTED, shold try to reinit with tunneling');

              return;
            }

            if (!webrtcVideoTunneling) {
              sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.disabledTunneling });
              console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.disabledTunneling}; videoInbounds: ${videoInboundHeight}`);
            }

            if (webrtcVideoTunneling && shouldTryTunnel) {
              sendVideoFailedEvent({ reason: CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling });
              console.log('VIDEO FAILED EVENT', `reason: ${CSRTCVideoFailedEventReason.videoNotAvailableWithTunneling}; videoInbounds: ${videoInboundHeight}`);
            }

            clearInterval(videoStatsCheckIntervalRef.current);
          }
        });
      }, CSRTC_STATS_TRACKING_INTERVAL_MS);

      Object.values(CSRTCVideoPlayerSessionStatus).forEach((status) => {
        session.on(status, () => {
          if (status === CSRTCVideoPlayerSessionStatus.FAILED) {
            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'video termination',
                terminationTimestamp: Date.now(),
              });
            }
          }
        });
      });

      Object.values(CSRTCVideoPlayerStatus).forEach((status) => {
        streamRef.current?.on(status, () => {
          onStreamStatusChange(status);

          if (status === CSRTCVideoPlayerStatus.PENDING) {
            const firstLoadTime = Date.now() - startTimeRef.current;

            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'time to initial connection',
                timeToInitialConnection: `${firstLoadTime}ms`,
              });
            }
          }

          if (status === CSRTCVideoPlayerStatus.PLAYING) {
            const firstLoadTime = Date.now() - startTimeRef.current;

            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 'time to start seeing video',
                timeToStartSeeingVideo: `${firstLoadTime}ms`,
              });
            }

            if (bufferingStartRef.current !== null) {
              const bufferingEnd = Date.now();
              const bufferingDuration = bufferingEnd - bufferingStartRef.current;

              bufferingStartRef.current = null;

              if (videoMonitoringEventSending) {
                videoMonitoringEventSending({
                  reason: 'video freeze',
                  freezePeriods: `${bufferingDuration}ms`,
                  freezeTimestamp: Date.now(),
                });
              }
            }
          }

          if (status === CSRTCVideoPlayerStatus.PAUSED
            || status === CSRTCVideoPlayerStatus.STOPPED
            || status === CSRTCVideoPlayerStatus.PLAYBACK_PROBLEM) {
            bufferingStartRef.current = Date.now();
          }

          if (status === CSRTCVideoPlayerStatus.FAILED) {
            if (videoMonitoringEventSending) {
              videoMonitoringEventSending({
                reason: 're-connect',
                reconnectTimestamp: Date.now(),
              });
            }
          }

          if (videoMonitoringEventSending && quality === 'low') {
            videoMonitoringEventSending({
              reason: 'downgrade',
              downgrades: `${quality} quality`,
            });
          }
        });
      });
    } catch (error) {
      console.error(error);
    }
  }, [rtsp, onStreamStatusChange, shouldTryTunnel, quality, webrtcVideoTunneling]);

  const handleCreateSession = useCallback(() => {
    try {
      const session = window?.CSRTCPlayer.createSession(defaultSessionOptions);

      Object.values(CSRTCVideoPlayerSessionStatus).forEach((status) => {
        session.on(status, () => {
          onSessionStatusChange(status);
        });
      });

      session.on(CSRTCVideoPlayerSessionStatus.ESTABLISHED, (establishedSession: any) => {
        const newSessionId = establishedSession.id();

        sessionIdRef.current = newSessionId;
        handlePlayStream(establishedSession);
        onSessionStatusChange(CSRTCVideoPlayerSessionStatus.ESTABLISHED);
      });
    } catch (error) {
      console.error(error);
    }
  }, [src, handlePlayStream, onSessionStatusChange]);

  const handleCleanupDOM = useCallback(() => {
    if (videoContainerRef.current) {
      videoContainerRef.current.innerHTML = '';
    }
  }, []);

  const handleDestroy = useCallback(() => {
    const session = getActiveSession();

    handleCleanupDOM();
    session?.disconnect();
    clearInterval(videoStatsCheckIntervalRef.current);
  }, [getActiveSession, handleCleanupDOM]);

  useEffect(() => {
    try {
      window.CSRTCPlayer.init(initOptions || defaultInitOptions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const shouldReInit = previousURL !== src || previousStreamID !== rtsp || shouldTryTunnel;
    const isPlayerPreCheckSuccess = Browser.isSafariWebRTC() || window?.CSRTCPlayer.getMediaProviders()[0] === MediaProvider.MSE;

    if (!shouldReInit) {
      return;
    }

    handleDestroy();

    if (src && rtsp) {
      if (isPlayerPreCheckSuccess) {
        window?.CSRTCPlayer.playFirstVideo(videoContainerRef.current, false, preloaderUrl).then(() => {
          handleCreateSession();
        }).catch((error: any) => {
          console.error(error);
        });
      } else {
        handleCreateSession();
      }
    }
  }, [src, rtsp, handleCreateSession, handleDestroy, previousURL, previousStreamID, shouldTryTunnel]);

  useEffect(() => handleDestroy, [handleDestroy]);

  useEffect(() => {
    if (videoStreamStatus === VideoStreamStatus.playing) {
      streamRef.current?.setVolume(volume);
    }
  }, [volume, videoStreamStatus]);

  return (
    <div ref={videoContainerRef} className={className} />
  );
});
