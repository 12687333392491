import { createAction } from '@reduxjs/toolkit';
import {
  IGetPlayerStatsProcessedReportsFilterParams,
  IGetPlayerStatsRequestFiltersParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getPlayerStatsReport = createAction<IGetPlayerStatsRequestFiltersParams>('REPORT/GET_PLAYER_STATS');
export const getPlayerStatsReportError = createAction('REPORT/GET_PLAYER_STATS/ERROR');

export const getProcessedPlayerStatsReport = createAction<
  IGetPlayerStatsProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_PLAYER_STATS_REPORT/SUCCESS');
export const setProcessedPlayerStatsReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_PLAYER_STATS_REPORTS/SUCCESS');

export const exportPlayerStatsReport = createAction<IGetPlayerStatsRequestFiltersParams>('REPORT/EXPORT_PLAYER_STATS');
export const exportPlayerStatsReportSuccess = createAction('REPORT/EXPORT_PLAYER_STATS/SUCCESS');
export const exportPlayerStatsReportError = createAction('REPORT/EXPORT_PLAYER_STATS/ERROR');
