import { IStorage } from './types';

export class CookieStorage implements IStorage {
  public getItem(key: string): string | null {
    const cookie = document.cookie;
    const cookieName = `${key}=`;
    const cookieStart = cookie.indexOf(cookieName);

    if (cookieStart > -1) {
      const cookieEnd = cookie.indexOf(';', cookieStart);
      if (cookieEnd === -1) {
        const val =  cookie.substring(cookieStart + cookieName.length);
        return JSON.parse( val );
      } else {
        const val =  cookie.substring(cookieStart + cookieName.length, cookieEnd);
        return JSON.parse( val );
      }
    }

    return '';
  }

  public setItem(key: string, value: string): void {
    document.cookie = `${key}=${JSON.stringify(value)}`;
  }

  public remove(key: string): void {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }

  public clear(): void {
    const cookies = document.cookie.split(';');
    cookies.forEach(cookie => {
      const pos = cookie.indexOf('=');
      const name = pos > -1 ? cookie.substring(0, pos) : cookie;
      this.remove(name);
    });
  }
}
