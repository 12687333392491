import queryString from 'query-string';
import { IGetVouchersRequestFiltersParams } from 'arcadia-common-fe';
import { IGetTransactionsRequestFilterParams } from '../modules/transactions/types';
import { IGetMachinesRequestFiltersParams } from '../modules/machines/types';
import { IGetGroupsRequestFiltersParams } from '../modules/groups/types';
import { IGetPlayersRequestFilterParams } from '../modules/players/types';
import { IGetOperatorsRequestFilterParams } from '../modules/operators/types';
import { IGetAdministrationRequestFiltersParams } from '../modules/administration/types';
import { IGetAlertsRequestFiltersParams } from '../modules/alerts/types';
import { UserPermissionName } from '../modules/auth/types';
import { IGetDisputesRequestFiltersParams } from '../modules/disputes/types';
import { IGetMonitoringRequestFilterParams } from '../modules/monitoring/types';
import { ReportType } from '../modules/reports/types';
import { IGetSessionsRequestFiltersParams } from '../modules/sessions/types';
import { SessionMode } from '../types/sessions';
import { IGetSitesRequestFilterParams } from '../modules/sites/types';
import { IGetCamerasRequestFiltersParams } from '../modules/cameras/types';
import { IGetCmsRequestFilterParams } from '../modules/cms/types';
import { IGetCurrenciesRequestFilterParams } from '../modules/currency/types';
import { IGetCredentialsRequestFilterParams } from '../modules/credentials/types';
import { IGetBrandsRequestFilterParams } from '../modules/brands/types';

export interface IRoutesMap {
  path: string
  exact?: boolean
  isWithoutHeader?: boolean
  title: string
  permissionName?: UserPermissionName
}

export const ROUTES_MAP = {
  noMatch: {
    path: '/no-match', exact: true, title: '404 - No Match', isWithoutHeader: true,
  },
  signIn: {
    path: '/auth', exact: true, title: 'Sign In', isWithoutHeader: true,
  },
  dashboard: {
    path: '/dashboard', exact: true, title: 'Dashboards', permissionName: UserPermissionName.dashboard,
  },
  sessions: {
    path: '/sessions',
    exact: true,
    title: 'Sessions',
    createURL: (queryParams: Omit<IGetSessionsRequestFiltersParams, 'mode'> & { mode?: SessionMode }) => (
      `${ROUTES_MAP.sessions.path}?${queryString.stringify({
        mode: SessionMode.active,
        ...queryParams,
      })}`
    ),
    permissionName: UserPermissionName.sessions,
  },
  players: {
    path: '/players',
    exact: true,
    title: 'Players',
    createURL: (queryParams: IGetPlayersRequestFilterParams) => (
      `${ROUTES_MAP.players.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.players,
  },
  machines: {
    path: '/machines',
    exact: true,
    title: 'Machines',
    createURL: (queryParams: IGetMachinesRequestFiltersParams) => (
      `${ROUTES_MAP.machines.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.machines,
  },
  groups: {
    path: '/groups',
    exact: true,
    title: 'Groups',
    createURL: (queryParams: IGetGroupsRequestFiltersParams) => (
      `${ROUTES_MAP.groups.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.groups,
  },
  cameras: {
    path: '/cameras',
    exact: true,
    title: 'Cameras',
    createURL: (queryParams: IGetCamerasRequestFiltersParams) => (
      `${ROUTES_MAP.cameras.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.cameras,
  },
  vouchers: {
    path: '/vouchers',
    exact: true,
    title: 'Vouchers',
    createURL: (queryParams: IGetVouchersRequestFiltersParams) => (
      `${ROUTES_MAP.vouchers.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.vouchers,
  },
  maintenance: {
    path: '/maintenance', exact: true, title: 'Maintenance', permissionName: UserPermissionName.maintenance,
  },
  operators: {
    path: '/operators',
    exact: true,
    title: 'Operators',
    createURL: (queryParams: IGetOperatorsRequestFilterParams) => (
      `${ROUTES_MAP.operators.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.operators,
  },
  reports: {
    path: '/reports',
    exact: false,
    title: 'Reports',
    permissionName: UserPermissionName.reports,
    createReportURL: <TValues>(reportType: ReportType, queryParams?: TValues) => (
      `${ROUTES_MAP.reports.path}/${reportType}${queryParams ? `?${queryString.stringify(queryParams)}` : ''}`
    ),
  },
  alerts: {
    path: '/alerts',
    exact: true,
    title: 'Alerts',
    createURL: (queryParams: IGetAlertsRequestFiltersParams) => (
      `${ROUTES_MAP.alerts.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.alerts,
  },
  disputes: {
    path: '/disputes',
    exact: true,
    title: 'Disputes',
    createURL: (queryParams: IGetDisputesRequestFiltersParams) => (
      `${ROUTES_MAP.disputes.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.disputes,
  },
  monitoring: {
    path: '/monitoring',
    exact: true,
    title: 'Monitoring',
    createURL: (queryParams: IGetMonitoringRequestFilterParams) => (
      `${ROUTES_MAP.monitoring.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.monitoring,
  },
  administration: {
    path: '/administration',
    exact: true,
    title: 'Administration',
    createURL: (queryParams: IGetAdministrationRequestFiltersParams) => (
      `${ROUTES_MAP.administration.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.administration,
  },
  transactions: {
    path: '/rebates',
    exact: true,
    title: 'Rebates',
    createURL: (queryParams: IGetTransactionsRequestFilterParams) => (
      `${ROUTES_MAP.transactions.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.transactions,
  },
  sites: {
    path: '/sites',
    exact: true,
    title: 'Sites',
    createURL: (queryParams: IGetSitesRequestFilterParams) => (
      `${ROUTES_MAP.sites.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.sites,
  },
  cms: {
    path: '/cms',
    exact: true,
    title: 'CMS',
    createURL: (queryParams: IGetCmsRequestFilterParams) => (
      `${ROUTES_MAP.cms.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.cms,
  },
  currency: {
    path: '/currencyConversion',
    exact: true,
    title: 'Currency',
    createURL: (queryParams: IGetCurrenciesRequestFilterParams) => (
      `${ROUTES_MAP.currency.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.currencyManager,
  },
  credentials: {
    path: '/brandSecrets',
    exact: true,
    title: 'Credentials',
    createURL: (queryParams: IGetCredentialsRequestFilterParams) => (
      `${ROUTES_MAP.credentials.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.brandSecrets,
  },
  brands: {
    path: '/brands',
    exact: true,
    title: 'Brands',
    createURL: (queryParams: IGetBrandsRequestFilterParams) => (
      `${ROUTES_MAP.brands.path}?${queryString.stringify(queryParams)}`
    ),
    permissionName: UserPermissionName.brands,
  },
};

export const DEFAULT_ROUTE = ROUTES_MAP.dashboard;
export const NO_MATCH_ROUTE = ROUTES_MAP.noMatch;
