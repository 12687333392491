import { createReducer } from '@reduxjs/toolkit';
import {
  getSites,
  getSitesError,
  getSitesSuccess,
  setSiteDialogForm,
  mergeSiteDialogForm,
  setSiteDialogAction,
  mergeSiteDialogAction,
} from './actions';
import { ISitesReducer, SiteAction } from '../types';

export const initialState: ISitesReducer = {
  isLoading: false,
  total: 0,
  sites: [],
  dialogAction: {
    id: null,
    action: SiteAction.remove,
    isOpen: false,
    isLoading: false,
  },
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
};

export const sitesReducer = createReducer(initialState, (builder) => {
  builder.addCase(getSites, (state) => ({
    ...state,
    total: 0,
    sites: [],
    isLoading: true,
  }));
  builder.addCase(getSitesSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getSitesError, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(setSiteDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeSiteDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));

  builder.addCase(setSiteDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...initialState.dialogAction,
      ...payload,
    },
  }));
  builder.addCase(mergeSiteDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...state.dialogAction,
      ...payload,
    },
  }));
});
