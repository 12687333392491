import React from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { useStyles } from '../../hooks/useStyles';
import { getStyles, ITextGradientStyleProps, ITextGradientStyles } from './styles/TextGradient';

export interface ITextGradientProps extends Partial<ITextGradientStyleProps> {
  styles?: IStyleFunctionOrObject<ITextGradientStyleProps, ITextGradientStyles>;
  color: string;
  gradient: string;
  children: React.ReactNode;
  dataAttr?: string;
}

const TextGradientBase: React.FC<ITextGradientProps> = ({
  styles, className, color, gradient, children, dataAttr,
}) => {
  const classNames = useStyles<ITextGradientStyles, ITextGradientStyleProps>(styles, {
    className,
    color,
    gradient,
  });

  return (
    <span className={classNames.root} data-e2e-selector={dataAttr}>
      { children }
    </span>
  );
};

export const TextGradient = React.memo(styled<ITextGradientProps, ITextGradientStyleProps, ITextGradientStyles>(
  TextGradientBase,
  getStyles,
));
