export type Entries<T extends object> = { [K in keyof T]-?: [K, T[K]] }[keyof T];

export const getEntries = <T extends object>(obj: T): Entries<T>[] => Object.entries(obj) as Entries<T>[];

export const getKeys = <T extends object>(obj: T): (keyof T)[] => Object.keys(obj) as (keyof T)[];

export const getValues = <T extends object>(obj: T): (T[keyof T])[] => Object.values(obj) as (T[keyof T])[];

export const swapKeyToValue = <T extends string, K extends string | number>(
  obj: Record<T, K>,
): Record<K, T> => getEntries(obj)
  .reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {} as Record<K, T>);
