import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getVouchersReport,
  getVouchersReportError,
  exportVouchersReport,
  exportVouchersReportError,
  exportVouchersReportSuccess,
  getProcessedVouchersReport,
  setProcessedVouchersReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getVouchersProcessedReportRequest, getVouchersReportRequest } from '../api';
import { IVouchersReportItem, IGetVouchersReportResponseBody, IGetVouchersRequestFiltersParams } from '../types';
import { vouchersReportReducerSelector } from './selectors';

function* handleGetVouchersReportRequest(requestParams: IGetVouchersRequestFiltersParams) {
  const { data } = yield call(getVouchersReportRequest, requestParams);

  return data;
}

function* handleGetVouchersProcessedReport({ payload }: ReturnType<typeof getProcessedVouchersReport>) {
  try {
    const { data } = yield call(getVouchersProcessedReportRequest, payload);

    yield put(setProcessedVouchersReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getVouchersReportError());
  }
}

function* handleGetVouchersReport({ payload }: ReturnType<typeof getVouchersReport>) {
  try {
    const vouchersReportData: IGetVouchersReportResponseBody = yield handleGetVouchersReportRequest(payload);
    const { info } = vouchersReportData;
    const { filterParams } = yield select(vouchersReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedVouchersReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getVouchersReportError());
  }
}

function* handleExportVouchersReport({ payload }: ReturnType<typeof exportVouchersReport>): any {
  try {
    const { data }: IGetVouchersReportResponseBody = yield handleGetVouchersReportRequest(payload);

    const preparedData = data.map((item: IVouchersReportItem) => ({
      'Grouping Value': item.groupingValue,
      'Vouchers Issued': item.totalVouchersIssued,
      'Vouchers Used': item.totalVouchersUsed,
      'Vouchers Bets': item.totalVouchersBets,
      'Vouchers Wins': item.totalVouchersWins,
      'Vouchers Expired': item.totalVouchersExpired,
      'Vouchers Cancelled': item.totalVouchersCanceled,
      'Rounds Played': item.totalRoundsPlayed,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Vouchers Report.csv');
    yield put(exportVouchersReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportVouchersReportError());
  }
}

export function* vouchersReportSagas(): any {
  yield all([
    yield takeLatest(
      getVouchersReport,
      handleGetVouchersReport,
    ),
    yield takeLatest(
      getProcessedVouchersReport,
      handleGetVouchersProcessedReport,
    ),
    yield takeLatest(
      exportVouchersReport,
      handleExportVouchersReport,
    ),
  ]);
}
