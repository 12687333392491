import { convertDataToJSON } from 'arcadia-common-fe';
import {
  all, call, put, takeEvery, takeLatest, delay,
} from 'redux-saga/effects';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  IGetCredentialsRequestFilterParams,
  IGetCredentialsResponseBody,
  CredentialAction,
  IPostSecretNameRequestBody,
} from '../types';
import {
  postCredentialRequest,
  getCredentialsRequest,
  putCredentialRequest,
  removeCredentialRequest,
  postSecretNameRequest,
  getBrandSecretsNamesRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  postCredential,
  getCredentials,
  getCredentialsError,
  getCredentialsSuccess,
  putCredential,
  mergeCredentialDialogForm,
  setCredentialDialogForm,
  setCredentialDialogAction,
  mergeCredentialDialogAction,
  executeCredentialAction,
  setCredentials,
  clearCredentialsAction,
  getBrandSecretsNamesSuccess,
  getBrandSecretsNames,
} from './actions';
import { setDialogJSONViewer } from '../../DialogJSONViewer/actions';

function* handleGetCredentialsRequest(requestParams: IGetCredentialsRequestFilterParams) {
  const { data } = yield call(getCredentialsRequest, requestParams);

  return data;
}

function* handlePostSecretName(requestParams: IPostSecretNameRequestBody) {
  const { data } = yield call(postSecretNameRequest, requestParams);

  return data;
}

function* handleRefreshCredentials() {
  yield put(getCredentials(queryString.parse(window.location.search)));
}

function* handlePostCredential({ payload }: ReturnType<typeof postCredential>) {
  try {
    yield put(mergeCredentialDialogForm({
      isLoading: true,
    }));

    yield call(postCredentialRequest, payload);
    yield put(setCredentialDialogForm());
    yield call(toast.success, 'The credential has been successfully created');
    yield handleRefreshCredentials();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCredentialDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetCredentials({ payload }: ReturnType<typeof getCredentials>) {
  try {
    const data: IGetCredentialsResponseBody = yield handleGetCredentialsRequest(payload);

    yield put(getCredentialsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getCredentialsError());
  }
}

export function* handleGetBrandSecretsNames() {
  try {
    const { data } = yield getBrandSecretsNamesRequest();

    yield put(getBrandSecretsNamesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getCredentialsError());
  }
}

function* handlePutCredential({ payload }: ReturnType<typeof putCredential>) {
  try {
    yield put(mergeCredentialDialogForm({
      isLoading: true,
    }));

    yield call(putCredentialRequest, payload);
    yield put(setCredentialDialogForm());
    yield call(toast.success, `The credential ${payload.id} has been successfully updated`);
    yield handleRefreshCredentials();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCredentialDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleExecuteCredentialAction({ payload }: ReturnType<typeof executeCredentialAction>) {
  try {
    const {
      action, id, confirmationPassword, secretName, isCredentialEdit, credential,
    } = payload;

    yield put(mergeCredentialDialogAction({ isLoading: true }));

    if (action === CredentialAction.remove) {
      yield call(removeCredentialRequest, { id, confirmationPassword });
      yield call(toast.success, `The credential with id ${id} has been successfully removed.`);
      yield put(setCredentialDialogAction({ isOpen: false }));
      yield handleRefreshCredentials();
    }

    if (action === CredentialAction.getCredentials) {
      const data: object = yield handlePostSecretName({ secretName, confirmationPassword });

      yield put(setCredentials({ [secretName]: data }));

      if (isCredentialEdit) {
        yield put(setCredentialDialogForm({
          isOpen: true,
          initialValues: credential,
        }));
      } else {
        yield put(setDialogJSONViewer({
          isOpen: true,
          JSON: convertDataToJSON(data),
        }));
      }
      yield put(setCredentialDialogAction());
      yield delay(300000);
      yield put(setCredentialDialogForm({
        isOpen: false,
      }));
      yield put(setDialogJSONViewer({
        isOpen: false,
      }));
      yield put(clearCredentialsAction({
        secretName,
      }));
    }
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCredentialDialogAction({ isLoading: false }));
  }
}

export function* credentialsSagas(): any {
  yield all([
    yield takeEvery(
      postCredential,
      handlePostCredential,
    ),
    yield takeLatest(
      getCredentials,
      handleGetCredentials,
    ),
    yield takeEvery(
      putCredential,
      handlePutCredential,
    ),
    yield takeLatest(
      executeCredentialAction,
      handleExecuteCredentialAction,
    ),
    yield takeLatest(
      getBrandSecretsNames,
      handleGetBrandSecretsNames,
    ),
  ]);
}
