import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getPlayerStatsReport,
  getPlayerStatsReportError,
  exportPlayerStatsReport,
  exportPlayerStatsReportError,
  exportPlayerStatsReportSuccess,
  getProcessedPlayerStatsReport,
  setProcessedPlayerStatsReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getPlayerStatsReportRequest, getPlayerStatsProcessedReportRequest } from '../api';
import { IPlayerStatsReportItem, IGetPlayerStatsReportResponseBody, IGetPlayerStatsRequestFiltersParams } from '../types';
import { playerStatsReportReducerSelector } from './selectors';

function* handleGetPlayerStatsReportRequest(requestParams: IGetPlayerStatsRequestFiltersParams) {
  const { data } = yield call(getPlayerStatsReportRequest, requestParams);

  return data;
}

function* handleGetPlayerStatsProcessedReport({ payload }: ReturnType<typeof getProcessedPlayerStatsReport>) {
  try {
    const { data } = yield call(getPlayerStatsProcessedReportRequest, payload);

    yield put(setProcessedPlayerStatsReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPlayerStatsReportError());
  }
}

function* handleGetPlayerStatsReport({ payload }: ReturnType<typeof getPlayerStatsReport>) {
  try {
    const playerStatsReportData: IGetPlayerStatsReportResponseBody = yield handleGetPlayerStatsReportRequest(payload);
    const { info } = playerStatsReportData;
    const { filterParams } = yield select(playerStatsReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedPlayerStatsReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPlayerStatsReportError());
  }
}

function* handleExportPlayerStatsReport({ payload }: ReturnType<typeof exportPlayerStatsReport>): any {
  try {
    const { data }: IGetPlayerStatsReportResponseBody = yield handleGetPlayerStatsReportRequest(payload);

    const preparedData = data.map((item: IPlayerStatsReportItem) => ({
      'Player ID': item.groupingValue,
      Sessions: item.totalUniqueSessions,
      'Total Session Time': item.totalSessionTime,
      'Avg Session Time': item.avgSessionTime,
      Rounds: item.totalRoundsPlayed,
      'Avg Rounds Per Session': item.avgRoundsPerSession,
      Bets: item.totalBets,
      Wins: item.totalWins,
      'Bets Behind': item.totalBehindBets,
      'Wins Behind': item.totalBehindWins,
      'Voucher Bets': item.totalVoucherBets,
      'Voucher Wins': item.totalVoucherWins,
      Refunds: item.totalRefunds,
      'Gross Gaming': item.totalGrossGaming,
      'Net Gaming': item.totalNetGaming,
      'Watch Time': item.totalWatchTime,
      'Avg Watch Time': item.avgWatchTime,
      'Max Watch Time': item.maxWatchTime,
      'Queue Time': item.totalQueueTime,
      'Avg Queue Time': item.avgQueueTime,
      'Max Queue Time': item.maxQueueTime,
      'In Play Time': item.totalInPlayTime,
      'Avg In Play Time': item.avgInPlayTime,
      'Max In Play Time': item.maxInPlayTime,
      'Autoplay Bets': item.totalAutoplayBets,
      'Autoplay Wins': item.totalAutoplayWins,
      'Autoplay Sessions %': item.percentAutoplaySessions,
      'Sessions Watch': item.totalSessionsWatch,
      'Sessions Watch %': item.percentSessionsWatch,
      'Sessions Queue': item.totalSessionsQueue,
      'Sessions Queue %': item.percentSessionsQueue,
      'Sessions Behind': item.totalSessionsBehind,
      'Sessions Behind %': item.percentSessionsBehind,
      'Sessions In Play': item.totalSessionsInPlay,
      'Sessions In Play %': item.percentSessionsInPlay,
      LTV: item.ltv,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Player Stats Report.csv');
    yield put(exportPlayerStatsReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportPlayerStatsReportError());
  }
}

export function* playerStatsReportSagas(): any {
  yield all([
    yield takeLatest(
      getPlayerStatsReport,
      handleGetPlayerStatsReport,
    ),
    yield takeLatest(
      getProcessedPlayerStatsReport,
      handleGetPlayerStatsProcessedReport,
    ),
    yield takeLatest(
      exportPlayerStatsReport,
      handleExportPlayerStatsReport,
    ),
  ]);
}
