import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getMachineBallTableCloseRequest, getMachineBallTableOpenRequest,
  patchMachineBallTableClearRequest,
  patchMachineBallTableSetActionRequest,
} from '../api';
import {
  getMachineBallTableClose,
  getMachineBallTableCloseError,
  getMachineBallTableCloseSuccess,
  getMachineBallTableOpen,
  getMachineBallTableOpenError,
  getMachineBallTableOpenSuccess,
  patchMachineBallTableClear,
  patchMachineBallTableClearError,
  patchMachineBallTableClearSuccess,
  patchMachineBallTableSetAction,
  patchMachineBallTableSetActionError,
  patchMachineBallTableSetActionSuccess,
} from './actions';
import { LocalStorageKeys } from '../../../constants';

function* handleGetMachineBallTableOpen({ payload }: ReturnType<typeof getMachineBallTableOpen>) {
  try {
    const { data } = yield call(getMachineBallTableOpenRequest, payload);

    yield call([localStorage, 'setItem'], LocalStorageKeys.sessionTokenData, JSON.stringify(payload));
    yield put(getMachineBallTableOpenSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachineBallTableOpenError());
  }
}

function* handleGetMachineBallTableClose({ payload }: ReturnType<typeof getMachineBallTableClose>) {
  try {
    const { data } = yield call(getMachineBallTableCloseRequest, payload);

    yield call([localStorage, 'removeItem'], LocalStorageKeys.sessionTokenData);
    yield put(getMachineBallTableCloseSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachineBallTableCloseError());
  }
}

function* handlePatchMachineBallTableClear({ payload }: ReturnType<typeof patchMachineBallTableClear>) {
  try {
    const { data } = yield call(patchMachineBallTableClearRequest, payload);

    yield call(toast.success, 'The list of balls has been cleared successfully!');
    yield put(patchMachineBallTableClearSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(patchMachineBallTableClearError());
  }
}

function* handlePatchMachineBallTableSetAction({ payload }: ReturnType<typeof patchMachineBallTableSetAction>) {
  try {
    const { data } = yield call(patchMachineBallTableSetActionRequest, payload);

    yield call(toast.success, `Balls ${payload.action === 'delete' ? 'removed' : 'added'} successfully!`);
    yield put(patchMachineBallTableSetActionSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(patchMachineBallTableSetActionError());
  }
}

export function* machineBallTableSagas(): any {
  yield all([
    yield takeLatest(
      getMachineBallTableOpen,
      handleGetMachineBallTableOpen,
    ),
    yield takeLatest(
      getMachineBallTableClose,
      handleGetMachineBallTableClose,
    ),
    yield takeLatest(
      patchMachineBallTableClear,
      handlePatchMachineBallTableClear,
    ),
    yield takeLatest(
      patchMachineBallTableSetAction,
      handlePatchMachineBallTableSetAction,
    ),
  ]);
}
