import React, { useState, useEffect } from 'react';
import { styled, IStyleFunctionOrObject } from '@fluentui/utilities';
import moment from 'moment';
import { useStyles } from '../../hooks/useStyles';
import { ONE_SECOND_IN_MILISECONDS } from '../../constants';
import { getStyles, IClockStyleProps, IClockStyles } from './styles/Clock';

export interface IClockProps extends Partial<IClockStyleProps> {
  styles?: IStyleFunctionOrObject<IClockStyleProps, IClockStyles>;
  IconClock?: SvgComponent;
  dataAttr?: string;
}

const ClockBase: React.FC<IClockProps> = (({ styles, IconClock, className, dataAttr }) => {
  const classNames = useStyles<IClockStyles, IClockStyleProps>(styles, {
    className,
  });

  const [date, setDate] = useState(Date.now());

  useEffect(() => {
    let interval: number;
    const milisecondsToRunInterval = (60 - moment(date).seconds()) * 1000;

    const timeout = setTimeout(() => {
      setDate(Date.now());

      interval = window.setInterval(() => {
        setDate(Date.now());
      }, ONE_SECOND_IN_MILISECONDS * 60);
    }, milisecondsToRunInterval);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={classNames.root} data-e2e-selector={dataAttr}>
      { IconClock && <IconClock className={classNames.icon} /> }
      { moment(date).format('LT') }
    </div>
  );
});

export const Clock = React.memo(
  styled<
    IClockProps,
    IClockStyleProps,
    IClockStyles
  >(
    ClockBase,
    getStyles,
  ),
);
