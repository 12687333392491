import { createAction } from '@reduxjs/toolkit';
import {
  IGetVouchersRequestFiltersParams,
  IGetVouchersProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getVouchersReport = createAction<IGetVouchersRequestFiltersParams>('REPORT/GET_VOUCHERS');
export const getVouchersReportError = createAction('REPORT/GET_VOUCHERS/ERROR');

export const getProcessedVouchersReport = createAction<
  IGetVouchersProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_VOUCHERS_REPORT/SUCCESS');
export const setProcessedVouchersReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_VOUCHERS_REPORTS/SUCCESS');

export const exportVouchersReport = createAction<IGetVouchersRequestFiltersParams>('REPORT/EXPORT_VOUCHERS');
export const exportVouchersReportSuccess = createAction('REPORT/EXPORT_VOUCHERS/SUCCESS');
export const exportVouchersReportError = createAction('REPORT/EXPORT_VOUCHERS/ERROR');
