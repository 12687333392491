import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetVouchersReportResponseBody,
  IGetVouchersRequestFiltersParams,
  IGetVouchersProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getVouchersProcessedReportRequest = ({ filterParams }: IGetVouchersProcessedReportsFilterParams) => {
  const url = getUrl('/report/vouchers/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getVouchersReportRequest = (filterParams: IGetVouchersRequestFiltersParams) => {
  const url = getUrl('/report/vouchers', filterParams);

  return makeRequest<IGetVouchersReportResponseBody>(url);
};
