import { ICommonRequestFiltersParams } from 'arcadia-common-fe';
import { ICms, ICmses, CmsId } from '../../types/cms';
import { OperatorId } from '../../types/operators';
import { BrandId } from '../../types/brand';

export enum CmsAction {
  remove,
}

export enum CmsAssetGroup {
  audio = 'Audio',
  image = 'Image',
  text = 'Text',
  video = 'Video',
}

export interface IAssetType {
  group: string;
  gameIds: string[];
}

export type IAssetTypes = Record<string, IAssetType>;

export enum CmsAssetType {
  videoTutorial = 'Video tutorial',
  tutorialSlide1 = 'Tutorial slide 1',
  tutorialSlide2 = 'Tutorial slide 2',
  tutorialSlide3 = 'Tutorial slide 3',
  tutorialSlide4 = 'Tutorial slide 4',
  posterTutorial = 'Poster Tutorial',
  chipCounter = 'Chip counter',
  groupLogo = 'Group logo',
  operatorLogo = 'Operator logo',
  gameRules = 'Game rules',
  pressedAutoplayButton = 'Autoplay button (pressed)',
  unpressedAutoplayButton = 'Autoplay button (default)',
  pressedGetCoinsButton = 'Get Coins button (pressed)',
  unpressedGetCoinsButton = 'Get Coins button (default)',
  pressedFireButton = 'Fire button (pressed)',
  unpressedFireButton = 'Fire button (default)',
  freeRoundLabel = 'Free Round label',
  activeTotalWinPanel = 'Total Win panel (active)',
  inactiveTotalWinPanel = 'Total Win panel (default)',
  pressedStopButton = 'Stop button (pressed)',
  unpressedStopButton = 'Stop button (default)',
  pressedAutoswingButton = 'Autoswing button (pressed)',
  unpressedAutoswingButton = 'Autoswing button (default)',
  pressedAutoFireButton = 'Auto fire button (pressed)',
  unpressedAutoFireButton = 'Auto fire button (default)',
  voucherButton = 'Voucher button',
  autoLabel = 'Stop button (Auto label)',
  chipBlueBig = 'Chip - Blue (big)',
  chipBlueSmall = 'Chip - Blue (small)',
  chipRedBig = 'Chip - Red (big)',
  chipRedSmall = 'Chip - Red (small)',
  chipGreenBig = 'Chip - Green (big)',
  chipGreenSmall = 'Chip - Green (small)',
  chipBlackBig = 'Chip - Black (big)',
  chipBlackSmall = 'Chip - Black (small)',
  chipPurpleBig = 'Chip - Purple (big)',
  chipPurpleSmall = 'Chip - Purple (small)',
  chipWhiteBig = 'Chip - White (big)',
  chipWhiteSmall = 'Chip - White (small)',
  chipYellowBig = 'Chip - Yellow (big)',
  chipYellowSmall = 'Chip - Yellow (small)',
  chipGreyBig = 'Chip - Grey (big)',
  chipGreySmall = 'Chip - Grey (small)',
  chipPinkBig = 'Chip - Pink (big)',
  chipPinkSmall = 'Chip - Pink (small)',
  chipOrangeBig = 'Chip - Orange (big)',
  chipOrangeSmall = 'Chip - Orange (small)',
  robotBackscreenVideo = 'robot-backscreen-video',
  clientSoundsYellowChipAdded = 'Client sounds - yellow chip added',
  clientSoundsPinkChipAdded = 'Client sounds - pink chip added',
  clientSoundsRedChipAdded = 'Client sounds - red chip added',
  clientSoundsGreenChipAdded = 'Client sounds - green chip added',
  clientSoundsBlueChipAdded = 'Client sounds - blue chip added',
  clientSoundsPhantomChipAdded = 'Client sounds - phantom chip added',
  clientSoundsStartGame = 'Client sounds - start game',
  helpGettingStarted = 'Help - Getting Started',
  helpPlacingBets = 'Help - Placing Bets',
  helpBetTypes = 'Help - Bet Types',
  helpGameLimits = 'Help - Game Limits',
  helpChipValues = 'Help - Chip Values',
  helpBallSelection = 'Help - Ball Selection',
  helpResultDisplay = 'Help - Result Display',
  helpDisconnectionPolicy = 'Help - Disconnection Policy',
  groupFeatureItem = 'Group Feature Item',
  lobbyBanner = 'Lobby banner'
}

export enum CmsEventAssetType {
  robotBackscreenVideo = 'robot-backscreen-video',
  clientSoundsYellowChipAdded = 'Client sounds - yellow chip added',
  clientSoundsPinkChipAdded = 'Client sounds - pink chip added',
  clientSoundsRedChipAdded = 'Client sounds - red chip added',
  clientSoundsGreenChipAdded = 'Client sounds - green chip added',
  clientSoundsBlueChipAdded = 'Client sounds - blue chip added',
  clientSoundsPhantomChipAdded = 'Client sounds - phantom chip added',
  clientSoundsStartGame = 'Client sounds - start game',
}

export enum CmsLanguage {
  enUS = 'en-US',
  esES = 'es-ES',
  ukUA = 'uk-UA',
  ruRU = 'ru-RU',
  zhCN = 'zh-CN',
  jaJP = 'ja-JP',
  trTR = 'tr-TR',
  svSE = 'sv-SE',
  itIT = 'it-IT',
  daDK = 'da-DK',
  ptPT = 'pt-PT',
  deDE = 'de-DE',
  nlNL = 'nl-NL',
  roRO = 'ro-RO',
  hiIN = 'hi-IN'
}

export enum CmsRegulation {
  uk = 'UK',
  mlt = 'MLT',
}

export interface IPostCmsRequestBody extends ICms {
}

export interface IPutCmsRequestBody extends ICms {
}

export interface IRemoveCmsRequestBody {
  id: CmsId;
}

export interface ICmsFiltersPanelValues {
  id?: CmsId;
  assetGroup?: string;
  assetType?: string;
  brandId?: string;
  operatorId?: OperatorId;
  groupId?: number;
  language?: string;
  regulation?: string;
  gameId?: string;
}

export interface IGetCmsRequestFilterParams extends ICommonRequestFiltersParams, ICmsFiltersPanelValues {
}

export interface IGetCmsResponseBody {
  total: number;
  records: ICmses;
}

export interface ICmsDialogFormState {
  isOpen: boolean;
  isLoading?: boolean;
  initialValues?: Partial<ICms>;
}

export interface ICmsReducer extends IGetCmsResponseBody {
  readonly isLoading: boolean;
  readonly dialogForm: ICmsDialogFormState;
  readonly assetTypes: IAssetTypes;
  readonly isLoadingAssetTypes: boolean;
}

export interface ICmsSlice {
  cmsReducer: ICmsReducer;
}
