import { createAction } from '@reduxjs/toolkit';
import {
  IGetActivityRequestFiltersParams,
  IGetActivityProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const clearActivityReport = createAction('REPORT/CLEAR_ACTIVITY_REPORT');

export const getActivityReport = createAction<IGetActivityRequestFiltersParams>('REPORT/GET_ACTIVITY_REPORT');
export const getActivityReportError = createAction('REPORT/GET_ACTIVITY_REPORT/ERROR');

export const getProcessedActivityReport = createAction<
  IGetActivityProcessedReportsFilterParams
>('REPORT/GET_PROCESSED_ACTIVITY_REPORT/SUCCESS');
export const setProcessedActivityReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
>('REPORTS/SET_PROCESSED_ACTIVITY_REPORTS/SUCCESS');

export const exportActivityReport = createAction<IGetActivityRequestFiltersParams>('REPORT/EXPORT_ACTIVITY');
export const exportActivityReportSuccess = createAction('REPORT/EXPORT_ACTIVITY/SUCCESS');
export const exportActivityReportError = createAction('REPORT/EXPORT_ACTIVITY/ERROR');
