import { IStyle } from '@fluentui/merge-styles';

export interface IForcePortraitModeStyleProps {
}

export interface IForcePortraitModeStyles {
  message: IStyle;
}

export const getStyles = (): IForcePortraitModeStyles => ({
  message: {
    marginTop: '3rem',
    fontSize: '3rem',
  },
});
