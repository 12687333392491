import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getPlayerBlocksReport,
  getPlayerBlocksReportError,
  exportPlayerBlocksReportError,
  exportPlayerBlocksReportSuccess,
  exportPlayerBlocksReport,
  getProcessedPlayerBlocksReport,
  setProcessedPlayerBlocksReportsSuccess,
} from './actions';
import { IPlayerBlocksReportReducer } from '../types';

export const initialState: IPlayerBlocksReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const playerBlocksReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getPlayerBlocksReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getPlayerBlocksReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportPlayerBlocksReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportPlayerBlocksReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportPlayerBlocksReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedPlayerBlocksReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedPlayerBlocksReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
