import {
  IFlagAlertRequestBody,
  IGetAlertsRequestFiltersParams,
  IGetAlertsResponseBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';
import { IAlertsDismissRequestBody } from '../../types/alerts';

export const getAlertsRequest = (filterParams: IGetAlertsRequestFiltersParams, isAutoRefresh?: boolean) => {
  const url = getUrl('/alerts', filterParams);

  return makeRequest<IGetAlertsResponseBody>(url, {}, 'GET', {}, isAutoRefresh);
};

export const dismissAlertRequest = (data: IAlertsDismissRequestBody) => {
  const url = getUrl(`/alerts/dismiss`);

  return makeRequest(url, data, 'POST');
};

export const flagAlertRequest = (data: IFlagAlertRequestBody) => {
  const url = getUrl(`/alerts/flag`);

  return makeRequest(url, data, 'POST');
};
