import React, { ReactNode, useCallback } from 'react';
import {
  Form, ButtonColor, PasswordField, DialogConfirmation, DialogSection, JSONEditorBase, PasswordFieldName,
} from 'arcadia-common-fe';
import './DialogAction.scss';

export type ActionTypeUnion = 'positive' | 'negative';

export const ActionType : { [key in ActionTypeUnion] : ActionTypeUnion } = {
  positive: 'positive',
  negative: 'negative',
};

export interface IDialogActionProps<TValues> {
  isOpen: boolean
  action: string
  message?: string
  detailsJSON?: string
  initialValues?: TValues
  subject: string
  isLoading?: boolean
  topContent?: React.ReactNode,
  bottomContent?: React.ReactNode,
  withPasswordConfirm?: boolean
  actionType?: ActionTypeUnion
  onSubmit: (data: TValues) => void
  onClose: () => void
  children?: ReactNode
  validate?: (values: TValues) => Record<string, string> | undefined
}

let submit: () => void;

export const DialogAction: <TValues>(props: IDialogActionProps<TValues>) => React.ReactElement = ({
  isOpen,
  isLoading,
  message,
  detailsJSON,
  initialValues,
  withPasswordConfirm = false,
  action,
  subject,
  children,
  topContent,
  bottomContent,
  actionType = ActionType.positive,
  onSubmit,
  onClose,
  validate,
}) => {
  const handleSubmitClick = useCallback(() => {
    submit();
  }, [submit]);

  return (
    <DialogConfirmation
      className="dialog-action"
      footerClassNames="dialog-action__footer"
      isOpen={isOpen}
      isLoading={isLoading}
      title={`${action} ${subject}`}
      submitText={action}
      submitBtnColor={actionType === ActionType.positive ? ButtonColor.secondary : ButtonColor.quaternary}
      onSubmit={handleSubmitClick}
      onClose={onClose}
    >
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, submitFailed, error }) => {
          submit = handleSubmit;

          return (
            <form onSubmit={handleSubmit}>
              {topContent}
              <DialogSection className="dialog-action__content">
                <div className="dialog-action__text">
                  {message || `Are you sure you want to ${action.toLowerCase()} the ${subject.toLowerCase()}?`}
                </div>
                {detailsJSON && (
                  <JSONEditorBase
                    value={detailsJSON}
                    height="200px"
                    readOnly
                  />
                )}
              </DialogSection>
              {children}
              {
                withPasswordConfirm && (
                  <DialogSection>
                    <div className="dialog-action__field">
                      <div className="dialog-action__field-label">Confirm changes with your password</div>
                      <PasswordField
                        name={PasswordFieldName.confirmationPassword}
                        label=""
                        withSymbolsValidation={false}
                      />
                    </div>
                  </DialogSection>
                )
              }
              {bottomContent}
              { submitFailed && error && (
                <div className="dialog-action__validation-message">
                  {error}
                </div>
              )}
            </form>
          );
        }}
      />
    </DialogConfirmation>
  );
};
