import { IStyle } from '@fluentui/merge-styles';

export interface ILiveBadgeStylesProps {
}

export interface ILiveBadgeStyles {
  root: IStyle;
  image: IStyle;
}

export const getStyles = (): ILiveBadgeStyles => ({
  root: [
    {
      width: '15.5rem',
      display: 'flex',
      pointerEvents: 'none',
    },
  ],
  image: {
    width: '100%',
  },
});
