import { createAction } from '@reduxjs/toolkit';
import {
  IGetRetentionRequestFiltersParams,
  IGetRetentionProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getRetentionReport = createAction<IGetRetentionRequestFiltersParams>('REPORT/GET_RETENTION');
export const getRetentionReportError = createAction('REPORT/GET_RETENTION/ERROR');

export const getProcessedRetentionReport = createAction<
  IGetRetentionProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_RETENTION_REPORT/SUCCESS');
export const setProcessedRetentionReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_RETENTION_REPORTS/SUCCESS');

export const exportRetentionReport = createAction<IGetRetentionRequestFiltersParams>('REPORT/EXPORT_RETENTION');
export const exportRetentionReportSuccess = createAction('REPORT/EXPORT_RETENTION/SUCCESS');
export const exportRetentionReportError = createAction('REPORT/EXPORT_RETENTION/ERROR');
