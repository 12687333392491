import { GroupAction, GroupStatus } from './types';

export const groupStatusLabelMap: { [key in GroupStatus]: string } = {
  [GroupStatus.inPlay]: 'In play',
  [GroupStatus.idle]: 'Active',
  [GroupStatus.drying]: 'Drying',
  [GroupStatus.shuttingDown]: 'Shutting down',
  [GroupStatus.offline]: 'Offline',
};

export const groupActionLabelMap: { [key in GroupAction]: string } = {
  [GroupAction.activate]: 'Activate',
  [GroupAction.dry]: 'Dry',
  [GroupAction.shutdown]: 'Shutdown',
  [GroupAction.remove]: 'Remove',
};
