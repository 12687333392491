import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetMachineRequestFiltersParams,
  IGetMachineStatusReportResponseBody,
  IGetMachineStatusProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getMachineStatusProcessedReportRequest = ({ filterParams }: IGetMachineStatusProcessedReportsFilterParams) => {
  const url = getUrl('/report/machine-status/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getMachineStatusReportRequest = (filterParams: IGetMachineRequestFiltersParams) => {
  const url = getUrl('/report/machine-status', filterParams);

  return makeRequest<IGetMachineStatusReportResponseBody>(url);
};
