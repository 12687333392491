import { ConnectionOptions, IEventEmitter, ISocket } from './types';

export abstract class PubSubClientBase<SendEvents extends string, ReceiveEvents extends string, EmitterEvents extends string> {
  protected socket: ISocket<SendEvents, ReceiveEvents> | null = null;
  protected eventEmitter: IEventEmitter<EmitterEvents> | null = null;

  protected constructor(socket: ISocket<SendEvents, ReceiveEvents>, eventEmitter: IEventEmitter<EmitterEvents>) {
    this.socket = socket;
    this.eventEmitter = eventEmitter;
  }

  isSocketConnected(): boolean {
    return this.socket?.isConnected() ?? false;
  }

  protected abstract registerEvents(): void;

  protected abstract getEvents(): ReceiveEvents[];

  protected on(event: EmitterEvents, listener: (...args: any) => void): this {
    this.eventEmitter?.on(event, listener);
    return this;
  }

  protected emit(event: EmitterEvents, ...args: any[]): void {
    this.eventEmitter?.emit(event, ...args);
  }

  protected connectToSocket(
    connectOptions: ConnectionOptions,
    connectionEvent: ReceiveEvents,
  ): Promise<ISocket<SendEvents, ReceiveEvents>> {
    this.socket?.connect(connectOptions);
    this.registerEvents();

    if (process.env.BUILD_NODE_ENV !== 'production') {
      this.handleSetupDebugger(this.getEvents());
    }

    return new Promise((resolve) => {
      this.socket?.on(connectionEvent, () => {
        resolve(this.socket!);
      });
    });
  }

  protected handleSetupDebugger = (types: ReceiveEvents[]) => {
    types.forEach((event: ReceiveEvents) => {
      this.socket?.on(event, (data: unknown) => {
        /* eslint-disable no-console */
        console.groupCollapsed(`%c Socket Subscribe ======= ${event} =======`, 'background: #222; color: #bada55');
        console.log(JSON.stringify(data, null, 4));
        console.groupEnd();
        /* eslint-enable no-console */
      });
    });
  };
}
