export const isCookiesAvailable = () => {
  try {
    document.cookie = 'test=123;';

    if (document.cookie.includes('test=123')) {
      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const isSessionStorageAvailable = () => {
  try {
    window.sessionStorage.getItem('test');

    return true;
  } catch (error) {
    return false;
  }
};
