import React from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import { IPanelProps, Panel } from '../../../Panel/Panel';
import { useStylesWithDeviceInfo } from '../../../../hooks/useStylesWithDeviceInfo';
import { IMenuItems } from '../../types';
import IconArrow from '../../assets/svg/arrowRight.svg';
import { getStyles, IMainMenuPanelStyleProps, IMainMenuPanelStyles } from './styles/MainMenuPanel';

export interface IMainMenuPanelProps extends Omit<IPanelProps, 'styles'> {
  styles?: IStyleFunctionOrObject<IMainMenuPanelStyleProps, IMainMenuPanelStyles>;
  items: IMenuItems;
  withClosingArrow?: boolean;
}

const MainMenuPanelBase: React.FC<IMainMenuPanelProps> = ({ items, styles, onClose, withClosingArrow, ...restProps }) => {

  const classNames = useStylesWithDeviceInfo<IMainMenuPanelStyles, IMainMenuPanelStyleProps>(styles);

  return (
    <Panel {...restProps} className={classNames.panel} onClose={onClose}>
      {withClosingArrow && <IconArrow
        className={classNames.iconBack}
        onClick={onClose}
      />}
      <div className={classNames.mainMenu} data-e2e-selector="menu-container">
        {items.map(({
          Icon, title, onClick,
        }) => (
          // TODO: Need to use Button component instead of div BEGIN
          <div
            key={title}
            className={classNames.item}
            role="button"
            tabIndex={0}
            onClick={onClick}
            data-e2e-selector={`menu-${title.toLowerCase().replace(/ /g, '-')}`}
          >
            <Icon className={classNames.icon} data-e2e-selector={`menu-${title.toLowerCase().replace(/ /g, '-')}-icon`}/>
            {title}
          </div>
          // TODO: Need to use Button component instead of div END
        ))}
      </div>
    </Panel>
  );
};

export const MainMenuPanel = styled<IMainMenuPanelProps, IMainMenuPanelStyleProps, IMainMenuPanelStyles>(
  MainMenuPanelBase,
  getStyles,
);
