import { Store } from 'redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { DEFAULT_IGNORE_ERRORS_PATTERNS, DSN } from './constants';

export function sentryInit(store: Store) {
  Sentry.init({
    dsn: DSN,
    release: process.env.RELEASE_ID,
    enabled: !!DSN,
    ignoreErrors: DEFAULT_IGNORE_ERRORS_PATTERNS,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing(),
      new Sentry.Integrations.TryCatch(),
    ],
    beforeSend(event) {
      const { user } = store.getState().authReducer || {};
      const { id, email, fullName } = user;

      // eslint-disable-next-line no-param-reassign
      event.user = {
        id,
        email,
        fullName,
      };

      return event;
    },
  });
}

export * from '@sentry/react';
