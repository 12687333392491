import { ICommonRequestFiltersParams, INormalizedEntities } from 'arcadia-common-fe';
import { BrandId } from '../../types/brand';

export type TransactionId = number;

export enum TransactionStatus {
  failed = 'failed',
  pending = 'pending',
  completed = 'completed',
  dismissed = 'dismissed',
}

export interface ITransaction {
  id: TransactionId;
  status: TransactionStatus;
  operatorName: string;
  playerCid: string;
  sessionId: number;
  type: string;
  retries: number;
  createDate: string;
  brandId: BrandId;
  brandName: string;
  playerId: number;
}

export type ITransactions = ITransaction[];

export type ITransactionsEntities = INormalizedEntities<ITransaction, TransactionId>;

export interface ITransactionsNormalizedData {
  ids: TransactionId[];
  entities: ITransactionsEntities;
  total: number;
}

export interface ITransactionsFiltersPanelValues {
  id?: TransactionId | TransactionId[];
  status?: TransactionStatus[];
  operatorName?: string;
  brandName?: string;
}

export interface IGetTransactionsRequestFilterParams extends ICommonRequestFiltersParams, ITransactionsFiltersPanelValues {
}

export interface IGetTransactionsResponseBody {
  total: number;
  transactions: ITransactions;
}

export interface ITransactionsDismissRequestBody {
  ids: TransactionId[];
}

export interface ITransactionsRetryRequestBody {
  ids: TransactionId[];
}

export type ISelectedTransactions = TransactionId[];

export interface ITransactionsDismissDialogState {
  isOpen: boolean;
  isLoading?: boolean;
}

export interface ITransactionsRetryDialogState {
  isOpen: boolean;
  isLoading?: boolean;
}

export interface ITransactionsReducer extends ITransactionsNormalizedData {
  readonly isLoading: boolean;
  readonly selectedTransactions: ISelectedTransactions;
  readonly dismissDialog: ITransactionsDismissDialogState;
  readonly retryDialog: ITransactionsRetryDialogState;
}

export interface ITransactionsSlice {
  transactionsReducer: ITransactionsReducer;
}
