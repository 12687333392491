import { INormalizedEntities, PermissionId } from 'arcadia-common-fe';

export enum UserPermissionName {
  dashboard = 'dashboard',
  sessions = 'sessions',
  players = 'players',
  groups = 'groups',
  machines = 'machines',
  vouchers = 'vouchers',
  operators = 'operators',
  reports = 'reports',
  alerts = 'alerts',
  disputes = 'disputes',
  monitoring = 'monitoring',
  administration = 'administration',
  cameras = 'cameras',
  maintenance = 'maintenance',
  transactions = 'transactions',
  sites = 'sites',
  cms = 'cms',
  currencyManager = 'currencyManager',
  brandSecrets = 'brandSecrets',
  brands = 'brands',
}

export interface IPermission {
  name: UserPermissionName
}

export type IPermissionsEntities = INormalizedEntities<IPermission, PermissionId>;

export interface IGetPermissionsResponseBody {
  permissions: IPermission[]
}

export type IGetUserPermissionsResponseBody = UserPermissionName[];

export interface IPermissionsData {
  permissionsNames: UserPermissionName[]
  permissionsEntities: IPermissionsEntities
  userPermissions: UserPermissionName[]
}

export interface IAuthReducer extends IPermissionsData {
  readonly isLoading: boolean
  readonly isPermissionsLoading: boolean
  readonly token: string | null
  readonly user: IUserData
}

export interface IUserData {
  firstName: string
  lastName: string
  email: string
}

export interface IAuthStoreSlice {
  authReducer: IAuthReducer
}

export interface ISignInRequestBody {
  email: string
  password: string
}

export interface ISignInResponseBody {
  token: string
  user: IUserData
}
