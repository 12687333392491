import {
  useCallback, useState,
} from 'react';
import FILE_ADDRESS from '../assets/images/speedMeasure.jpeg';

const FILE_SIZE_IN_BITS = 80224;
const MAX_NUMBER_OF_MEASUREMENTS = 5;

export const useConnectionSpeedMeasure = () => {
  const [latestConnectionSpeeds, setLatestConnectionSpeeds] = useState<number[]>([]);

  const handleMeasureConnectionSpeed = useCallback(() => {
    const now = Date.now();
    const measureFile = new Image();

    measureFile.src = `${FILE_ADDRESS}?version=${now}`;

    measureFile.onload = () => {
      const endTime = Date.now();
      const duration = (endTime - now) / 1000;
      const speedKbps = parseFloat((FILE_SIZE_IN_BITS / duration / 1024).toFixed(2));

      setLatestConnectionSpeeds((prevState) => {
        const newState = [...prevState, speedKbps];

        if (prevState.length >= MAX_NUMBER_OF_MEASUREMENTS) {
          newState.shift();
        }

        return newState;
      });
    };

    measureFile.onerror = (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    };
  }, []);

  return {
    latestConnectionSpeeds,
    handleMeasureConnectionSpeed,
  };
};
