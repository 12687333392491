export type AlertId = number;

export enum AlertSeverity {
  critical = 'critical',
  high = 'high',
  medium = 'medium',
  low = 'low',
}

export enum AlertType {
  maintenance = 'maintenance',
  information = 'information',
  warning = 'warning',
  alert = 'alert',
  error = 'error',
}

export enum AlertStatus {
  active = 'active',
  dismissed = 'dismissed',
}

export enum AlertAction {
  dismiss = 'dismiss',
  fillDispenser = 'fillDispenser',
  emptyDispenserWaist = 'emptyDispenserWaist'
}

export interface IAlertsDismissRequestBody {
  ids: AlertId[]
}
