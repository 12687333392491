import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { history, saveStringAsFile } from 'arcadia-common-fe';
import {
  exportOperators,
  getOperators,
  getOperatorsSuccess,
  getOperatorsError,
  exportOperatorsError,
  exportOperatorsSuccess,
  postOperator,
  mergeOperatorDialogForm,
  setOperatorDialogForm,
  putOperator,
  putOperatorSuccess,
  executeOperatorsAction,
  mergeOperatorsDialogAction,
  setOperatorsDialogAction,
  executeOperatorsActionSuccess,
  getOperatorLogo,
  getOperatorLogoSuccess,
  getOperatorLogoError,
} from './actions';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  IGetOperatorLogoRequestBody,
  IGetOperatorLogoResponseBody,
  IGetOperatorsRequestFilterParams,
  IGetOperatorsResponseBody,
  OperatorAction,
} from '../types';
import {
  getOperatorsRequest,
  postOperatorsRequest,
  putOperatorsRequest,
  enableOperatorRequest,
  disableOperatorRequest,
  removeOperatorRequest,
  getOperatorLogoRequest,
} from '../api';
import { operatorStatusLabelMap } from '../constants';

function* handleRefreshOperator() {
  yield put(getOperators(queryString.parse(window.location.search)));
}

function* handleGetOperatorsRequest(requestParams: IGetOperatorsRequestFilterParams) {
  const { data } = yield call(getOperatorsRequest, requestParams);

  return data;
}

export function* handleGetOperators({ payload }: ReturnType<typeof getOperators>) {
  try {
    const operatorsData: IGetOperatorsResponseBody = yield handleGetOperatorsRequest(payload);

    yield put(getOperatorsSuccess(operatorsData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getOperatorsError());
  }
}

function* handlePostOperator({ payload }: ReturnType<typeof postOperator>) {
  try {
    yield put(mergeOperatorDialogForm({
      isLoading: true,
    }));
    yield call(postOperatorsRequest, payload);
    yield put(setOperatorDialogForm());
    yield call(toast.success, 'The operator has been successfully created');
    yield handleRefreshOperator();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeOperatorDialogForm({
      isLoading: false,
    }));
  }
}

function* handlePutOperator({ payload }: ReturnType<typeof putOperator>) {
  try {
    yield put(mergeOperatorDialogForm({
      isLoading: true,
    }));

    const { data } = yield call(putOperatorsRequest, payload);

    yield put(putOperatorSuccess(data));
    yield put(setOperatorDialogForm());
    yield call(toast.success, `The operator ${payload.name} has been successfully updated`);
    yield handleRefreshOperator();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeOperatorDialogForm({
      isLoading: false,
    }));
  }
}

function* handleExecuteOperatorsAction({ payload }: ReturnType<typeof executeOperatorsAction>): any {
  try {
    const { location: { search } } = history;
    const { id, action } = payload;
    let response;

    yield put(mergeOperatorsDialogAction({ isLoading: true }));

    switch (action) {
      case OperatorAction.enable:
        response = yield call(enableOperatorRequest, payload);
        yield call(toast.success, `The operator with id ${id} has been successfully enabled.`);
        yield handleRefreshOperator();
        break;
      case OperatorAction.disable:
        response = yield call(disableOperatorRequest, payload);
        yield call(toast.success, `The operator with id ${id} has been successfully disabled.`);
        yield handleRefreshOperator();
        break;
      case OperatorAction.remove:
        response = yield call(removeOperatorRequest, payload);
        yield call(toast.success, `The operator with id ${id} has been successfully removed.`);
        break;
      default:
        break;
    }

    yield put(setOperatorsDialogAction());

    if (action === OperatorAction.remove) {
      yield put(getOperators(queryString.parse(search)));
    } else {
      yield put(executeOperatorsActionSuccess(response.data));
    }
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeOperatorsDialogAction({ isLoading: false }));
  }
}

function* handleGetOperatorLogoRequest(requestParams: IGetOperatorLogoRequestBody) {
  const { data } = yield call(getOperatorLogoRequest, requestParams);

  return data;
}

export function* handleGetOperatorLogo({ payload }: ReturnType<typeof getOperatorLogo>) {
  try {
    const operatorsData: IGetOperatorLogoResponseBody = yield handleGetOperatorLogoRequest(payload);

    yield put(getOperatorLogoSuccess({ ...operatorsData, id: payload.id }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getOperatorLogoError({ id: payload.id }));
  }
}

function* handleExportOperators({ payload }: ReturnType<typeof exportOperators>): any {
  try {
    const { operators }: IGetOperatorsResponseBody = yield handleGetOperatorsRequest(payload);

    const preparedData = operators.map((operator) => ({
      Status: operatorStatusLabelMap[operator.status],
      'Operator Name': operator.name,
      'Operator ID': operator.id,
      'API Connector ID': operator.apiConnectorId,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Operators.csv');
    yield put(exportOperatorsSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportOperatorsError());
  }
}

export function* operatorsSagas(): any {
  yield all([
    yield takeLatest(
      getOperators,
      handleGetOperators,
    ),
    yield takeLatest(
      postOperator,
      handlePostOperator,
    ),
    yield takeLatest(
      putOperator,
      handlePutOperator,
    ),
    yield takeLatest(
      executeOperatorsAction,
      handleExecuteOperatorsAction,
    ),
    yield takeLatest(
      exportOperators,
      handleExportOperators,
    ),
    yield takeLatest(
      getOperatorLogo,
      handleGetOperatorLogo,
    ),
  ]);
}
