import { createAction } from '@reduxjs/toolkit';
import { PasswordFieldName } from 'arcadia-common-fe';
import {
  IPostCredentialRequestBody,
  IGetCredentialsRequestFilterParams,
  IGetCredentialsResponseBody,
  IPutCredentialRequestBody,
  ICredentialDialogActionState,
  ICredentialDialogFormState,
  CredentialAction,
  ICredentialsBySecretName,
  IGetBrandSecretsNamesResponseBody,
} from '../types';
import { CredentialId, ICredential } from '../../../types/credential';

interface IExecuteActionCredential {
  id: CredentialId;
  action: CredentialAction;
  isCredentialEdit: boolean;
  credential?: ICredential;
  secretName: string;
  [PasswordFieldName.confirmationPassword]: string;
}

export const postCredential = createAction<IPostCredentialRequestBody>('CREDENTIALS/POST_CREDENTIAL');

export const setCredentials = createAction<ICredentialsBySecretName>('CREDENTIALS/SET_CREDENTIALS');

export const getCredentials = createAction<IGetCredentialsRequestFilterParams>('CREDENTIALS/GET_CREDENTIALS');
export const getCredentialsSuccess = createAction<IGetCredentialsResponseBody>('CREDENTIALS/GET_CREDENTIALS/SUCCESS');
export const getCredentialsError = createAction('CREDENTIALS/GET_CREDENTIALS/ERROR');

export const putCredential = createAction<IPutCredentialRequestBody>('CREDENTIALS/PUT_CREDENTIAL');

export const setCredentialDialogForm = createAction<Partial<ICredentialDialogFormState> | undefined>('CREDENTIALS/SET_DIALOG_FORM');
export const mergeCredentialDialogForm = createAction<Partial<ICredentialDialogFormState> | undefined>('CREDENTIALS/MERGE_DIALOG_FORM');

export const setCredentialDialogAction = createAction<Partial<ICredentialDialogActionState> | undefined>(
  'CREDENTIALS/SET_DIALOG_ACTION',
);
export const mergeCredentialDialogAction = createAction<Partial<ICredentialDialogActionState> | undefined>(
  'CREDENTIALS/MERGE_DIALOG_ACTION',
);

export const executeCredentialAction = createAction<IExecuteActionCredential>('CREDENTIALS/EXECUTE_ACTION');

export const clearCredentialsAction = createAction<{secretName: string}>('CREDENTIALS/CLEAR_CREDENTIALS_ACTION');

export const getBrandSecretsNames = createAction('CREDENTIALS/GET_BRAND_SECRETS_NAMES');
export const getBrandSecretsNamesSuccess = createAction<IGetBrandSecretsNamesResponseBody>('CREDENTIALS/GET_BRAND_SECRETS_NAMES_SUCCESS');
