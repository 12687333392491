import { Environment } from '../../environment';

interface IIceServer {
  url: string;
  username: string;
  credential: string;
}

type IIceServers = IIceServer[];

const DEV_ICE_SERVERS: IIceServers = [
  { url: 'turn:turn1.arcdemo.cleversphere.com?transport=tcp', username: 'flashphoner', credential: 'coM77EMrV7Cwhyan' },
  { url: 'turn:turn2.arcdemo.cleversphere.com?transport=tcp', username: 'flashphoner', credential: 'coM77EMrV7Cwhyan' },
  { url: 'turn:turn3.arcdemo.cleversphere.com?transport=tcp', username: 'flashphoner', credential: 'coM77EMrV7Cwhyan' },
];

const PRODUCTION_ICE_SERVERS: IIceServers = [
  { url: 'turn:video-wcs1.arcadiagaming.io?transport=tcp', username: 'flashphoner', credential: 'SDF@s6543F!^dg&sf' },
  { url: 'turn:video-wcs2.arcadiagaming.io?transport=tcp', username: 'flashphoner', credential: 'SDF@s6543F!^dg&sf' },
  { url: 'turn:video-wcs3.arcadiagaming.io?transport=tcp', username: 'flashphoner', credential: 'SDF@s6543F!^dg&sf' },
  { url: 'turn:video-wcs4.arcadiagaming.io?transport=tcp', username: 'flashphoner', credential: 'SDF@s6543F!^dg&sf' },
  { url: 'turn:video-wcs5.arcadiagaming.io?transport=tcp', username: 'flashphoner', credential: 'SDF@s6543F!^dg&sf' },
];

export const iceServersByEnvironment: Record<Environment, IIceServers> = {
  [Environment.development]: DEV_ICE_SERVERS,
  [Environment.stage]: DEV_ICE_SERVERS,
  [Environment.uat]: PRODUCTION_ICE_SERVERS,
  [Environment.production]: PRODUCTION_ICE_SERVERS,
};
