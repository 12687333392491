import { IAuthStoreSlice } from './types';

export const authReducerSelector = ({ authReducer }: IAuthStoreSlice) => authReducer;

export const isAuthenticatedSelector = ({ authReducer }: IAuthStoreSlice) => !!authReducer.token;

export const authenticatedSelector = ({ authReducer }: IAuthStoreSlice) => authReducer.token;

export const userSelector = ({ authReducer }: IAuthStoreSlice) => authReducer.user;

export const permissionsSelector = ({ authReducer }: IAuthStoreSlice) => {
  const { isPermissionsLoading, permissionsNames, permissionsEntities } = authReducer;

  return {
    isPermissionsLoading,
    permissionsNames,
    permissionsEntities,
  };
};
