import React, { useCallback } from 'react';
import { IStyleFunctionOrObject, styled } from '@fluentui/utilities';
import IconArrow from './assets/svg/arrowRight.svg';
import { useStylesWithDeviceInfo } from '../../hooks/useStylesWithDeviceInfo';
import { getStyles, IPanelHeaderStyleProps, IPanelHeaderStyles } from './styles/PanelHeader';

export interface IPanelHeaderProps extends Partial<IPanelHeaderStyleProps> {
  styles?: IStyleFunctionOrObject<IPanelHeaderStyleProps, IPanelHeaderStyles>;
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  backBtnComponent?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  title: string;
  onBackClick: () => void;
  openSoundCallback?: () => void;
}

const PanelHeaderBase: React.FC<IPanelHeaderProps> = ({
  styles,
  Icon,
  backBtnComponent: BackBtn,
  title,
  onBackClick,
  openSoundCallback,
}) => {
  const handleBackClick = useCallback(() => {
    openSoundCallback?.();
    onBackClick();
  }, [onBackClick, openSoundCallback]);

  const classNames = useStylesWithDeviceInfo<IPanelHeaderStyles, IPanelHeaderStyleProps>(styles);

  return (
    <div className={classNames.panelHeader}>
      { BackBtn ? (
        <BackBtn
          className={classNames.iconBack}
          onClick={handleBackClick}
        />
      ) : (
        <IconArrow
          className={classNames.iconBack}
          onClick={handleBackClick}
        />
      )}
      { Icon && (
        <Icon className={classNames.titleIcon} data-e2e-selector={`menu-${title.toLowerCase().replace(/ /g, '-')}-headline-icon`}/>
      ) }
      <span data-e2e-selector={`menu-${title.toLowerCase().replace(/ /g, '-')}-headline-text`}>{ title }</span>
    </div>
  );
};

export const PanelHeader = styled<IPanelHeaderProps, IPanelHeaderStyleProps, IPanelHeaderStyles>(
  PanelHeaderBase,
  getStyles,
);
