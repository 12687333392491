import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getMachineStatusReport,
  getMachineStatusReportError,
  exportMachineStatusReportError,
  exportMachineStatusReportSuccess,
  exportMachineStatusReport,
  getProcessedMachineStatusReport,
  setProcessedMachineStatusReportsSuccess,
} from './actions';
import { IMachineStatusReportReducer } from '../types';

export const initialState: IMachineStatusReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const machineStatusReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getMachineStatusReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getMachineStatusReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportMachineStatusReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportMachineStatusReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportMachineStatusReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedMachineStatusReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedMachineStatusReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
