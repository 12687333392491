import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getRetentionReport,
  getRetentionReportError,
  exportRetentionReport,
  exportRetentionReportError,
  exportRetentionReportSuccess,
  getProcessedRetentionReport,
  setProcessedRetentionReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getRetentionProcessedReportRequest, getRetentionReportRequest } from '../api';
import { IRetentionReportItem, IGetRetentionReportResponseBody, IGetRetentionRequestFiltersParams } from '../types';
import { retentionReportReducerSelector } from './selectors';

function* handleGetRetentionReportRequest(requestParams: IGetRetentionRequestFiltersParams) {
  const { data } = yield call(getRetentionReportRequest, requestParams);

  return data;
}

function* handleGetRetentionProcessedReport({ payload }: ReturnType<typeof getProcessedRetentionReport>) {
  try {
    const { data } = yield call(getRetentionProcessedReportRequest, payload);

    yield put(setProcessedRetentionReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getRetentionReportError());
  }
}

function* handleGetRetentionReport({ payload }: ReturnType<typeof getRetentionReport>) {
  try {
    const retentionReportData: IGetRetentionReportResponseBody = yield handleGetRetentionReportRequest(payload);
    const { info } = retentionReportData;
    const { filterParams } = yield select(retentionReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedRetentionReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getRetentionReportError());
  }
}

function* handleExportRetentionReport({ payload }: ReturnType<typeof exportRetentionReport>): any {
  try {
    const { data }: IGetRetentionReportResponseBody = yield handleGetRetentionReportRequest(payload);

    const preparedData = data.map((retentionReport: IRetentionReportItem) => ({
      'Grouping Value': retentionReport.groupingValue,
      R1: retentionReport.r1,
      R2: retentionReport.r2,
      R7: retentionReport.r7,
      R14: retentionReport.r14,
      R30: retentionReport.r30,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Retention Report.csv');
    yield put(exportRetentionReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportRetentionReportError());
  }
}

export function* retentionReportSagas(): any {
  yield all([
    yield takeLatest(
      getRetentionReport,
      handleGetRetentionReport,
    ),
    yield takeLatest(
      getProcessedRetentionReport,
      handleGetRetentionProcessedReport,
    ),
    yield takeLatest(
      exportRetentionReport,
      handleExportRetentionReport,
    ),
  ]);
}
