import { ICommonRequestFiltersParams, PasswordFieldName } from 'arcadia-common-fe';
import { ICredential, ICredentials, CredentialId } from '../../types/credential';

export enum CredentialAction {
  remove,
  getCredentials,
}

export interface IPostCredentialRequestBody extends ICredential {
  password: string;
}

export interface IPutCredentialRequestBody extends ICredential {
  password: string;
}

export interface ICredentialsBySecretName {
  [key: string]: object | undefined;
}

export interface IPostSecretNameRequestBody {
  [PasswordFieldName.confirmationPassword]: string;
  secretName: string;
}

export interface IRemoveCredentialRequestBody {
  id: CredentialId;
  [PasswordFieldName.confirmationPassword]: string;
}

export interface ICredentialsFiltersPanelValues {
  displayName?: string;
}

export interface IBrandSecret {
  id: number;
  name: string;
}

export type IBrandSecrets = IBrandSecret[];

export interface IGetBrandSecretsNamesResponseBody {
  total: number;
  brandSecrets: IBrandSecrets;
}

export interface IGetCredentialsRequestFilterParams extends ICommonRequestFiltersParams, ICredentialsFiltersPanelValues {
}

export interface IGetCredentialsResponseBody {
  total: number;
  brandSecrets: ICredentials;
}

export interface ICredentialDialogActionState {
  id: CredentialId | null;
  action: CredentialAction;
  secretName?: string;
  isCredentialEdit?: boolean;
  isOpen: boolean;
  isLoading?: boolean;
}

export interface ICredentialDialogFormState {
  isOpen: boolean;
  isLoading?: boolean;
  initialValues?: Partial<ICredential>;
}

export interface ICredentialsReducer extends IGetCredentialsResponseBody {
  readonly isLoading: boolean;
  readonly credentials: ICredentialsBySecretName;
  readonly dialogForm: ICredentialDialogFormState;
  readonly dialogAction: ICredentialDialogActionState;
  readonly brandSecretsNames: IBrandSecrets;
}

export interface ICredentialsSlice {
  credentialsReducer: ICredentialsReducer;
}
