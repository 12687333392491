import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getFunnelReport,
  getFunnelReportError,
  exportFunnelReportError,
  exportFunnelReportSuccess,
  exportFunnelReport,
  getProcessedFunnelReport,
  setProcessedFunnelReportsSuccess,
} from './actions';
import { IFunnelReportReducer } from '../types';

export const initialState: IFunnelReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const funnelReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getFunnelReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getFunnelReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportFunnelReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportFunnelReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportFunnelReportError, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(getProcessedFunnelReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedFunnelReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
