import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  IGetCmsRequestFilterParams,
  IGetCmsResponseBody,
  CmsAction,
  IAssetTypes,
} from '../types';
import {
  postCmsRequest,
  getCmsRequest,
  putCmsRequest,
  removeCmsRequest,
  getAssetTypesRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  postCms,
  getCms,
  getCmsError,
  getCmsSuccess,
  putCms,
  mergeCmsDialogForm,
  setCmsDialogForm,
  executeCmsAction,
  getAssetTypes,
  getAssetTypesSuccess,
  getAssetTypesError,
} from './actions';

function* handleGetCmsRequest(requestParams: IGetCmsRequestFilterParams) {
  const { data } = yield call(getCmsRequest, requestParams);

  return data;
}

function* handleAssetsByGroupRequest() {
  const { data } = yield call(getAssetTypesRequest);

  return data;
}

function* handleRefreshCms() {
  yield put(getCms(queryString.parse(window.location.search)));
}

function* handlePostCms({ payload }: ReturnType<typeof postCms>) {
  try {
    yield put(mergeCmsDialogForm({
      isLoading: true,
    }));

    yield call(postCmsRequest, payload);
    yield put(setCmsDialogForm());
    yield call(toast.success, 'The cms has been successfully created');
    yield handleRefreshCms();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCmsDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetCms({ payload }: ReturnType<typeof getCms>) {
  try {
    const data: IGetCmsResponseBody = yield handleGetCmsRequest(payload);

    yield put(getCmsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getCmsError());
  }
}

export function* handleAssetsByGroup() {
  try {
    const data: IAssetTypes = yield handleAssetsByGroupRequest();

    yield put(getAssetTypesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getAssetTypesError());
  }
}

function* handlePutCms({ payload }: ReturnType<typeof putCms>) {
  try {
    yield put(mergeCmsDialogForm({
      isLoading: true,
    }));

    yield call(putCmsRequest, payload);
    yield put(setCmsDialogForm());
    yield call(toast.success, `The cms ${payload.id} has been successfully updated`);
    yield handleRefreshCms();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeCmsDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleExecuteCmsAction({ payload }: ReturnType<typeof executeCmsAction>) {
  try {
    const { action, id } = payload;

    if (action === CmsAction.remove) {
      yield call(removeCmsRequest, { id });
      yield call(toast.success, `The cms with id ${id} has been successfully removed.`);
    }
    yield handleRefreshCms();
  } catch (error: any) {
    yield handleError(error);
  }
}

export function* cmsSagas(): any {
  yield all([
    yield takeEvery(
      postCms,
      handlePostCms,
    ),
    yield takeLatest(
      getCms,
      handleGetCms,
    ),
    yield takeEvery(
      putCms,
      handlePutCms,
    ),
    yield takeLatest(
      executeCmsAction,
      handleExecuteCmsAction,
    ),
    yield takeLatest(
      getAssetTypes,
      handleAssetsByGroup,
    ),
  ]);
}
