import { ICommonRequestFiltersParams, PasswordFieldName } from 'arcadia-common-fe';

export enum MonitoringStatus {
  active = 'active',
  suspended = 'suspended'
}

export enum MonitoringAction {
  remove,
}

export enum Segment {
  machine = 'machine',
  group = 'group',
  operator = 'operator',
  brand = 'brand',
  all = 'all',
}

export enum MonitoringMode {
  each = 'each',
  all = 'all',
}

export type MonitoringId = number;

export type ISegmentSubset = Array<string | number> | null | undefined;

interface CurrentValue {
  value: number
  subsegmentItem: string
}

export type CurrentValues = CurrentValue[];

export interface IMonitoring {
  id: MonitoringId
  status: MonitoringStatus
  segment: Segment
  segmentSubset: ISegmentSubset
  mode: MonitoringMode
  currentValue: CurrentValues
  metric: string
  dimension: string
  targetValue: number
  alertLowThreshold: number
  alertHighThreshold: number
  cutoffLowThreshold: number
  cutoffHighThreshold: number
}

export type IMonitoringList = IMonitoring[];

export interface IMonitoringFiltersPanelValues {
  status?: MonitoringStatus
  currentValueTo?: number
  currentValueFrom?: number
  targetValueTo?: number
  targetValueFrom?: number
  dimension?: string
  metric?: string
  mode?: string
  segment?: string
}

export interface IGetMonitoringRequestFilterParams extends ICommonRequestFiltersParams, IMonitoringFiltersPanelValues {
}

export interface IPutMonitoringRequestBody extends Omit<IMonitoring, 'segmentSubset'> {
  segmentSubset: ISegmentSubset | null
}

export interface IRemoveMonitoringRequestBody {
  id: MonitoringId
  [PasswordFieldName.confirmationPassword]: string
}

export interface IPostMonitoringRequestBody extends Omit<IMonitoring, 'segmentSubset'> {
  segmentSubset: ISegmentSubset | null
}

export interface IMonitoringActionRequestBody {
  id: MonitoringId
}

export interface IGetMonitoringResponseBody {
  total: number
  monitoring: IMonitoringList
}

export interface IMonitoringDialogActionState {
  id: MonitoringId | null
  action: MonitoringAction
  isOpen: boolean
  isLoading?: boolean
}

export interface IMonitoringDialogFormState {
  isOpen: boolean
  isLoading?: boolean
  initialValues?: Partial<IMonitoring>
}

export interface IMonitoringReducer extends IGetMonitoringResponseBody {
  readonly isLoading: boolean
  readonly isExporting: boolean
  readonly dialogForm: IMonitoringDialogFormState
  readonly dialogAction: IMonitoringDialogActionState
}

export interface IMonitoringSlice {
  monitoringReducer: IMonitoringReducer
}
