import React from 'react';
import classNames from 'classnames';
import { Button, IButtonProps } from '../Button/Button';
import RefreshIcon from '../../assets/svg/refresh.svg';
import './RefreshButton.scss';

export interface IRefreshButtonProps extends IButtonProps {
}

export const RefreshButton: React.FC<IRefreshButtonProps> = ({ className, ...restProps }) => (
  <Button
    className={classNames(
      'refresh-btn',
      className,
    )}
    {...restProps}
  >
    <RefreshIcon className="refresh-btn__icon" />
  </Button>
);
