import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types';

export interface IExitStyleProps extends IDeviceInfoContext {
}

export interface IExitStyles {
  content: IStyle;
}

export const getStyles = ({ isLandscape }: IExitStyleProps): IExitStyles => ({
  content: {
    width: isLandscape ? '50%' : '100%',
    fontSize: isLandscape ? '4rem' : '9rem',
  },
});
