import { ApplicationStorage } from './storage';
import { SessionStorage } from './sessionStorage';
import { CookieStorage } from './cookieStorage';
import { MemoryStorage } from './memoryStorage';
import { isCookiesAvailable, isSessionStorageAvailable } from './utils';

const initStorage = (): ApplicationStorage => {
  if (isSessionStorageAvailable()) {
    return new ApplicationStorage(new SessionStorage());
  }

  if (isCookiesAvailable()) {
    return new ApplicationStorage(new CookieStorage());
  }

  return new ApplicationStorage(new MemoryStorage());
};

export const AppStorage = initStorage();
