import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  IGetMonitoringRequestFilterParams,
  IGetMonitoringResponseBody,
  MonitoringAction,
} from '../types';
import {
  getMonitoringRequest,
  removeMonitoringRequest,
  postMonitoringRequest,
  putMonitoringRequest,
} from '../api';
import {
  getMonitoring,
  getMonitoringError,
  getMonitoringSuccess,
  exportMonitoring,
  exportMonitoringError,
  exportMonitoringSuccess,
  executeMonitoringAction,
  mergeMonitoringDialogAction,
  setMonitoringDialogAction,
  postMonitoring,
  putMonitoring,
  mergeMonitoringDialogForm,
  setMonitoringDialogForm,
} from './actions';
import { monitoringStatusLabelMap } from '../constants';

function* handleRefreshList() {
  yield put(getMonitoring(queryString.parse(window.location.search)));
}

function* handleGetMonitoringRequest(requestParams: IGetMonitoringRequestFilterParams) {
  const { data } = yield call(getMonitoringRequest, requestParams);

  return data;
}

function* handlePostMonitoring({ payload }: ReturnType<typeof postMonitoring>) {
  try {
    yield put(mergeMonitoringDialogForm({
      isLoading: true,
    }));
    yield call(postMonitoringRequest, payload);
    yield call(toast.success, 'The Monitoring has been successfully added');
    yield put(setMonitoringDialogForm());
    yield handleRefreshList();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeMonitoringDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetMonitoring({ payload }: ReturnType<typeof getMonitoring>) {
  try {
    const MonitoringData: IGetMonitoringResponseBody = yield handleGetMonitoringRequest(payload);

    yield put(getMonitoringSuccess(MonitoringData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMonitoringError());
  }
}

function* handlePutMonitoring({ payload }: ReturnType<typeof putMonitoring>) {
  try {
    yield put(mergeMonitoringDialogForm({
      isLoading: true,
    }));
    yield call(putMonitoringRequest, payload);
    yield call(
      toast.success,
      `The Monitoring with id - ${payload.id} has been successfully changed`,
    );
    yield put(setMonitoringDialogForm());
    yield handleRefreshList();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeMonitoringDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleExecuteMonitoringAction({ payload }: ReturnType<typeof executeMonitoringAction>) {
  try {
    const { action, id, confirmationPassword } = payload;

    if (action === MonitoringAction.remove) {
      yield call(removeMonitoringRequest, { id, confirmationPassword });
      yield call(toast.success, `The monitoring with id ${id} has been successfully removed.`);
    }
    yield put(setMonitoringDialogAction());
    yield handleRefreshList();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeMonitoringDialogAction({ isLoading: false }));
  }
}

function* handleExportMonitoring({ payload }: ReturnType<typeof exportMonitoring>): any {
  try {
    const { monitoring }: IGetMonitoringResponseBody = yield handleGetMonitoringRequest(payload);

    const preparedData = monitoring.map((item) => ({
      Status: monitoringStatusLabelMap[item.status],
      Segment: item.segment,
      'Segment Subset': item.segmentSubset?.join(', '),
      Mode: item.mode,
      Metric: item.metric,
      Dimension: item.dimension,
      'Target Value': item.targetValue,
      'Current Value': item.currentValue.map(({ value, subsegmentItem }) => `${value}, ${subsegmentItem}`).join(', '),
      'Alert Low Threshold': item.alertLowThreshold,
      'Alert High Threshold': item.alertHighThreshold,
      'Cutoff Low Threshold': item.cutoffLowThreshold,
      'Cutoff High Threshold': item.cutoffHighThreshold,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Monitoring.csv');
    yield put(exportMonitoringSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportMonitoringError());
  }
}

export function* monitoringSagas(): any {
  yield all([
    yield takeLatest(
      postMonitoring,
      handlePostMonitoring,
    ),
    yield takeLatest(
      getMonitoring,
      handleGetMonitoring,
    ),
    yield takeLatest(
      putMonitoring,
      handlePutMonitoring,
    ),
    yield takeLatest(
      executeMonitoringAction,
      handleExecuteMonitoringAction,
    ),
    yield takeLatest(
      exportMonitoring,
      handleExportMonitoring,
    ),
  ]);
}
