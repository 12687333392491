import {
  IGetSessionLogsRequestBody,
  IGetSessionLogsResponseBody,
  IGetSessionsRequestFiltersParams,
  IGetSessionsResponseBody,
  ITerminateSessionRequestBody,
  IGetVideoRecordingRequestBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

export const getSessionsRequest = (filterParams: IGetSessionsRequestFiltersParams, isAutoRefresh?: boolean) => {
  const url = getUrl('/sessions', filterParams);

  return makeRequest<IGetSessionsResponseBody>(url, {}, 'GET', {}, isAutoRefresh);
};

export const getLogsRequest = ({ session, filterParams }: IGetSessionLogsRequestBody) => {
  const url = getUrl(`/sessions/${session.id}/eventLogs`, filterParams);

  return makeRequest<IGetSessionLogsResponseBody>(url);
};

export const terminateSessionRequest = ({ id }: ITerminateSessionRequestBody) => {
  const url = getUrl(`/sessions/${id}/terminate`);

  return makeRequest(url, undefined, 'POST');
};

export const getVideoRecordingRequest = ({ id, offsetSec, cameraId }: IGetVideoRecordingRequestBody) => {
  const url = getUrl(`/sessions/${id}/videoRecord/${cameraId}?offsetSec=${offsetSec}`);

  return makeRequest<Blob>(url, undefined, 'GET', { responseType: 'blob' });
};
