import { createAction } from '@reduxjs/toolkit';
import {
  IGetFunnelRequestFiltersParams,
  IGetFunnelProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getFunnelReport = createAction<IGetFunnelRequestFiltersParams>('REPORT/GET_FUNNEL');
export const getFunnelReportError = createAction('REPORT/GET_FUNNEL/ERROR');

export const getProcessedFunnelReport = createAction<
  IGetFunnelProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_FUNNEL_REPORT/SUCCESS');
export const setProcessedFunnelReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_FUNNEL_REPORTS/SUCCESS');

export const exportFunnelReport = createAction<IGetFunnelRequestFiltersParams>('REPORT/EXPORT_FUNNEL');
export const exportFunnelReportSuccess = createAction('REPORT/EXPORT_FUNNEL/SUCCESS');
export const exportFunnelReportError = createAction('REPORT/EXPORT_FUNNEL/ERROR');
