import {
  IGetGroupsResponseBody,
  IGetOperatorsResponseBody,
  IGetLocationsResponseBody,
  IGetSitesResponseBody,
  IGetMachinesResponseBody,
  IGetDenominatorValuesResponseBody,
  IGetBlockingReasonsResponseBody,
  IGetChipTypesResponseBody,
  IGetMonitoringMetricsResponseBody,
  IGetMonitoringDimensionsResponseBody,
  IGetRebateCurrenciesResponseBody,
  IGetPrizeGroupsResponseBody,
  IGetPowerLinesResponseBody,
  IGetCamerasResponseBody,
  IRequestQueryParams,
  IGetBrandsResponseBody,
  IGetAPIConnectorResponseBody,
  IGetGameVariationsResponseBody,
  IGetGroupTypesResponseBody,
  IGetSessionEndReasonResponseBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

const createAPIRequest = <TResponseBody>(path: string) => (requestQueryParams?: IRequestQueryParams, isAutoRefresh: boolean = false) => {
  const url = getUrl(path, requestQueryParams);

  return makeRequest<TResponseBody>(url, {}, 'GET', {}, isAutoRefresh);
};

export const getGroupsRequest = createAPIRequest<IGetGroupsResponseBody>('/groups/names');

export const getSessionsRequest = createAPIRequest<IGetSessionEndReasonResponseBody>('/sessions/sessionEndReason');

export const getOperatorsRequest = createAPIRequest<IGetOperatorsResponseBody>('/operators/names');

export const getMachinesRequest = createAPIRequest<IGetMachinesResponseBody>('/machines/names');

export const getLocationsRequest = createAPIRequest<IGetLocationsResponseBody>('/cameras/locations');

export const getMachinesSitesRequest = createAPIRequest<IGetSitesResponseBody>('/machines/sites');

export const getCamerasSitesRequest = createAPIRequest<IGetSitesResponseBody>('/cameras/sites');

export const getCamerasRequest = createAPIRequest<IGetCamerasResponseBody>('/cameras');

export const getBlockingReasonsRequest = createAPIRequest<IGetBlockingReasonsResponseBody>('/players/blockReasons');

export const getDenominatorValuesRequest = createAPIRequest<IGetDenominatorValuesResponseBody>('/groups/denominator-values');

export const getChipTypesRequest = createAPIRequest<IGetChipTypesResponseBody>('/administration/chip/types');

export const getMonitoringMetricsRequest = createAPIRequest<IGetMonitoringMetricsResponseBody>('/monitorings/metrics');

export const getMonitoringDimensionsRequest = createAPIRequest<IGetMonitoringDimensionsResponseBody>('/monitorings/dimensions');

export const getRebateCurrenciesRequest = createAPIRequest<IGetRebateCurrenciesResponseBody>('/disputes/rebateCurrencies');

export const getPrizeGroupsRequest = createAPIRequest<IGetPrizeGroupsResponseBody>('/groups/prize-groups');

export const getPowerLinesRequest = createAPIRequest<IGetPowerLinesResponseBody>('/machines/power-lines');

export const getBrandsRequest = createAPIRequest<IGetBrandsResponseBody>('/brands/names');

export const getAPIConnectorRequest = createAPIRequest<IGetAPIConnectorResponseBody>('/operators/api-connectors');

export const getGameVariationsRequest = createAPIRequest<IGetGameVariationsResponseBody>('/groups/game-variations');

export const getGroupTypesRequest = createAPIRequest<IGetGroupTypesResponseBody>('/groups/group-types');
