import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { ExpandBtn } from 'arcadia-common-fe';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePrevious } from 'arcadia-common-client';
import { ROUTES_MAP } from '../../../../../routing/constants';
import { modulesInitialFilters } from '../../../../../constants';
import { authReducerSelector } from '../../../../auth/selectors';
import { SessionMode } from '../../../../../types/sessions';
import './styles/NavBar.scss';

const items = [
  ROUTES_MAP.dashboard,
  {
    ...ROUTES_MAP.sessions,
    path: ROUTES_MAP.sessions.createURL(modulesInitialFilters.sessions[SessionMode.active]),
  },
  {
    ...ROUTES_MAP.machines,
    path: ROUTES_MAP.machines.createURL(modulesInitialFilters.machines),
  },
  {
    ...ROUTES_MAP.groups,
    path: ROUTES_MAP.groups.createURL(modulesInitialFilters.groups),
  },
  ROUTES_MAP.cameras,
  {
    ...ROUTES_MAP.alerts,
    path: ROUTES_MAP.alerts.createURL(modulesInitialFilters.alerts),
  },
  ROUTES_MAP.maintenance,
  {
    ...ROUTES_MAP.brands,
    path: ROUTES_MAP.brands.createURL(modulesInitialFilters.brands),
  },
  {
    ...ROUTES_MAP.operators,
    path: ROUTES_MAP.operators.createURL(modulesInitialFilters.operators),
  },
  {
    ...ROUTES_MAP.credentials,
    path: ROUTES_MAP.credentials.createURL(modulesInitialFilters.credentials),
  },
  {
    ...ROUTES_MAP.monitoring,
    path: ROUTES_MAP.monitoring.createURL(modulesInitialFilters.monitoring),
  },
  {
    ...ROUTES_MAP.currency,
    path: ROUTES_MAP.currency.createURL(modulesInitialFilters.currency),
  },
  {
    ...ROUTES_MAP.cms,
    path: ROUTES_MAP.cms.createURL(modulesInitialFilters.cms),
  },
  {
    ...ROUTES_MAP.transactions,
    path: ROUTES_MAP.transactions.createURL(modulesInitialFilters.transactions),
  },
  {
    ...ROUTES_MAP.players,
    path: ROUTES_MAP.players.createURL(modulesInitialFilters.players),
  },
  ROUTES_MAP.sites,
  {
    ...ROUTES_MAP.administration,
    path: ROUTES_MAP.administration.createURL(modulesInitialFilters.administration),
  },
  ROUTES_MAP.reports,
  {
    ...ROUTES_MAP.vouchers,
    path: ROUTES_MAP.vouchers.createURL(modulesInitialFilters.vouchers),
  },
  {
    ...ROUTES_MAP.disputes,
    path: ROUTES_MAP.disputes.createURL(modulesInitialFilters.disputes),
  },
];

const SCROLL_STEP = 150;

export const NavBar = () => {
  const divRef = useRef<HTMLDivElement>(null);

  const [slideLeft, setSlideLeft] = useState(0);
  const [tabs, setTabs] = useState<Array<any>>([]);

  const previousTabsLength = usePrevious(tabs.length) || -1;
  const { userPermissions } = useSelector(authReducerSelector);

  const clickOnLeftArrow = useCallback(() => {
    if (divRef.current && slideLeft >= 0) {
      setSlideLeft(divRef.current.scrollLeft -= SCROLL_STEP);
    }
  }, [divRef.current]);

  const clickOnRightArrow = useCallback(() => {
    if (divRef.current) {
      setSlideLeft(divRef.current.scrollLeft += SCROLL_STEP);
    }
  }, [divRef.current]);

  const isShowArrowRight = useMemo(() => {
    const isScrollWidth = divRef.current && divRef.current?.scrollWidth > divRef.current?.clientWidth;

    if (slideLeft >= SCROLL_STEP) {
      return isScrollWidth && divRef.current.scrollWidth >= divRef.current.clientWidth + slideLeft;
    }

    return isScrollWidth;
  }, [divRef.current?.scrollWidth, divRef.current?.clientWidth, slideLeft]);

  //TODO: Need to rework this solution without useState and useEffect START
  useEffect(() => {
    if (userPermissions.length && tabs.length !== previousTabsLength) {
      const allowedToShowTabs = items.map((item) => {
        const isAllowedToShow = userPermissions?.includes(item.permissionName);

        if (isAllowedToShow) {
          return item;
        }

        return null;
      }).filter((item) => item !== null);

      setTabs(allowedToShowTabs);
    }
  }, [tabs, items, userPermissions]);
  //TODO: Need to rework this solution without useState and useEffect END

  return (
    <div className="nav-bar-wrapper">
      {slideLeft > 0 && (
        <ExpandBtn className="nav-bar__arrow nav-bar__arrow--left" onClick={clickOnLeftArrow} isActive />
      )}
      <div className="nav-bar" ref={divRef}>
        {
          tabs.map(({ path, title, exact }) => (
            <NavLink
              key={path + title}
              className="nav-bar__item"
              activeClassName="nav-bar__item--active"
              exact={exact}
              to={path}
            >
              <div className="nav-bar__item-content">
                {title}
              </div>
            </NavLink>
          ))
        }
      </div>
      {isShowArrowRight && (
        <ExpandBtn className="nav-bar__arrow nav-bar__arrow--right" onClick={clickOnRightArrow} isActive />
      )}
    </div>
  );
};
