import {
  all, call, put, takeEvery,
} from 'redux-saga/effects';
import { normalize, schema } from 'normalizr';
import _uniqBy from 'lodash.uniqby';
import { ISelectOptions } from 'arcadia-common-fe';
import {
  getEntityData, getEntityDataSuccess, getEntityDataError,
} from './actions';
import { handleError } from '../../services/sagasErrorHandler';
import {
  EntityType,
  IGroup,
  IOperator,
  IGetEntityRequestBody,
  IChip,
  IDimension,
  IPowerLine,
  IOptions,
  IRequestQueryParams,
  ISite,
  IMachine,
  IBrandData,
  IApiConnector,
  IGetGameVariation,
  IGetGroupType,
  ISessionEndReason,
} from './types';
import {
  getDenominatorValuesRequest,
  getBlockingReasonsRequest,
  getGroupsRequest,
  getLocationsRequest,
  getMachinesRequest,
  getOperatorsRequest,
  getMachinesSitesRequest,
  getChipTypesRequest,
  getMonitoringMetricsRequest,
  getMonitoringDimensionsRequest,
  getRebateCurrenciesRequest,
  getCamerasSitesRequest,
  getPrizeGroupsRequest,
  getPowerLinesRequest,
  getCamerasRequest,
  getBrandsRequest,
  getAPIConnectorRequest,
  getGameVariationsRequest,
  getGroupTypesRequest,
  getSessionsRequest,
} from './api';
import { ICamera } from '../cameras/types';
import { IBrand } from '../../types/brand';
import { IBrandSecret } from '../brands/types';
import { getCredentialsRequest } from '../credentials/api';

const optionSchema = new schema.Entity('options', {}, { idAttribute: 'value' });
const optionsSchema = new schema.Array(optionSchema);

const getEntitiesByTypeRequestsMap: {[key in EntityType]: (requestQueryParams?: IRequestQueryParams, isAutoRefresh?: boolean, disableDuplicateRequest?: boolean) => any} = {
  * [EntityType.groupName](requestQueryParams) {
    const { data } = yield call(getGroupsRequest, requestQueryParams);

    return data.groups.map(({ name }: IGroup) => ({ value: name, label: name })) || [];
  },
  * [EntityType.sessionEndReason](requestQueryParams) {
    const { data } = yield call(getSessionsRequest, requestQueryParams);

    return data.sessionEndReasons.map(({ sessionEndReason, displaySessionEndReason }: ISessionEndReason) => ({ value: sessionEndReason, label: displaySessionEndReason }));
  },
  * [EntityType.groupId](requestQueryParams, isAutoRefresh) {
    let resultData;

    if (requestQueryParams?.gameId) {
      const { data } = yield call(getGroupsRequest, { gameId: requestQueryParams.gameId }, isAutoRefresh);

      resultData = data;
    } else {
      const { data } = yield call(getGroupsRequest, {}, isAutoRefresh);

      resultData = data;
    }

    return resultData.groups.map(({ name, id }: IGroup) => ({ value: id, label: name })) || [];
  },
  * [EntityType.denominator](requestQueryParams) {
    const { data } = yield call(getDenominatorValuesRequest, requestQueryParams);

    return data.denominators.map((denominator: string) => ({ value: denominator, label: denominator })) || [];
  },
  * [EntityType.operatorName](requestQueryParams) {
    const { data } = yield call(getOperatorsRequest, requestQueryParams);

    return data.operators.map(({ name }: IOperator) => ({ value: name, label: name }));
  },
  * [EntityType.brandSecretsId](requestQueryParams) {
    if (requestQueryParams?.operatorId) {
      const { data } = yield call(getCredentialsRequest, requestQueryParams);

      return data.brandSecrets
        .filter(({ operatorId }: IBrand) => operatorId === requestQueryParams.operatorId)
        .map(({ id, displayName }: IBrandSecret) => ({ value: id, label: displayName }));
    }

    return [];
  },
  * [EntityType.operatorId](requestQueryParams) {
    const { data } = yield call(getOperatorsRequest, requestQueryParams);

    return data.operators.map(({ name, id }: IOperator) => ({ value: id, label: name }));
  },
  * [EntityType.machineName](requestQueryParams) {
    const { data } = yield call(getMachinesRequest, requestQueryParams);

    return data.machines.map(({ name }: IMachine) => ({ value: name, label: name }));
  },
  * [EntityType.machineSerial](requestQueryParams) {
    const { data } = yield call(getMachinesRequest, requestQueryParams);

    return data.machines.map(({ name, machineSerial }: IMachine) => ({ value: machineSerial, label: name }));
  },
  * [EntityType.location](requestQueryParams) {
    const { data } = yield call(getLocationsRequest, requestQueryParams);

    return data.locations.map((location: string) => ({ value: location, label: location }));
  },
  * [EntityType.siteName](requestQueryParams) {
    const { data } = yield call(getMachinesSitesRequest, requestQueryParams);

    return data.sites.map(({ name }: IMachine) => ({ value: name, label: name }));
  },
  * [EntityType.camerasSiteName](requestQueryParams) {
    const { data } = yield call(getCamerasSitesRequest, requestQueryParams);

    return data.sites.map(({ name }: ISite) => ({ value: name, label: name }));
  },
  * [EntityType.freeCameraId](requestQueryParams, _isAutoRefresh, disableDuplicateRequest) {
    if (requestQueryParams?.site && !disableDuplicateRequest) {
      const { data } = yield call(getCamerasRequest, { site: requestQueryParams.site });
      return data.cameras
        .filter(({ machine }: ICamera) => machine === null || machine === requestQueryParams.selectedMachineName)
        .map(({ id }: ICamera) => ({ value: id, label: id }));
    }

    return [];
  },
  * [EntityType.siteId](requestQueryParams) {
    const { data } = yield call(getMachinesSitesRequest, requestQueryParams);

    return data.sites.map(({ name, id }: IMachine) => ({ value: id, label: name }));
  },
  * [EntityType.blockingReason](requestQueryParams) {
    const { data } = yield call(getBlockingReasonsRequest, requestQueryParams);

    return data.blockReasons.map((reason: string) => ({ value: reason, label: reason }));
  },
  * [EntityType.chipType](requestQueryParams) {
    const { data } = yield call(getChipTypesRequest, requestQueryParams);

    return data.chipTypes.map(({ id, name }: IChip) => ({ value: id, label: name }));
  },
  * [EntityType.monitoringMetric](requestQueryParams) {
    const { data } = yield call(getMonitoringMetricsRequest, requestQueryParams);

    return data.metric.map((metric: string) => ({ value: metric, label: metric }));
  },
  * [EntityType.monitoringDimension](requestQueryParams) {
    const { data } = yield call(getMonitoringDimensionsRequest, requestQueryParams);

    return data.dimension.map(({ name }: IDimension) => ({ value: name, label: name }));
  },
  * [EntityType.rebateCurrency](requestQueryParams) {
    const { data } = yield call(getRebateCurrenciesRequest, requestQueryParams);

    return data.map((currency: string) => ({ value: currency, label: currency }));
  },
  * [EntityType.prizeGroup](requestQueryParams) {
    const { data } = yield call(getPrizeGroupsRequest, requestQueryParams);

    return data.groups.map((group: string) => ({ value: group, label: group }));
  },
  * [EntityType.powerLines](requestQueryParams) {
    const { data } = yield call(getPowerLinesRequest, requestQueryParams);

    return data.powerLines.map(({ name }: IPowerLine) => ({ value: name, label: name }));
  },
  * [EntityType.brandName](requestQueryParams) {
    const { data } = yield call(getBrandsRequest, requestQueryParams);

    return data.brands.map(({ name }: IBrandData) => ({ value: name, label: name }));
  },
  * [EntityType.brandId](requestQueryParams) {
    const { data } = yield call(getBrandsRequest, requestQueryParams);

    return data.brands.map(({ id, name }: IBrandData) => ({ value: id, label: name }));
  },
  * [EntityType.apiConnectorId](requestQueryParams) {
    const { data } = yield call(getAPIConnectorRequest, requestQueryParams);

    return data.apiConnectors.map(({ apiConnectorId }: IApiConnector) => ({ value: apiConnectorId, label: apiConnectorId }));
  },
  * [EntityType.gameVariation](requestQueryParams) {
    const { data } = yield call(getGameVariationsRequest, requestQueryParams);

    return data.gameVariations.map(({ gameVariation }: IGetGameVariation) => ({ value: gameVariation, label: gameVariation }));
  },
  * [EntityType.groupType](requestQueryParams) {
    const { data } = yield call(getGroupTypesRequest, requestQueryParams);

    return data.groupTypes.map(({ groupType }: IGetGroupType) => ({ value: groupType, label: groupType }));
  },
};

function* handleGetEntityDataByType(payload: IGetEntityRequestBody): any {
  const { requestQueryParams, isAutoRefresh, disableDuplicateRequest } = payload;

  return yield call(getEntitiesByTypeRequestsMap[payload.entityType], requestQueryParams, isAutoRefresh, disableDuplicateRequest);
}

export function* handleGetEntityData({ payload }: ReturnType<typeof getEntityData>) {
  try {
    const result: ISelectOptions = yield call(handleGetEntityDataByType, payload);

    const uniqueOptions = _uniqBy(result, ((option) => option.value));
    const { result: ids, entities } = normalize<IOptions>(uniqueOptions, optionsSchema);

    yield put(getEntityDataSuccess({ entityType: payload.entityType, ids, entities: entities.options }));
  } catch (error: any) {
    yield put(getEntityDataError({ entityType: payload.entityType }));
    yield handleError(error);
  }
}

export function* selectEntityByTypeSagas(): any {
  yield all([
    yield takeEvery(
      getEntityData,
      handleGetEntityData,
    ),
  ]);
}
