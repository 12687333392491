import { createAction } from '@reduxjs/toolkit';
import {
  IGetMachineRequestFiltersParams,
  IGetMachineStatusProcessedReportsFilterParams,
} from '../types';
import { IGetProcessedReportsResponseBody } from '../../../types';

export const getMachineStatusReport = createAction<IGetMachineRequestFiltersParams>('REPORT/GET_MACHINE_STATUS');
export const getMachineStatusReportError = createAction('REPORT/GET_MACHINE_STATUS/ERROR');

export const getProcessedMachineStatusReport = createAction<
  IGetMachineStatusProcessedReportsFilterParams
  >('REPORT/GET_PROCESSED_MACHINE_STATUS_REPORT/SUCCESS');
export const setProcessedMachineStatusReportsSuccess = createAction<
  IGetProcessedReportsResponseBody
  >('REPORTS/SET_PROCESSED_MACHINE_STATUS_REPORTS/SUCCESS');

export const exportMachineStatusReport = createAction<IGetMachineRequestFiltersParams>('REPORT/EXPORT_MACHINE_STATUS');
export const exportMachineStatusReportSuccess = createAction('REPORT/EXPORT_MACHINE_STATUS/SUCCESS');
export const exportMachineStatusReportError = createAction('REPORT/EXPORT_MACHINE_STATUS/ERROR');
