import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getActivityReport,
  getActivityReportError,
  exportActivityReport,
  exportActivityReportError,
  exportActivityReportSuccess,
  getProcessedActivityReport,
  setProcessedActivityReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getActivityProcessedReportRequest, getActivityReportRequest } from '../api';
import {
  IActivityReportItem,
  IGetActivityReportResponseBody,
  IGetActivityRequestFiltersParams,
} from '../types';
import { activityReportReducerSelector } from './selectors';

function* handleGetActivityReportRequest(requestParams: IGetActivityRequestFiltersParams) {
  const { data } = yield call(getActivityReportRequest, requestParams);

  return data;
}

function* handleGetActivityProcessedReport({ payload }: ReturnType<typeof getProcessedActivityReport>) {
  try {
    const { data } = yield call(getActivityProcessedReportRequest, payload);

    yield put(setProcessedActivityReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getActivityReportError());
  }
}

function* handleGetActivityReport({ payload }: ReturnType<typeof getActivityReport>) {
  try {
    const activityReportData: IGetActivityReportResponseBody = yield handleGetActivityReportRequest(payload);
    const { info } = activityReportData;
    const { filterParams } = yield select(activityReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedActivityReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getActivityReportError());
  }
}

function* handleExportActivityReport({ payload }: ReturnType<typeof exportActivityReport>): any {
  try {
    const { data }: IGetActivityReportResponseBody = yield handleGetActivityReportRequest(payload);

    const preparedData = data.map((item: IActivityReportItem) => ({
      'Grouping Value': item.groupingValue,
      '# Players': item.totalUniquePlayers,
      '# New Players': item.totalNewPlayers,
      '# Sessions': item.totalUniqueSessions,
      'Total Session Time': item.totalSessionTime,
      'Avg Session Time': item.avgSessionTime,
      Rounds: item.totalRoundsPlayed,
      'Avg Rounds Per Session': item.avgRoundsPerSession,
      Bets: item.totalBets,
      Wins: item.totalWins,
      'Bets Behind': item.totalBehindBets,
      'Wins Behind': item.totalBehindWins,
      'Voucher Bets': item.totalVoucherBets,
      'Voucher Wins': item.totalVoucherWins,
      Refunds: item.totalRefunds,
      'Gross Gaming': item.totalGrossGaming,
      'Net Gaming': item.totalNetGaming,
      'Watch Time': item.totalWatchTime,
      'Avg Watch Time': item.avgWatchTime,
      'Max Watch Time': item.maxWatchTime,
      'Queue Time': item.totalQueueTime,
      'Avg Queue Time': item.avgQueueTime,
      'Max Queue Time': item.maxQueueTime,
      'In Play Time': item.totalInPlayTime,
      'Average In Play Time': item.avgInPlayTime,
      'Max In Play Time': item.maxInPlayTime,
      'Autoplay Bets': item.totalAutoplayBets,
      'Autoplay Wins': item.totalAutoplayWins,
      'Autoplay % Of Sessions': item.percentAutoplaySessions,
      '# Sessions Watch': item.totalSessionsWatch,
      'Sessions Watch %': item.percentSessionsWatch,
      '# Sessions Queue': item.totalSessionsQueue,
      'Sessions Queue %': item.percentSessionsQueue,
      '# Sessions Behind': item.totalSessionsBehind,
      'Sessions Behind %': item.percentSessionsBehind,
      '# Sessions In Play': item.totalSessionsInPlay,
      'Sessions In Play %': item.percentSessionsInPlay,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Activity Report.csv');
    yield put(exportActivityReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportActivityReportError());
  }
}

export function* activityReportSagas(): any {
  yield all([
    yield takeLatest(
      getActivityReport,
      handleGetActivityReport,
    ),
    yield takeLatest(
      getProcessedActivityReport,
      handleGetActivityProcessedReport,
    ),
    yield takeLatest(
      exportActivityReport,
      handleExportActivityReport,
    ),
  ]);
}
