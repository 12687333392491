import {
  ISignInRequestBody,
  ISignInResponseBody,
  IGetPermissionsResponseBody,
  IGetUserPermissionsResponseBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

export const signInRequest = (data: ISignInRequestBody) => {
  const url = getUrl('/auth/login');

  return makeRequest<ISignInResponseBody>(url, data, 'POST');
};

export const getPermissionsRequest = async () => {
  const url = getUrl('/auth/bo-modules');
  const { data } = await makeRequest<IGetPermissionsResponseBody>(url);

  return data;
};

export const getUserPermissionsRequest = async () => {
  const url = getUrl('/auth/permitted-modules');
  const { data } = await makeRequest<IGetUserPermissionsResponseBody>(url);

  return data;
};
