import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import {
  formatSecondsToMinutesSeconds,
  getDateTimeFormatted,
  formatNumber,
  saveStringAsFile,
  convertDataToJSON,
  saveVideoFile,
} from 'arcadia-common-fe';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  getSessions,
  getSessionsSuccess,
  getSessionsError,
  exportSessions,
  exportSessionsSuccess,
  exportSessionsError,
  getLogs,
  getLogsSuccess,
  getLogsError,
  terminateSession,
  terminateSessionError,
  terminateSessionSuccess,
  exportSessionLogs,
  exportSessionLogsError,
  exportSessionLogsSuccess,
  getVideoRecording,
  mergeGetVideoRecordingDialog,
  setGetVideoRecordingDialog,
} from './actions';
import {
  getSessionsRequest,
  getLogsRequest,
  terminateSessionRequest,
  getVideoRecordingRequest,
} from '../api';
import { IGetSessionsRequestFiltersParams, IGetSessionsResponseBody, ISessionLogItem } from '../types';
import { sessionLogSourceLabelMap, sessionsLabelMap } from '../constants';
import { SessionMode } from '../../../types/sessions';

const getQueryParams = () => queryString.parse(window.location.search) as unknown as IGetSessionsRequestFiltersParams;

function* handleRefreshSessions(): any {
  const queryParams = yield getQueryParams();

  yield put(getSessions(queryParams));
}

function* handleGetSessionsRequest(requestParams: IGetSessionsRequestFiltersParams) {
  const { isAutoRefresh, ...restRequestParams } = requestParams;
  const { data } = yield call(getSessionsRequest, restRequestParams, isAutoRefresh);

  return data;
}

function* handleGetSessions({ payload }: ReturnType<typeof getSessions>) {
  try {
    const sessionsData: IGetSessionsResponseBody = yield handleGetSessionsRequest(payload);

    yield put(getSessionsSuccess(sessionsData));
  } catch (error: any) {
    yield handleError(error);
    yield put(getSessionsError());
  }
}

function* handleGetVideoRecording({ payload }: ReturnType<typeof getVideoRecording>) {
  try {
    yield put(mergeGetVideoRecordingDialog({ isLoading: true }));

    const { data } = yield call(getVideoRecordingRequest, payload);

    yield call(
      saveVideoFile,
      data,
      `session-${payload.id}.mp4`,
    );
    yield put(setGetVideoRecordingDialog());
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeGetVideoRecordingDialog({ isLoading: false }));
  }
}

function* handleExportSessions({ payload }: ReturnType<typeof exportSessions>): any {
  try {
    const { sessions }: IGetSessionsResponseBody = yield handleGetSessionsRequest(payload);
    const queryParams = yield getQueryParams();

    const preparedData = sessions.map((session) => ({
      Status: sessionsLabelMap[session.status],
      'Session ID': session.id,
      'Group Name': session.groupName,
      'Machine Name': session.machineName,
      'Operator Name': session.operatorName,
      'Player CID': session.playerCid,
      'IP Address': session.ip,
      'Start Date & Time': getDateTimeFormatted(session.startDate),
      Duration: session.duration,
      ...queryParams?.mode === SessionMode.active && { Rounds: session.rounds },
      'Total Winning': formatNumber(session.totalWinning),
      'Total Netgaming': formatNumber(session.totalNetCash),
      'Viewer Duration': formatSecondsToMinutesSeconds(session.viewerDuration),
      'Queue Duration': formatSecondsToMinutesSeconds(session.queueDuration),
      'Total Bets': session.totalBets,
      'Total Stacks Used': session.totalStacksUsed,
      Currency: session.currency,
      'Game Client Version': session.clientVersion,
      'OS (and version)': session.os,
      'Device Type': session.deviceType,
      Browser: session.browser,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Sessions.csv');
    yield put(exportSessionsSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportSessionsError());
  }
}

function* handleGetLogs({ payload }: ReturnType<typeof getLogs>) {
  try {
    const { data } = yield call(getLogsRequest, payload);

    yield put(getLogsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getLogsError());
  }
}

function* handleExportSessionLogs({ payload }: ReturnType<typeof exportSessionLogs>): any {
  try {
    const { data } = yield getLogsRequest(payload);

    const preparedData = data.logs.map((row: ISessionLogItem) => ({
      Player: payload.session.playerCid,
      Group: payload.session.groupName,
      Start: getDateTimeFormatted(payload.session.startDate),
      'Created Date': getDateTimeFormatted(row.createdDate),
      Source: sessionLogSourceLabelMap[row.source],
      Type: row.type,
      Parameters: convertDataToJSON(row.parameters),
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, `Arcadia_Session_${payload.session.id}.csv`);
    yield put(exportSessionLogsSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportSessionLogsError());
  }
}

function* handleTerminateSession({ payload }: ReturnType<typeof terminateSession>) {
  try {
    yield call(terminateSessionRequest, payload);

    yield call(toast.success, `The session with id ${payload.id} has been terminated`);
    yield put(terminateSessionSuccess());
    yield handleRefreshSessions();
  } catch (error: any) {
    yield handleError(error);
    yield put(terminateSessionError());
  }
}

export function* sessionsSagas(): any {
  yield all([
    yield takeLatest(
      getSessions,
      handleGetSessions,
    ),
    yield takeLatest(
      exportSessions,
      handleExportSessions,
    ),
    yield takeLatest(
      getLogs,
      handleGetLogs,
    ),
    yield takeLatest(
      exportSessionLogs,
      handleExportSessionLogs,
    ),
    yield takeLatest(
      terminateSession,
      handleTerminateSession,
    ),
    yield takeLatest(
      getVideoRecording,
      handleGetVideoRecording,
    ),
  ]);
}
