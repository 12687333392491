import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetPlayerBlocksReportResponseBody,
  IGetPlayerBlocksRequestFiltersParams,
  IGetPlayerBlocksProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getPlayerBlocksProcessedReportRequest = ({ filterParams }: IGetPlayerBlocksProcessedReportsFilterParams) => {
  const url = getUrl('/report/player-blocks/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getPlayerBlocksReportRequest = (filterParams: IGetPlayerBlocksRequestFiltersParams) => {
  const url = getUrl('/report/player-blocks', filterParams);

  return makeRequest<IGetPlayerBlocksReportResponseBody>(url);
};
