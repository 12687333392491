import { createReducer } from '@reduxjs/toolkit';
import {
  getCredentials,
  getCredentialsError,
  getCredentialsSuccess,
  setCredentialDialogForm,
  mergeCredentialDialogForm,
  setCredentialDialogAction,
  mergeCredentialDialogAction,
  setCredentials,
  clearCredentialsAction,
  getBrandSecretsNamesSuccess,
} from './actions';
import { ICredentialsReducer, CredentialAction } from '../types';

export const initialState: ICredentialsReducer = {
  isLoading: false,
  total: 0,
  brandSecrets: [],
  brandSecretsNames: [],
  credentials: {},
  dialogAction: {
    id: null,
    action: CredentialAction.remove,
    isOpen: false,
    isLoading: false,
  },
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
};

export const credentialsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getCredentials, (state) => ({
    ...state,
    total: 0,
    credentials: {},
    brandSecrets: [],
    isLoading: true,
  }));
  builder.addCase(setCredentials, (state, { payload }) => ({
    ...state,
    credentials: {
      ...state.credentials,
      ...payload,
    },
  }));
  builder.addCase(clearCredentialsAction, (state, { payload }) => ({
    ...state,
    credentials: {
      ...state.credentials,
      [payload.secretName]: undefined,
    },
  }));
  builder.addCase(getCredentialsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getCredentialsError, (state) => ({
    ...state,
    isLoading: false,
  }));
  builder.addCase(setCredentialDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeCredentialDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));

  builder.addCase(setCredentialDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...initialState.dialogAction,
      ...payload,
    },
  }));
  builder.addCase(mergeCredentialDialogAction, (state, { payload }) => ({
    ...state,
    dialogAction: {
      ...state.dialogAction,
      ...payload,
    },
  }));

  builder.addCase(getBrandSecretsNamesSuccess, (state, { payload }) => ({
    ...state,
    brandSecretsNames: [
      ...state.brandSecretsNames,
      ...payload.brandSecrets,
    ],
  }));
});
