import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getRetentionReport,
  getRetentionReportError,
  exportRetentionReportError,
  exportRetentionReportSuccess,
  exportRetentionReport,
  getProcessedRetentionReport,
  setProcessedRetentionReportsSuccess,
} from './actions';
import { IRetentionReportReducer } from '../types';

export const initialState: IRetentionReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const retentionReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getRetentionReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getRetentionReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportRetentionReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportRetentionReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportRetentionReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedRetentionReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedRetentionReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
