import { createAction } from '@reduxjs/toolkit';
import { PasswordFieldName } from 'arcadia-common-fe';
import {
  IPostSiteRequestBody,
  IGetSitesRequestFilterParams,
  IGetSitesResponseBody,
  IPutSiteRequestBody,
  ISiteDialogActionState,
  ISiteDialogFormState,
  SiteAction,
} from '../types';
import { SiteId } from '../../../types/site';

interface IExecuteActionSite {
  id: SiteId
  action: SiteAction
  [PasswordFieldName.confirmationPassword]: string
}

export const postSite = createAction<IPostSiteRequestBody>('SITES/POST_SITE');

export const getSites = createAction<IGetSitesRequestFilterParams>('SITES/GET_SITES');
export const getSitesSuccess = createAction<IGetSitesResponseBody>('SITES/GET_SITES/SUCCESS');
export const getSitesError = createAction('SITES/GET_SITES/ERROR');

export const putSite = createAction<IPutSiteRequestBody>('SITES/PUT_SITE');

export const setSiteDialogForm = createAction<Partial<ISiteDialogFormState> | undefined>('SITES/SET_DIALOG_FORM');
export const mergeSiteDialogForm = createAction<Partial<ISiteDialogFormState> | undefined>('SITES/MERGE_DIALOG_FORM');

export const setSiteDialogAction = createAction<Partial<ISiteDialogActionState> | undefined>(
  'SITES/SET_DIALOG_ACTION',
);
export const mergeSiteDialogAction = createAction<Partial<ISiteDialogActionState> | undefined>(
  'SITES/MERGE_DIALOG_ACTION',
);

export const executeSiteAction = createAction<IExecuteActionSite>('SITES/EXECUTE_ACTION');
