import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetRevenueReportResponseBody,
  IGetRevenueRequestFiltersParams,
  IGetRevenueProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getRevenueProcessedReportRequest = ({ filterParams }: IGetRevenueProcessedReportsFilterParams) => {
  const url = getUrl('/report/revenue/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getRevenueReportRequest = (filterParams: IGetRevenueRequestFiltersParams) => {
  const url = getUrl('/report/revenue', filterParams);

  return makeRequest<IGetRevenueReportResponseBody>(url);
};
