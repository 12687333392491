import { getUrl, makeRequest } from '../../services/api';
import {
  IGetBrandSecretsNamesResponseBody,
  IGetCredentialsRequestFilterParams,
  IGetCredentialsResponseBody,
  IPostCredentialRequestBody,
  IPostSecretNameRequestBody,
  IPutCredentialRequestBody,
  IRemoveCredentialRequestBody,
} from './types';

export const postCredentialRequest = (data: IPostCredentialRequestBody) => {
  const url = getUrl('/brandSecrets');

  return makeRequest(url, data, 'POST');
};

export const getCredentialsRequest = (filterParams: IGetCredentialsRequestFilterParams) => {
  const url = getUrl('/brandSecrets', {take: 100, ...filterParams});

  return makeRequest<IGetCredentialsResponseBody>(url);
};

export const putCredentialRequest = (data: IPutCredentialRequestBody) => {
  const url = getUrl(`/brandSecrets/${data.id}`);

  return makeRequest(url, data, 'PUT');
};

export const removeCredentialRequest = (data: IRemoveCredentialRequestBody) => {
  const url = getUrl(`/brandSecrets/${data.id}`);

  return makeRequest(url, data, 'DELETE');
};

export const postSecretNameRequest = (data: IPostSecretNameRequestBody) => {
  const url = getUrl('/brandSecrets/credentials');

  return makeRequest(url, data, 'POST');
};

export const getBrandSecretsNamesRequest = () => {
  const url = getUrl('/brandSecrets/names');

  return makeRequest<IGetBrandSecretsNamesResponseBody>(url);
};
