import { IStyle } from '@fluentui/merge-styles';
import { FontFamily } from '../../../styles/constants';

export interface ITextFitStyleProps {
  className?: string;
}

export interface ITextFitStyles {
  root: IStyle;
}

export const getStyles = ({ className }: ITextFitStyleProps): ITextFitStyles => ({
  root: [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
    className,
  ],
});
