import { createReducer } from '@reduxjs/toolkit';
import { ITEMS_PER_PAGE } from 'arcadia-common-fe';
import {
  getDisputesReport,
  getDisputesReportError,
  exportDisputesReportError,
  exportDisputesReportSuccess,
  exportDisputesReport,
  getProcessedDisputesReport,
  setProcessedDisputesReportsSuccess,
} from './actions';
import { IDisputesReportReducer } from '../types';

export const initialState: IDisputesReportReducer = {
  isLoading: false,
  isExporting: false,
  total: 0,
  data: [],
  filterParams: {
    take: ITEMS_PER_PAGE,
    offset: 0,
  },
};

export const disputesReportReducer = createReducer(initialState, (builder) => {

  builder.addCase(getDisputesReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(getDisputesReportError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(exportDisputesReport, (state) => ({
    ...state,
    isExporting: true,
  }));
  builder.addCase(exportDisputesReportSuccess, (state) => ({
    ...state,
    isExporting: false,
  }));
  builder.addCase(exportDisputesReportError, (state) => ({
    ...state,
    isExporting: false,
  }));

  builder.addCase(getProcessedDisputesReport, (state) => ({
    ...state,
    isLoading: true,
  }));
  builder.addCase(setProcessedDisputesReportsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
});
