import { getUrl, makeRequest } from '../../services/api';
import {
  IGetMachineBallTableCloseRequestBody,
  IGetMachineBallTableCloseResponseBody,
  IGetMachineBallTableOpenRequestBody,
  IGetMachineBallTableOpenResponseBody,
  IPatchMachineBallTableClearRequestBody,
  IPatchMachineBallTableSetActionRequestBody,
} from './types';

export const getMachineBallTableOpenRequest = ({ id, sessionToken }: IGetMachineBallTableOpenRequestBody) => {
  const url = getUrl(`/machines/${id}/ballTable/${sessionToken}/open`, { id, sessionToken });

  return makeRequest<IGetMachineBallTableOpenResponseBody>(url);
};

export const getMachineBallTableCloseRequest = ({ id, sessionToken }: IGetMachineBallTableCloseRequestBody) => {
  const url = getUrl(`/machines/${id}/ballTable/${sessionToken}/close`, { id, sessionToken });

  return makeRequest<IGetMachineBallTableCloseResponseBody>(url);
};

export const patchMachineBallTableClearRequest = ({ id, sessionToken, label }: IPatchMachineBallTableClearRequestBody) => {
  const url = getUrl(`/machines/${id}/ballTable/${sessionToken}/clear/${label}`);

  return makeRequest(url, { id, sessionToken, label }, 'PATCH');
};

export const patchMachineBallTableSetActionRequest = ({
  id, sessionToken, action, label,
}: IPatchMachineBallTableSetActionRequestBody) => {
  const url = getUrl(`/machines/${id}/ballTable/${sessionToken}/setAction/${action}/${label}`);

  return makeRequest(url, { id, sessionToken, label }, 'PATCH');
};
