import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getDisputesReport,
  getDisputesReportError,
  exportDisputesReport,
  exportDisputesReportError,
  exportDisputesReportSuccess,
  getProcessedDisputesReport,
  setProcessedDisputesReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getDisputesProcessedReportRequest, getDisputesReportRequest } from '../api';
import { IDisputesReportItem, IGetDisputesReportResponseBody, IGetDisputesRequestFiltersParams } from '../types';
import { disputesReportReducerSelector } from './selectors';

function* handleGetDisputesReportRequest(requestParams: IGetDisputesRequestFiltersParams) {
  const { data } = yield call(getDisputesReportRequest, requestParams);

  return data;
}

function* handleGetDisputesProcessedReport({ payload }: ReturnType<typeof getProcessedDisputesReport>) {
  try {
    const { data } = yield call(getDisputesProcessedReportRequest, payload);

    yield put(setProcessedDisputesReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getDisputesReportError());
  }
}

function* handleGetDisputesReport({ payload }: ReturnType<typeof getDisputesReport>) {
  try {
    const disputesReportData: IGetDisputesReportResponseBody = yield handleGetDisputesReportRequest(payload);
    const { info } = disputesReportData;
    const { filterParams } = yield select(disputesReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedDisputesReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getDisputesReportError());
  }
}

function* handleExportDisputesReport({ payload }: ReturnType<typeof exportDisputesReport>): any {
  try {
    const { data }: IGetDisputesReportResponseBody = yield handleGetDisputesReportRequest(payload);

    const preparedData = data.map((item: IDisputesReportItem) => ({
      'Grouping Value': item.groupingValue,
      'Total Dispute Count': item.totalDisputeCount,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Disputes Report.csv');
    yield put(exportDisputesReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportDisputesReportError());
  }
}

export function* disputesReportSagas(): any {
  yield all([
    yield takeLatest(
      getDisputesReport,
      handleGetDisputesReport,
    ),
    yield takeLatest(
      getProcessedDisputesReport,
      handleGetDisputesProcessedReport,
    ),
    yield takeLatest(
      exportDisputesReport,
      handleExportDisputesReport,
    ),
  ]);
}
