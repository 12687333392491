import { IStyle } from '@fluentui/merge-styles';
import { IDeviceInfoContext } from '../../../types/general';
import { Color } from '../../../styles/constants';

export interface IPanelHeaderStyleProps extends IDeviceInfoContext {
}

export interface IPanelHeaderStyles {
  panelHeader: IStyle;
  iconBack: IStyle;
  titleIcon: IStyle;
}

export const getStyles = ({ calculateRemValue }: IPanelHeaderStyleProps): IPanelHeaderStyles => ({
  panelHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: calculateRemValue(8.93),
    textTransform: 'uppercase',
    letterSpacing: calculateRemValue(-0.15),
  },
  iconBack: {
    cursor: 'pointer',
    position: 'absolute',
    left: calculateRemValue(4.6),
    width: calculateRemValue(3.93),
    transform: 'rotate(180deg)',
  },
  titleIcon: {
    width: calculateRemValue(7.5),
    marginRight: calculateRemValue(3.21),
    fill: Color.panel.headerIconFillColor,
  },
});
