import {
  all, call, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import {
  IGetSitesRequestFilterParams,
  IGetSitesResponseBody,
  SiteAction,
} from '../types';
import {
  postSiteRequest,
  getSitesRequest,
  putSiteRequest,
  removeSiteRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';
import {
  postSite,
  getSites,
  getSitesError,
  getSitesSuccess,
  putSite,
  mergeSiteDialogForm,
  setSiteDialogForm,
  setSiteDialogAction,
  mergeSiteDialogAction,
  executeSiteAction,
} from './actions';

function* handleGetSitesRequest(requestParams: IGetSitesRequestFilterParams) {
  const { data } = yield call(getSitesRequest, requestParams);

  return data;
}

function* handleRefreshSites() {
  yield put(getSites(queryString.parse(window.location.search)));
}

function* handlePostSite({ payload }: ReturnType<typeof postSite>) {
  try {
    yield put(mergeSiteDialogForm({
      isLoading: true,
    }));

    yield call(postSiteRequest, payload);
    yield put(setSiteDialogForm());
    yield call(toast.success, 'The site has been successfully created');
    yield handleRefreshSites();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeSiteDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleGetSites({ payload }: ReturnType<typeof getSites>) {
  try {
    const data: IGetSitesResponseBody = yield handleGetSitesRequest(payload);

    yield put(getSitesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getSitesError());
  }
}

function* handlePutSite({ payload }: ReturnType<typeof putSite>) {
  try {
    yield put(mergeSiteDialogForm({
      isLoading: true,
    }));

    yield call(putSiteRequest, payload);
    yield put(setSiteDialogForm());
    yield call(toast.success, `The site ${payload.name} has been successfully updated`);
    yield handleRefreshSites();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeSiteDialogForm({
      isLoading: false,
    }));
  }
}

export function* handleExecuteSiteAction({ payload }: ReturnType<typeof executeSiteAction>) {
  try {
    const { action, id, confirmationPassword } = payload;

    if (action === SiteAction.remove) {
      yield call(removeSiteRequest, { id, confirmationPassword });
      yield call(toast.success, `The site with id ${id} has been successfully removed.`);
    }
    yield put(setSiteDialogAction());
    yield handleRefreshSites();
  } catch (error: any) {
    yield handleError(error);
    yield put(mergeSiteDialogAction({ isLoading: false }));
  }
}

export function* sitesSagas(): any {
  yield all([
    yield takeEvery(
      postSite,
      handlePostSite,
    ),
    yield takeLatest(
      getSites,
      handleGetSites,
    ),
    yield takeEvery(
      putSite,
      handlePutSite,
    ),
    yield takeLatest(
      executeSiteAction,
      handleExecuteSiteAction,
    ),
  ]);
}
