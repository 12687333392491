import { IStyle } from '@fluentui/merge-styles';
import { Color, FontSize } from '../../../styles/constants';
import { IDeviceInfoContext } from '../../../types';

export interface IAccordionStyleProps extends IDeviceInfoContext {
  className?: string;
  isExpanded: boolean;
}

export interface IAccordionStyles {
  accordion: IStyle;
  header: IStyle;
  icon: IStyle;
  children: IStyle;
}

export const getStyles = ({ className, calculateRemValue, isExpanded }: IAccordionStyleProps): IAccordionStyles => ({
  accordion: [
    {
      textTransform: 'uppercase',
      borderTop: `2px solid ${Color.border}`,
      overflow: 'hidden',
      selectors: {
        ':last-child': {
          borderBottom: `2px solid ${Color.border}`,
        },
      },
    },
    className,
  ],
  header: [
    {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      fontSize: calculateRemValue(FontSize.Size7),
      padding: `${calculateRemValue(4)} 0`,
    },
    isExpanded && {
      color: Color.menuHeader,
    },
  ],
  icon: [
    {
      stroke: 'currentColor',
      transform: 'rotate(-90deg)',
      transition: 'transform 0.8s',
      width: calculateRemValue(3),
      marginRight: '8px',
    },
    isExpanded && {
      transform: 'rotate(0)',
    },
  ],
  children: {
    borderTop: `2px solid ${Color.border}`,
    transformOrigin: 'top center',
  },
});
