import { IStorage } from './types';

export class MemoryStorage implements IStorage {
  private storage = new Map<string, string | null>();

  public getItem(key: string): string | null {
    return this.storage.get(key) ?? null;
  }

  public setItem(key: string, value: string): void {
    this.storage.set(key, value);
  }

  public remove(key: string): void {
    this.storage.delete(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}
