import { EventEmitter as Emitter } from 'events';
import { IEventEmitter } from './types';

export class EventEmitter<EmitterEvents extends string> implements IEventEmitter<EmitterEvents> {
  protected emitter = new Emitter();

  emit(event: EmitterEvents, ...args: any[]): void {
    this.emitter.emit(event, ...args);
  }

  on(event: EmitterEvents, listener: (...args: any) => void): void {
    this.emitter.on(event, listener);
  }
}
