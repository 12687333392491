import { createAction } from '@reduxjs/toolkit';
import {
  EntityType, IGetEntityRequestBody, OptionId,
} from './types';

interface IGetEntityDataSuccessActionPayload {
  entityType: EntityType
  ids: OptionId[]
  entities: any
}

export const getEntityData = createAction<IGetEntityRequestBody>('SELECT_ENTITY_BY_TYPE/GET_ENTITY_DATA');
export const getEntityDataSuccess = createAction<IGetEntityDataSuccessActionPayload>('SELECT_ENTITY_BY_TYPE/GET_ENTITY_DATA/SUCCESS');
export const getEntityDataError = createAction<{ entityType: EntityType }>('SELECT_ENTITY_BY_TYPE/GET_ENTITY_DATA/ERROR');
