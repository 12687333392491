import { getUrl, makeRequest } from '../../services/api';
import { IGetMaintenanceResponseBody, IGetMaintenanceRequestFiltersParams } from './types';
import { AlertId } from '../../types/alerts';

export const getMaintenanceRequest = (filterParams: IGetMaintenanceRequestFiltersParams) => {
  const url = getUrl('/maintenance', filterParams);

  return makeRequest<IGetMaintenanceResponseBody>(url);
};

export const maintenanceFillDispenserRequest = (id: AlertId) => {
  const url = getUrl(`/maintenance/${id}/dispenserFilled`);

  return makeRequest(url, undefined, 'POST');
};

export const maintenanceDismissRequest = (id: AlertId) => {
  const url = getUrl('/maintenance/dismiss');

  return makeRequest(url, { ids: [id] }, 'POST');
};

export const maintenanceEmptyDispenserWaistRequest = (id: AlertId) => {
  const url = getUrl(`/maintenance/${id}/emptyDispenserWaist`);

  return makeRequest(url, undefined, 'POST');
};
