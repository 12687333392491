import { call } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import axios from 'axios';

interface IError extends Error {
  response?: {
    data: {
      data: string[];
    };
  };
}

function getMessageFromError(error: IError) {
  const errorData = error?.response?.data?.data;

  if (errorData?.length) {
    return Array.isArray(errorData) ? errorData?.join(', ') : errorData;
  }

  if (error.message) {
    return error.message;
  }

  return null;
}

export function* handleError(error: IError, defaultErrorMessage = 'Oops! Something went wrong. Please try again later.') {
  if (axios.isCancel(error)) {
    // do not show toast for cancelled requests
    console.log('aborted automatically');

    return;
  }

  const message = getMessageFromError(error);

  yield call(toast.error, message || defaultErrorMessage);
}
