import { getUrl, makeRequest } from '../../services/api';
import { IGetPlayersRequestFilterParams, IGetPlayersResponseBody, IPlayerActionRequestBody } from './types';

export const getPlayersRequest = (filterParams: IGetPlayersRequestFilterParams) => {
  const url = getUrl('/players', filterParams);

  return makeRequest<IGetPlayersResponseBody>(url);
};

export const blockPlayersRequest = (data: IPlayerActionRequestBody) => {
  const url = getUrl('/players/block');

  return makeRequest(url, data, 'POST');
};

export const unblockPlayersRequest = (data: IPlayerActionRequestBody) => {
  const url = getUrl('/players/unblock');

  return makeRequest(url, data, 'POST');
};

export const toggleIsTesterRequest = (data: IPlayerActionRequestBody) => {
  const url = getUrl('/players/setIsTester');

  return makeRequest(url, data, 'POST');
};
