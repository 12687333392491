import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LoadingOverlay, NoAccessRights } from 'arcadia-common-fe';
import { authReducerSelector } from '../modules/auth/selectors';
import { UserPermissionName } from '../modules/auth/types';

export const withPermissions = (predicate: () => boolean, renderFallback: () => React.ReactElement) => (
  <P extends object & { permissionName?: UserPermissionName }>(Component: React.ComponentType<P>): React.FC<P> => (props: P) => {
    const { permissionName } = props;
    const { userPermissions, isPermissionsLoading } = useSelector(authReducerSelector);

    const isNotAllowedPermission = useMemo(() => (
      userPermissions.length && !userPermissions.some((permission) => permission === permissionName)
    ), [userPermissions, permissionName]);

    if (!predicate()) {
      return renderFallback();
    }

    if (isPermissionsLoading) {
      return <LoadingOverlay />;
    }

    if (isNotAllowedPermission) {
      return <NoAccessRights />;
    }

    return (
      <Component {...props} />
    );
  }
);
