import React, {
  useEffect, useRef, useState, useCallback,
} from 'react';
import { useVideoStreamLog } from './hooks/useVideoStreamLog';
import { useVideoStream } from './hooks/useVideoStream';
import { useMutationObservable } from './hooks/useMutationObservable';
import { HLSVideoPlayer } from './HLSVideoPlayer';
import { CSRTCVideoPlayer } from './CSRTCVideoPlayer';
import {
  VideoPlayerType, IWebrtcSrc, IStreamStatusData, IVideoFailedEvent,
} from './types';
import CSRTCPlayer from './js/CSRTCPlayer.min';

window.CSRTCPlayer = CSRTCPlayer;

export interface IVideoStream extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  classNamesPlayer?: string;
  volume: number;
  webrtcVideoTunneling?: boolean;
  playerType: VideoPlayerType;
  hlsSrc?: string;
  webrtcSrc: IWebrtcSrc;
  handleGetStreamData?: (status: IStreamStatusData) => void;
  handleSendVideoFailedEvent?: (data: IVideoFailedEvent) => void;
  handleSendUserEventNotification?: (data: any) => void;
  videoMonitoringEventSending?: (data: any) => void;
}

export const VideoStream: React.FC<IVideoStream> = React.memo(({
  className,
  classNamesPlayer,
  volume,
  webrtcVideoTunneling,
  playerType = VideoPlayerType.HLSPlayer,
  hlsSrc = '',
  webrtcSrc,
  handleGetStreamData = () => {},
  handleSendVideoFailedEvent = () => {},
  handleSendUserEventNotification,
  videoMonitoringEventSending,
  ...restProps
}) => {
  const videoStreamContainer = useRef<HTMLDivElement>(null);
  const [, setRefreshStateOnVideoNodeAppearance] = useState(1);

  const onContainerMutation = useCallback(() => {
    setRefreshStateOnVideoNodeAppearance((count) => count + 1);
  }, []);

  useMutationObservable(videoStreamContainer.current, onContainerMutation);

  const {
    handleHLSVideoPlayerStatusChange,
    handleSCRTCVideoPlayerSessionStatusChange,
    handleCSRTCVideoPlayerStreamStatusChange,
  } = useVideoStreamLog({
    playerType,
    sendUserEventNotification: handleSendUserEventNotification,
  });

  const {
    quality,
    videoStreamStatus,
  } = useVideoStream({
    activeVideoPlayerType: playerType,
    videoNodeRef: videoStreamContainer?.current,
    sendVideoFailedEvent: handleSendVideoFailedEvent,
    sendUserEventNotification: handleSendUserEventNotification,
  });

  useEffect(() => {
    if (handleGetStreamData) {
      handleGetStreamData({
        quality,
        videoStreamStatus,
      });
    }
  }, [quality, videoStreamStatus, handleGetStreamData]);

  return (
    <div
      ref={videoStreamContainer}
      className={className}
      role="presentation"
      {...restProps}
    >
      { playerType === VideoPlayerType.HLSPlayer ? (
        <HLSVideoPlayer
          className={classNamesPlayer}
          volume={volume / 100}
          src={hlsSrc}
          videoMonitoringEventSending={videoMonitoringEventSending}
          quality={quality}
          onStatusChange={handleHLSVideoPlayerStatusChange}
        />
      ) : (
        <CSRTCVideoPlayer
          className={classNamesPlayer}
          volume={volume}
          webrtcVideoTunneling={webrtcVideoTunneling}
          videoStreamStatus={videoStreamStatus}
          quality={quality}
          videoMonitoringEventSending={videoMonitoringEventSending}
          sendVideoFailedEvent={handleSendVideoFailedEvent}
          onStreamStatusChange={handleCSRTCVideoPlayerStreamStatusChange}
          onSessionStatusChange={handleSCRTCVideoPlayerSessionStatusChange}
          {...webrtcSrc}
        />
      ) }
    </div>
  );
});
