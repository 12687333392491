import { useEffect } from 'react';

const DEFAULT_OPTIONS = {
  attributes: true, childList: true, subtree: true
};

export const useMutationObservable = (targetElement: HTMLDivElement | null, callback: (mutationsList: any) => void, options = DEFAULT_OPTIONS) => {
  useEffect(() => {
    if (!targetElement) return;

    const mutationObserver = new MutationObserver(callback);

    mutationObserver.observe(targetElement, options);

    return () => {
      mutationObserver.disconnect();
    };
  }, [callback, targetElement, options]);
}