import { ICommonRequestFiltersParams, PasswordFieldName } from 'arcadia-common-fe';
import { ISite, ISites, SiteId } from '../../types/site';

export enum SiteAction {
  remove,
}

export interface IPostSiteRequestBody extends ISite {
  password: string
}

export interface IPutSiteRequestBody extends ISite {
  password: string
}

export interface IRemoveSiteRequestBody {
  id: SiteId
  [PasswordFieldName.confirmationPassword]: string
}

export interface IGetSitesRequestFilterParams extends ICommonRequestFiltersParams {
}

export interface IGetSitesResponseBody {
  total: number
  sites: ISites
}

export interface ISiteDialogActionState {
  id: SiteId | null
  action: SiteAction
  isOpen: boolean
  isLoading?: boolean
}

export interface ISiteDialogFormState {
  isOpen: boolean
  isLoading?: boolean
  initialValues?: Partial<ISite>
}

export interface ISitesReducer extends IGetSitesResponseBody {
  readonly isLoading: boolean
  readonly dialogForm: ISiteDialogFormState
  readonly dialogAction: ISiteDialogActionState
}

export interface ISitesSlice {
  sitesReducer: ISitesReducer
}
