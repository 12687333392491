import {
  all, put, takeLatest, call,
} from 'redux-saga/effects';
import {
  getGroups,
  getGroupsError,
  getGroupsSuccess,
  getActiveSessionsBreakdown,
  getActiveSessionsBreakdownError,
  getActiveSessionsBreakdownSuccess,
  getCurrentActives,
  getCurrentActivesError,
  getCurrentActivesSuccess,
  getActiveAndNewPlayers,
  getActiveAndNewPlayersError,
  getPlayersPerDay,
  getPlayersPerDayError,
  getPlayersPerDaySuccess,
  getActiveAndNewPlayersSuccess,
  getBettingActivity,
  getBettingActivityError,
  getBettingActivitySuccess,
  getTopWinnersAndLosers,
  getTopWinnersAndLosersError,
  getTopWinnersAndLosersSuccess,
  getWaitTime,
  getWaitTimeError,
  getWaitTimeSuccess,
  getMachineAvailability,
  getMachineAvailabilityError,
  getMachineAvailabilitySuccess,
  getMachinesStatus,
  getMachinesStatusError,
  getMachinesStatusSuccess,
  getLatestAlerts,
  getLatestAlertsError,
  getLatestAlertsSuccess,
} from './actions';
import {
  getGroupsRequest,
  getActiveSessionsBreakdownRequest,
  getCurrentActivesRequest,
  getActiveAndNewPlayersRequest,
  getPlayersPerDayRequest,
  getBettingActivityRequest,
  getTopWinnersAndLosersRequest,
  getWaitTimeRequest,
  getMachineAvailabilityRequest,
  getMachinesStatusRequest,
  getLatestAlertsRequest,
} from '../api';
import { handleError } from '../../../services/sagasErrorHandler';

function* handleGetGroups() {
  try {
    const { data } = yield getGroupsRequest();

    yield put(getGroupsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getGroupsError());
  }
}

function* handleGetPlayersPerDay({ payload }: ReturnType<typeof getPlayersPerDay>) {
  try {
    const { data } = yield call(getPlayersPerDayRequest, payload.isAutoRefresh, payload.gameId);

    yield put(getPlayersPerDaySuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getPlayersPerDayError());
  }
}

function* handleGetCurrentActives({ payload }: ReturnType<typeof getCurrentActives>) {
  try {
    const { data } = yield call(getCurrentActivesRequest, payload.isAutoRefresh, payload.gameId);

    yield put(getCurrentActivesSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getCurrentActivesError());
  }
}

function* handleGetActiveSessionsBreakdown({ payload }: ReturnType<typeof getActiveSessionsBreakdown>) {
  try {
    const { data } = yield call(getActiveSessionsBreakdownRequest, payload.isAutoRefresh, payload.gameId);

    yield put(getActiveSessionsBreakdownSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getActiveSessionsBreakdownError());
  }
}

function* handleGetActiveAndNewPlayers() {
  try {
    const { data } = yield getActiveAndNewPlayersRequest();

    yield put(getActiveAndNewPlayersSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getActiveAndNewPlayersError());
  }
}

function* handleGetBettingActivity() {
  try {
    const { data } = yield getBettingActivityRequest();

    yield put(getBettingActivitySuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getBettingActivityError());
  }
}

function* handleGetTopWinnersAndLosers({ payload }: ReturnType<typeof getTopWinnersAndLosers>) {
  try {
    const { data } = yield call(getTopWinnersAndLosersRequest, payload.isAutoRefresh, payload.gameId);

    yield put(getTopWinnersAndLosersSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getTopWinnersAndLosersError());
  }
}

function* handleGetWaitTime({ payload }: ReturnType<typeof getWaitTime>) {
  try {
    const { isAutoRefresh, filterValues, gameId } = payload;
    const { data } = yield getWaitTimeRequest(filterValues, isAutoRefresh, gameId);

    yield put(getWaitTimeSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getWaitTimeError());
  }
}

function* handleGetMachineAvailability({ payload }: ReturnType<typeof getMachineAvailability>) {
  try {
    const { isAutoRefresh, filterValues, gameId } = payload;
    const { data } = yield getMachineAvailabilityRequest(filterValues, isAutoRefresh, gameId);

    yield put(getMachineAvailabilitySuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachineAvailabilityError());
  }
}

function* handleGetMachinesStatus({ payload }: ReturnType<typeof getMachinesStatus>) {
  try {
    const { isAutoRefresh, filterValues, gameId } = payload;
    const { data } = yield getMachinesStatusRequest(filterValues, isAutoRefresh, gameId);

    yield put(getMachinesStatusSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getMachinesStatusError());
  }
}

function* handleGetLatestAlerts({ payload }: ReturnType<typeof getLatestAlerts>) {
  try {
    const { data } = yield call(getLatestAlertsRequest, payload.isAutoRefresh, payload.gameId);

    yield put(getLatestAlertsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getLatestAlertsError());
  }
}

export function* dashboardSagas(): any {
  yield all([
    yield takeLatest(
      getGroups,
      handleGetGroups,
    ),
    yield takeLatest(
      getPlayersPerDay,
      handleGetPlayersPerDay,
    ),
    yield takeLatest(
      getCurrentActives,
      handleGetCurrentActives,
    ),
    yield takeLatest(
      getActiveSessionsBreakdown,
      handleGetActiveSessionsBreakdown,
    ),
    yield takeLatest(
      getActiveAndNewPlayers,
      handleGetActiveAndNewPlayers,
    ),
    yield takeLatest(
      getBettingActivity,
      handleGetBettingActivity,
    ),
    yield takeLatest(
      getTopWinnersAndLosers,
      handleGetTopWinnersAndLosers,
    ),
    yield takeLatest(
      getWaitTime,
      handleGetWaitTime,
    ),
    yield takeLatest(
      getMachineAvailability,
      handleGetMachineAvailability,
    ),
    yield takeLatest(
      getMachinesStatus,
      handleGetMachinesStatus,
    ),
    yield takeLatest(
      getLatestAlerts,
      handleGetLatestAlerts,
    ),
  ]);
}
