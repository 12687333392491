import { ICommonRequestFiltersParams, INormalizedEntities } from 'arcadia-common-fe';
import { BinaryBoolean } from '../../types/api';
import { ICmses } from '../../types/cms';

export enum GroupStatus {
  idle = 'idle',
  inPlay = 'in-play',
  drying = 'drying',
  shuttingDown = 'shutting-down',
  offline = 'offline',
}

export enum GroupAction {
  activate,
  dry,
  shutdown,
  remove,
}

export interface IGroup {
  id: number;
  status: GroupStatus;
  name: string;
  color: string;
  blueRibbonGameId: null | string;
  denominator: number;
  machinesIdle: number;
  machinesTotal: number;
  totalPowerLineA: number;
  totalPowerLineB: number;
  stackCoinsSize: number;
  timePerShotMs: number;
  graceTimeout: number;
  private: string | null;
  brands: boolean;
  gameId: string;
  regulation: {};
  configuration: {};
  gameVariation: string;
  textGameVariation: string;
  groupType: string;
  textGroupType: string;
}

export type IGroups = IGroup[];

export interface IPostGroupRequestBody extends IGroup {
  prizeGroup: string;
  password: string;
}

export interface IGroupsFiltersPanelValues {
  id?: number | number[];
  status?: GroupStatus[];
  name?: string;
  machinesTotal?: number;
  machinesIdle?: number;
  denominator?: number;
  operators?: BinaryBoolean;
  gameId?: string;
}

export interface IGetGroupsRequestFiltersParams extends ICommonRequestFiltersParams, IGroupsFiltersPanelValues {
}

export type GroupId = number;

export type IGroupsEntities = INormalizedEntities<IGroup, GroupId>;

export interface IGroupsNormalizedData {
  ids: GroupId[];
  entities: IGroupsEntities;
  total: number;
}

export interface IGetGroupsResponseBody {
  total: number;
  groups: IGroups;
}

export interface IPutGroupRequestBody extends IGroup {
  prizeGroup: string;
  password: string;
}

export interface IGroupsFormValues extends IPutGroupRequestBody, IPostGroupRequestBody {
}

export interface IGroupActionRequestBody {
  id: string;
  password: string;
}

export interface IGroupDialogFormState {
  isOpen: boolean;
  isLoading?: boolean;
  initialValues?: IGroup;
}

export interface IGroupDialogActionState {
  id: number | null;
  action: GroupAction;
  isOpen: boolean;
  isLoading?: boolean;
}

export interface IGroupsReducer extends IGroupsNormalizedData {
  readonly isLoading: boolean;
  readonly isExporting: boolean;
  readonly dialogForm: IGroupDialogFormState;
  readonly dialogAction: IGroupDialogActionState;
  readonly logos: IGroupLogosState;
}

export interface IGroupsSlice {
  groupsReducer: IGroupsReducer;
}

export interface IGetGroupLogoRequestBody {
  id: GroupId;
}

export interface IGetGroupLogoResponseBody {
  total: number;
  records: ICmses;
  id: GroupId;
}

export interface IGroupLogo {
  total: number;
  records: ICmses;
  isLoading: boolean;
}

export interface IGroupLogosState {
  [id: string]: IGroupLogo;
}

export interface IGroupLogoError {
  id: GroupId;
}
