import {
  IGetMonitoringRequestFilterParams,
  IGetMonitoringResponseBody,
  IPostMonitoringRequestBody,
  IPutMonitoringRequestBody,
  IRemoveMonitoringRequestBody,
} from './types';
import { getUrl, makeRequest } from '../../services/api';

export const postMonitoringRequest = (data: IPostMonitoringRequestBody) => {
  const url = getUrl('/monitorings');

  return makeRequest(url, data, 'POST');
};

export const getMonitoringRequest = (filterParams: IGetMonitoringRequestFilterParams) => {
  const url = getUrl('/monitorings', filterParams);

  return makeRequest<IGetMonitoringResponseBody>(url);
};

export const putMonitoringRequest = (data: IPutMonitoringRequestBody) => {
  const url = getUrl(`/monitorings/${data.id}`);

  return makeRequest(url, data, 'PUT');
};

export const removeMonitoringRequest = (data: IRemoveMonitoringRequestBody) => {
  const url = getUrl(`/monitorings/${data.id}`);

  return makeRequest(url, data, 'DELETE');
};
