import { createReducer } from '@reduxjs/toolkit';
import {
  getTransactions,
  getTransactionsSuccess,
  getTransactionsError,
  setSelectedTransactions,
  setDismissDialog,
  mergeDismissDialog,
  setRetryDialog,
  mergeRetryDialog,
} from './actions';
import { ITransactionsReducer } from '../types';

export const initialState: ITransactionsReducer = {
  ids: [],
  entities: {},
  isLoading: false,
  total: 0,
  selectedTransactions: [],
  dismissDialog: {
    isOpen: false,
    isLoading: false,
  },
  retryDialog: {
    isOpen: false,
    isLoading: false,
  },
};

export const transactionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getTransactions, (state) => ({
    ...state,
    ids: [],
    entities: {},
    total: 0,
    isLoading: true,
    selectedTransactions: [],
  }));
  builder.addCase(getTransactionsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getTransactionsError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(setSelectedTransactions, (state, { payload }) => ({
    ...state,
    selectedTransactions: payload,
  }));

  builder.addCase(setDismissDialog, (state, { payload }) => ({
    ...state,
    dismissDialog: {
      ...initialState.dismissDialog,
      ...payload,
    },
  }));
  builder.addCase(mergeDismissDialog, (state, { payload }) => ({
    ...state,
    dismissDialog: {
      ...state.dismissDialog,
      ...payload,
    },
  }));

  builder.addCase(setRetryDialog, (state, { payload }) => ({
    ...state,
    retryDialog: {
      ...initialState.retryDialog,
      ...payload,
    },
  }));
  builder.addCase(mergeRetryDialog, (state, { payload }) => ({
    ...state,
    retryDialog: {
      ...state.retryDialog,
      ...payload,
    },
  }));
});
