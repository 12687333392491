import { getUrl, makeRequest } from '../../../../services/api';
import {
  IGetDisputesReportResponseBody,
  IGetDisputesRequestFiltersParams,
  IGetDisputesProcessedReportsFilterParams,
} from './types';
import { IGetProcessedReportsResponseBody } from '../../types';

export const getDisputesProcessedReportRequest = ({ filterParams }: IGetDisputesProcessedReportsFilterParams) => {
  const url = getUrl('/report/disputes/info', filterParams);

  return makeRequest<IGetProcessedReportsResponseBody>(url);
};

export const getDisputesReportRequest = (filterParams: IGetDisputesRequestFiltersParams) => {
  const url = getUrl('/report/disputes', filterParams);

  return makeRequest<IGetDisputesReportResponseBody>(url);
};
