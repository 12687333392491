import { getUrl, makeRequest } from '../../services/api';
import {
  IGetSitesRequestFilterParams,
  IGetSitesResponseBody,
  IPostSiteRequestBody,
  IPutSiteRequestBody,
  IRemoveSiteRequestBody,
} from './types';

export const postSiteRequest = (data: IPostSiteRequestBody) => {
  const url = getUrl('/sites');

  return makeRequest(url, data, 'POST');
};

export const getSitesRequest = (filterParams: IGetSitesRequestFilterParams) => {
  const url = getUrl('/sites', filterParams);

  return makeRequest<IGetSitesResponseBody>(url);
};

export const putSiteRequest = (data: IPutSiteRequestBody) => {
  const url = getUrl(`/sites/${data.id}`);

  return makeRequest(url, data, 'PUT');
};

export const removeSiteRequest = (data: IRemoveSiteRequestBody) => {
  const url = getUrl(`/sites/${data.id}`);

  return makeRequest(url, data, 'DELETE');
};
