import {
  all, call, put, takeLatest, select,
} from 'redux-saga/effects';
import papaparse from 'papaparse';
import { toast } from 'react-toastify';
import { saveStringAsFile } from 'arcadia-common-fe';
import {
  getFunnelReport,
  getFunnelReportError,
  exportFunnelReport,
  exportFunnelReportError,
  exportFunnelReportSuccess,
  getProcessedFunnelReport,
  setProcessedFunnelReportsSuccess,
} from './actions';
import { handleError } from '../../../../../services/sagasErrorHandler';
import { getFunnelProcessedReportRequest, getFunnelReportRequest } from '../api';
import { IFunnelReportItem, IGetFunnelReportResponseBody, IGetFunnelRequestFiltersParams } from '../types';
import { funnelReportReducerSelector } from './selectors';

function* handleGetFunnelReportRequest(requestParams: IGetFunnelRequestFiltersParams) {
  const { data } = yield call(getFunnelReportRequest, requestParams);

  return data;
}

function* handleGetFunnelProcessedReport({ payload }: ReturnType<typeof getProcessedFunnelReport>) {
  try {
    const { data } = yield call(getFunnelProcessedReportRequest, payload);

    yield put(setProcessedFunnelReportsSuccess(data));
  } catch (error: any) {
    yield handleError(error);
    yield put(getFunnelReportError());
  }
}

function* handleGetFunnelReport({ payload }: ReturnType<typeof getFunnelReport>) {
  try {
    const funnelReportData: IGetFunnelReportResponseBody = yield handleGetFunnelReportRequest(payload);
    const { info } = funnelReportData;
    const { filterParams } = yield select(funnelReportReducerSelector);

    if (info.inProgress || info.toCreate) {
      yield call(toast.info, 'Your report is preparing. Check the “Processed reports“ section in few minutes');
    }

    yield put(getProcessedFunnelReport({ filterParams }));
  } catch (error: any) {
    yield handleError(error);
    yield put(getFunnelReportError());
  }
}

function* handleExportFunnelReport({ payload }: ReturnType<typeof exportFunnelReport>): any {
  try {
    const { data }: IGetFunnelReportResponseBody = yield handleGetFunnelReportRequest(payload);

    const preparedData = data.map((item: IFunnelReportItem) => ({
      'Grouping Value': item.groupingValue,
      '# Unique Players': item.totalUniquePlayers,
      '# Unique Sessions': item.totalUniqueSessions,
      'Total Session Time': item.totalSessionTime,
      'Avg Session Time': item.avgSessionTime,
      Rounds: item.totalRoundsPlayed,
      'Avg Rounds Per Session': item.avgRoundsPerSession,
      'Watch Time': item.totalWatchTime,
      'Avg Watch Time': item.avgWatchTime,
      'Max Watch Time': item.maxWatchTime,
      'Queue Time': item.totalQueueTime,
      'Avg Queue Time': item.avgQueueTime,
      'Max Queue Time': item.maxQueueTime,
      'In Play Time': item.totalInPlayTime,
      'Avg In Play Time': item.avgInPlayTime,
      'Max In Play Time': item.maxInPlayTime,
      '# Sessions Watch': item.totalSessionsWatch,
      '% Sessions Watch': item.percentSessionsWatch,
      '# Sessions Queue': item.totalSessionsQueue,
      '% Sessions Queue': item.percentSessionsQueue,
      '# Sessions Behind': item.totalSessionsBehind,
      '% Sessions Behind': item.percentSessionsBehind,
      '# Sessions In Play': item.totalSessionsInPlay,
      '% Sessions In Plays': item.percentSessionsInPlay,
      '# Sessions Change Denomination': item.totalSessionsChangeDenomination,
      '% Sessions Change Denomination': item.percentSessionsChangeDenomination,
    }));

    const csvString = yield call(papaparse.unparse, preparedData);

    yield call(saveStringAsFile, csvString, 'Funnel Report.csv');
    yield put(exportFunnelReportSuccess());
  } catch (error: any) {
    yield handleError(error);
    yield put(exportFunnelReportError());
  }
}

export function* funnelReportSagas(): any {
  yield all([
    yield takeLatest(
      getFunnelReport,
      handleGetFunnelReport,
    ),
    yield takeLatest(
      getProcessedFunnelReport,
      handleGetFunnelProcessedReport,
    ),
    yield takeLatest(
      exportFunnelReport,
      handleExportFunnelReport,
    ),
  ]);
}
