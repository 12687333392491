import { IStyle } from '@fluentui/merge-styles';
import { textBackgroundGradient } from '../../../styles/helpers';

export interface ITextGradientStyleProps {
  className?: string;
  color: string;
  gradient: string;
}

export interface ITextGradientStyles {
  root: IStyle;
}

export const getStyles = ({ className, color, gradient }: ITextGradientStyleProps): ITextGradientStyles => ({
  root: [
    {
      color,
    },
    textBackgroundGradient(gradient),
    className,
  ],
});
