import { ICommonRequestFiltersParams } from 'arcadia-common-fe';
import { OperatorId } from '../../types/operators';
import { ICmses } from '../../types/cms';

export enum OperatorStatus {
  disabled = 'disabled',
  enabled = 'enabled'
}

export enum OperatorAction {
  enable,
  disable,
  remove,
}

export interface IOperatorsFiltersPanelValues {
  id?: string;
  status?: OperatorStatus[];
  operatorName?: string;
}

export interface IOperator {
  id: OperatorId;
  status: OperatorStatus;
  name: string;
  apiConnectorId: string;
  regulation: object;
  configuration: object;
  activeSessionsCount: number;
  voucherPortalUsername: string;
}

export type IOperators = IOperator[];

export interface IPostOperatorRequestBody extends IOperator {
  password: string;
  voucherPortalPassword: string;
}

export interface IPutOperatorRequestBody extends IOperator {
  password: string;
  voucherPortalPassword: string;
}

export interface IPostOperatorResponseBody extends IOperator {
}

export interface IPutOperatorResponseBody extends IOperator {
}

export interface IGetOperatorLogoResponseBody {
  total: number;
  records: ICmses;
  id: OperatorId;
}

export interface IOperatorLogo {
  total: number;
  records: ICmses;
  isLoading: boolean;
}

export interface IOperatorLogosState {
  [id: string]: IOperatorLogo;
}

export interface IExecuteOperatorResponseBody extends IOperator {
}

export interface IGetOperatorsRequestFilterParams extends ICommonRequestFiltersParams, IOperatorsFiltersPanelValues {
}

export interface IGetOperatorsResponseBody {
  total: number;
  operators: IOperators;
}

export interface IOperatorFormValues extends Omit<IPostOperatorRequestBody | IPutOperatorRequestBody, 'regulation' | 'configuration'> {
  regulation: string | {};
  configuration: string | {};
}

export interface IOperatorDialogFormState {
  isOpen: boolean;
  isLoading?: boolean;
  initialValues?: Partial<IOperatorFormValues | IOperator>;
}

export interface IOperatorsReducer extends IGetOperatorsResponseBody {
  readonly isLoading: boolean;
  readonly isExporting: boolean;
  readonly dialogForm: IOperatorDialogFormState;
  readonly dialogAction: IOperatorDialogActionState;
  readonly logos: IOperatorLogosState;
}

export interface IOperatorsSlice {
  operatorsReducer: IOperatorsReducer;
}

export interface IOperatorActionRequestBody {
  id: string;
  password: string;
}

export interface IGetOperatorLogoRequestBody {
  id: OperatorId;
}

export interface IOperatorDialogActionState {
  id: number | null;
  action: OperatorAction;
  isOpen: boolean;
  isLoading?: boolean;
}

export interface IOperatorLogoError {
  id: OperatorId;
}
