import { createReducer } from '@reduxjs/toolkit';
import {
  getCms,
  getCmsError,
  getCmsSuccess,
  setCmsDialogForm,
  mergeCmsDialogForm,
  clearCms,
  getAssetTypes,
  getAssetTypesSuccess,
  getAssetTypesError,
} from './actions';
import { ICmsReducer } from '../types';

export const initialState: ICmsReducer = {
  isLoading: false,
  total: 0,
  records: [],
  dialogForm: {
    isOpen: false,
    isLoading: false,
  },
  assetTypes: {},
  isLoadingAssetTypes: false,
};

export const cmsReducer = createReducer(initialState, (builder) => {
  builder.addCase(clearCms, (state) => ({
    ...initialState,
    assetTypes: { ...state.assetTypes },
  }));

  builder.addCase(getCms, (state) => ({
    ...state,
    total: 0,
    records: [],
    isLoading: true,
  }));
  builder.addCase(getCmsSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
    isLoading: false,
  }));
  builder.addCase(getCmsError, (state) => ({
    ...state,
    isLoading: false,
  }));

  builder.addCase(getAssetTypes, (state) => ({
    ...state,
    assetTypes: {},
    isLoadingAssetTypes: true,
  }));
  builder.addCase(getAssetTypesSuccess, (state, { payload }) => ({
    ...state,
    assetTypes: {
      ...payload,
    },
    isLoadingAssetTypes: false,
  }));
  builder.addCase(getAssetTypesError, (state) => ({
    ...state,
    isLoadingAssetTypes: false,
  }));

  builder.addCase(setCmsDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...initialState.dialogForm,
      ...payload,
    },
  }));
  builder.addCase(mergeCmsDialogForm, (state, { payload }) => ({
    ...state,
    dialogForm: {
      ...state.dialogForm,
      ...payload,
    },
  }));
});
